import * as React from "react";
import { formatDate } from "../../../actions/helpers/actionHelpers";
import { connect } from "react-redux";
import { padStart } from "lodash";

export class EventLog extends React.Component<any, any> {

  clipboard = null;
  contentEl = null;

  constructor(props) {
    super(props);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  copyToClipboard() {
    let { onChangeLog } = this.props;
    if (this.contentEl) {
      let log = this.contentEl.innerText;
      onChangeLog(log);
    }
  }

  render() {
    let { events, storm, entities: { actions, userDecisions, decisions, choices, announcements, decisionTiming } } = this.props;
    return (
      <div  ref={e => { this.contentEl = e; this.copyToClipboard() }}>
        <div className="storm">
          <h3 className="title">{`Storm ${storm.id} at ${storm.cityNameShort}`}</h3>
          <a className="copy-to-clipboard"
            ref={e => {
              if (process.env.JS_ENV === "browser") {
                if (e && !this.clipboard) {
                  let Clipboard = require("clipboard");
                  this.clipboard = new Clipboard(e);
                  this.clipboard.on("success", this.copyToClipboard);
                }
              }
            }}
            data-clipboard-text={this.contentEl ? this.contentEl.innerText : ""}
          >Copy to Clipboard</a>
          <br/>
        </div>
        {events.map((event, eventIndex) => {

          let eventDecisions = userDecisions
            .filter(uD => uD.event === event.order)
            .map(uD => ({
              decision: decisions[uD.decision],
              choice: decisions[uD.decision].choices[uD.choice]
            }));

          let eventAnnouncements: any[] =
            event.announcements.map(id => announcements[id]).map(a => ({ guid: a.id, content: a.popupText, name: `announcement-${a.id}` })).concat(
            event.decisionTimingAnnouncements.map(guid => decisionTiming[guid]).filter(a => a.announcementText).map(a => ({ guid: a.guid, content: a.announcementText, name: `decisionTimingAnnouncement-${a.guid}`}))
          );

          let eventActions = event.actions.map(aId => actions[aId]);

          if (eventAnnouncements.length === 0 && eventActions.length === 0 && eventDecisions.length === 0) {
            return null;
          }

          return (
            <div className="event" key={eventIndex}>
              <h3 className="title">{`Event ${eventIndex + 1} - ${formatDate(event.date)}`}</h3>
              <div className="details">

                <div className="stormInfo">
                  <h1 className="title">Storm Info</h1>
                  <ul>
                    <li className="item">{`cityWindSpeed: ${event.cityWindSpeed}\n`}</li>
                    <li className="item">{`estimatedHoursToLandfallHurrevac: ${event.estimatedHoursToLandfallHurrevac}\n`}</li>
                    <li className="item">{`probabilityWinds39MPH: ${event.probabilityWinds39MPH}\n`}</li>
                    <li className="item">{`probabilityWinds58MPH: ${event.probabilityWinds58MPH}\n`}</li>
                    <li className="item">{`probabilityWinds74MPH: ${event.probabilityWinds74MPH}\n`}</li>
                    <li className="item">{`probabilityWinds74MPH: ${event.probabilityWinds74MPH}\n`}</li>
                    <li className="item">{`maxSustainedWinds: ${event.maxSustainedWinds}\n`}</li>
                  </ul>
                  <br/>
                </div>

                {eventDecisions.length === 0 ? null :
                  <div className="decisions">
                    <h1 className="title">Decisions</h1>
                    <ul>
                      {userDecisions
                        .filter(uD => uD.event === event.order)
                        .map(uD => ({ decision: decisions[uD.decision], choice: decisions[uD.decision].choices[uD.choice]}))
                        .map(({ decision, choice }, i) =>
                          <li className="item" key={i} title={`${decision.id}\n\n${decision.dialogShortText}`}>{`${choice.choiceText}`}</li>
                        )}
                    </ul>
                    <br/>
                  </div>
                }
                {eventAnnouncements.length === 0 ? null :
                  <div className="announcements">
                    <h1 className="title">Announcements</h1>
                    <ul>
                      {eventAnnouncements.map((announcement, i) =>
                        <li className="item" key={i} title={`${JSON.stringify(announcement, null, 2)}`}>{announcement.content}</li>
                      )}
                    </ul>
                    <br/>
                  </div>
                }
                {eventActions.length === 0 ? null :
                  <div className="actions">
                    <h1 className="title">Actions</h1>
                    <ul>
                      {eventActions.map((a, i) =>
                        <li className="item" key={i} title={`
${a.id}

requirements ${JSON.stringify(event.actionRequirements[a.id], null, 2)}`
                        }>{a.textForRecordOfEvents}</li>
                      )}
                    </ul>
                    <br/>
                  </div>
                }
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let { entities: { events, storms }, currentStorm, entities, selectedEvent } = state;
  let storm = storms[currentStorm];
  let eventsArray = storm.events.map(eId => events[eId]);

  return {
    events: eventsArray,
    entities,
    storm,
  };
};

export default connect(mapStateToProps, null)(EventLog);
