import { formatDate, knotsToMph, typeOfStorm, roundToFive } from "../../misc/formatter";
import { orderBy, round } from  "lodash";
export let narrative = (event, events, cityName) => {

  let hasExpectedLandfall = event.expectedLandfallLatitude && event.expectedLandfallLongitude && event.estimatedLandfallDate ? true : false;
  let tide = event.tide;
  let surge = event.surge;

  // From matlab code build_data_storm_description line 99
  // bearingtext = [{'North'};{'North West'};{'West'};{'South West'};{'South'};{'South East'};{'East'};{'North East'}];
  // bearingnums = [-22.5:45:157.5,-157.5:45:-22.5];
  // bearingtext = bearingtext(find(bearingnums(1:end-1)<=bearing & bearingnums(2:end) > bearing));

  // Get the bearing direction based on a #
  let typeOfBearing = () => {
    let directions = [
      {name: "North", range: [-22.5, 22.4]},
      {name: "North East", range: [22.5, 67.4]},
      {name: "East", range: [67.5, 112.4]},
      {name: "South East", range: [112.5, 157.4]},
      {name: "South", range: [157.5, 202.4]},
      {name: "South West", range: [-157.5, -112.4]},
      {name: "West", range: [-112.5, -67.4]},      
      {name: "North West", range: [-67.5, -22.4]},
    ];
    return directions.find((direction) => {
      // Find the direction that the bearing fits betwee. Return that name.
      if (event.bearing >= direction.range[0] && event.bearing <= direction.range[1]) {
        return true;
      }
    }).name;
  };

  let bearing = typeOfBearing();

  //If the storm has not made landfall yet, and there are estimated hours to landfall not equaling 9999 or -9999
  let hasLandfall = event.estimatedHoursToLandfall && event.estimatedHoursToLandfall !== 0 && Math.abs(event.estimatedHoursToLandfall) !== 9999;
  let isOverLand = event.estimatedHoursToLandfall === -9999;
  let notProjectedToHitLand = event.estimatedHoursToLandfall === 9999;
  let forecast;
  // Gets the event with max winds after the currentEvent and finds storm classification based on the 
  // maxSustainedWindsForecasted
  let maxForecastType = null;
  let futureEventWithMaxWinds = null;

  const eventIds = Object.keys(events);
  const stormType = typeOfStorm(event.maxSustainedWindsForecasted);

  // If not on last event, get the future storm type
  if (event.order < eventIds.length - 1) {
    const futureEventIds = eventIds.slice(event.order + 1, eventIds.length);
    const futureEventsSortedByMaxWind = orderBy(futureEventIds.map(event => events[event]), ["maxSustainedWindsForecasted"]);  
    futureEventWithMaxWinds = futureEventsSortedByMaxWind[futureEventsSortedByMaxWind.length - 1];
    maxForecastType = typeOfStorm(futureEventWithMaxWinds.maxSustainedWindsForecasted);
  }

  let expectedLandfallInfo = () => {
    if (!isOverLand && event.estimatedHoursToLandfall < 120 && !notProjectedToHitLand) {
      return ` and is expected to make landfall in ${Math.round(event.estimatedHoursToLandfall)} hours. ` + 
        `Tropical storm force winds or even hurricane force winds can reach land many hours before ` +
        `the storm's official landfall.`;
    }
    else if (!isOverLand && notProjectedToHitLand) {
      return `. The storm is currently not expected to make landfall near ${cityName} within the next 120 ` +
      `hours${event.hasMadeFirstLandfall ? `, but this forecast could change in the future.` : `.`}`;
    }
    else {
      return ".";
    }
  };

  
  if (hasLandfall && maxForecastType) {
    if (event.maxSustainedWindsForecasted < futureEventWithMaxWinds.maxSustainedWindsForecasted) {
      forecast = `The ${stormType} could intensify to a ${maxForecastType} before it makes landfall.`;
    }
  }

  if (!hasLandfall && maxForecastType) {
    if (event.maxSustainedWindsForecasted < futureEventWithMaxWinds.maxSustainedWindsForecasted) {
      forecast = `The ${stormType} could intensify to a ${maxForecastType}.`;
    }
  }

  if (stormType === maxForecastType || !maxForecastType) {
    forecast = null;
  }

  let getStormPercentage = (probability) => {
    if (probability < 1) {
      return "less than a 1%";
    }
    else {
      return `a ${Math.round(probability)}%`;
    }
  };

  let windsInfo = event.hasMadeLandfallForecasted ? "" :
    " Winds and seas may vary greatly within and by quadrant. Winds are strongest to the right of the track.";
  let hasMadeLandfallText = event.hasMadeLandfallForecasted ? "has made landfall and " : "";

  let statuteToNauticalMiles = v => round(v * 1.15);

  // Narrative paragraphs
  let stormDescription = `A tropical cyclone with maximum sustained winds of ${roundToFive(knotsToMph(event.maxSustainedWindsForecasted))} mph, classified as a ${stormType}, ${hasMadeLandfallText}is currently centered at ${event.stormCenterLatitudeForecasted.toFixed(1)}°N and ${event.stormCenterLongitudeForecasted.toFixed(1)}°W. The radius of maximum winds is ${(statuteToNauticalMiles(event.radiusMaxWindsMilesForecasted))} miles. ${windsInfo}`;

  let landfallForecast = hasExpectedLandfall ? "The storm is currently forecast to make landfall at approximately " +
    formatDate(event.estimatedLandfallDate) + " at " + event.expectedLandfallLatitude.toFixed(1) +
    "°N " + event.expectedLandfallLongitude.toFixed(1) + "°W." : null;

  let probability39 = event.probabilityWinds39MPH < 100 ? `There is currently ${getStormPercentage(event.probabilityWinds39MPH)} probability that winds exceeding 39 mph (tropical storm force) will affect ${cityName} in the next 120 hours.` : null;
  let probability74 = event.probabilityWinds74MPH < 100 && event.probabilityWinds39MPH >= 1 ? `There is currently ${getStormPercentage(event.probabilityWinds74MPH)} probability that winds exceeding 74 mph (hurricane force) will affect ${cityName} in the next 120 hours.` : null;

  let stormLocation = `The storm is currently heading approximately ${bearing} at ${knotsToMph(event.speedOfForwardMotionForecasted).toFixed()} mph${expectedLandfallInfo()}`;
  let cityWindSpeeds = event.probabilityWinds39MPH === 100 ? `${cityName} is experiencing ` + roundToFive(knotsToMph(event.cityWindSpeedForecasted)) + " mph winds." : null;
  let appreciableSurge = surge < 1 ? "No appreciable surge is forecast, but" : `The surge is expected to be ${surge.toFixed(1)} feet, with`; 
  let isExpected = surge < 1 ? " feet is expected." : " feet.";
  let expectedTides = tide !== null && surge !== null ? (`${appreciableSurge} a tide of ` + (tide.toFixed(1) == -0.0 ? "0.0 " : tide.toFixed(1)) + isExpected) : null;

  return (
    {
      stormDescription: stormDescription,
      forecast: forecast,
      stormLocation: stormLocation,
      cityWindSpeeds: cityWindSpeeds,
      landfallForecast: landfallForecast,
      probability39: probability39,
      probability74: probability74,
      expectedTides: expectedTides,
    }
  );
};

export default narrative;
