import * as React from "react";
import { closeDialog } from "../actions/dialog";
import Dialog from "./BetterDialog";
import { connect } from "react-redux";
import { filter } from "lodash";

export let name = "event-actions";

export class EventActions extends React.Component<any, any> {

  constructor(params) {
    super(params);
  }

  render() {
    let {
      closeDialog,
      decisions,
      decisionsMadeOnSelectedEvent,
      actions,
      events,
      selectedEvent,
      announcements,
      decisionTiming,
    } = this.props;

    let announcementList = events[selectedEvent].announcements;
    let decisionTimingAnnouncementsList = events[selectedEvent].decisionTimingAnnouncements;
    let hasAnnouncements = announcementList.length > 0;
    let hasDecisionTimingAnnouncements = decisionTimingAnnouncementsList.length > 0;
    let selectedEventActions = events[selectedEvent].actions;
    let priorityOneActions = filter(selectedEventActions, a => actions[a].priority === 1);
    let priorityTwoActions = filter(selectedEventActions, a => actions[a].priority === 2);

    return (
      <div>
        <div className="dialog-header">
          <h2>Actions</h2>
          <span className="grab-handle-icon" />
          <span
          className="close-icon"
          onClick={(e) => closeDialog({ name })} />
        </div>

        <div className="dialog-body">

        {hasAnnouncements || hasDecisionTimingAnnouncements ?
          <div className="announcements">
            <h1>Announcements</h1>
            <ul className="announcements-list">
              { hasAnnouncements ? (announcementList.map((id) =>
                announcements[id].popupText ?
                  <li className="actionText" key={id}>
                    {announcements[id].popupText}
                  </li> : null
              )) : null}
              { hasDecisionTimingAnnouncements ? (decisionTimingAnnouncementsList.map((id) =>
                decisionTiming[id].announcementText ?
                  <li className="actionText" key={id}>
                    {decisionTiming[id].announcementText}
                  </li> : null
              )) : null}
            </ul>
          </div> : null
        }

          {decisionsMadeOnSelectedEvent.length > 0 ?
            <div className="decisions">
              <h1>Decisions You've Made</h1>
              <ul className="decisions-list">
                {decisionsMadeOnSelectedEvent.map((decision) => {
                  let decisionId = decision.decision;
                  return (
                    <li className="decisionText" key={decisionId}>
                      {decisions[decisionId].choices[decision.choice].choiceText}
                    </li>
                    );
                  })
                }
              </ul>
            </div> : null
          }

          {selectedEventActions.length > 0 ?
            <div className="actions">
              <h1>Actions Taken by MFR and Other Entities</h1>
              <ul className="actions-list">

                {priorityOneActions ? (priorityOneActions.map((a, i) => {
                  return (
                    <li className="actionText" key={i}>
                      {actions[a].textForRecordOfEvents}
                    </li>
                  );
                })) : null}

                {priorityTwoActions ? (priorityTwoActions.map((a, i) => {
                  return (
                    <li className="actionText" key={i}>
                      {actions[a].textForRecordOfEvents}
                    </li>
                  );
                })) : null}

              </ul>
            </div> : null }

        </div>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let { entities: { userDecisions, decisions, actions, events, announcements, decisionTiming }, selectedEvent} = state;
  let decisionsMadeOnSelectedEvent = userDecisions.filter((ud) => {
    return ud.event === selectedEvent;
  });

  return {
    decisionsMadeOnSelectedEvent,
    selectedEvent,
    decisions,
    actions,
    events,
    announcements,
    decisionTiming,
    direction: state.dialogs[name] ? state.dialogs[name].direction : "from-bottom",
  };
};

let mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeDialog: (options) => dispatch(closeDialog(options)),
  };
};

export let ReduxEventActions = connect(mapStateToProps, mapDispatchToProps)(EventActions);

export let ReduxEventActionsDialog = (props) =>
  <Dialog
    name={name}
    dialogClassName="dialog dialog-view-actions"
    styleOpened={({ dialogElement, dialogProps }) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `${document.body.offsetHeight / 2 - dialogElement.offsetHeight / 2 + 6}px`,
      };
    }}
    styleClosed={({ dialogElement, dialogProps }) => {
      if (dialogProps.direction === "from-left") {
        return {
          left: `${0 - dialogElement.offsetWidth - 6}px`,
          top: `${document.body.offsetHeight / 2 - dialogElement.offsetHeight / 2 + 6}px`,
        };
      }
      else {
        return {
          left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
          top: `${document.body.offsetHeight + 10}px`,
          transitionTimingFunction: `ease`,
        };
      }
    }}
    transitionDuration={600}
    hasOverlay={true}
  >
    <ReduxEventActions/>
  </Dialog>;

export default ReduxEventActionsDialog;
