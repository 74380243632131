import { merge, mergeWith } from "lodash";

let defaultAnimationTimings = {

  // SideBar
  sideBar: {
    animationTime: 400,
    sideBarTime: 300,
  },

  // Decision Dialog
  decisionDialog: {
    showAgainAfterSavedTiming: 50,
    slideUpAndDownTransitionTiming: 500,
    hideTransitionTiming: 1000,
    waitToShowOnFirstLoadTiming: 800,
    waitToShowTiming: 1000,
    waitToHideTiming: 1000,
    waitToShowPausedBefore: 200,
    waitToShowNotPausedBefore: 700,
  },

  // Auto Advance
  autoAdvance: {
    autoAdvanceTiming: 1000,
  },

  // Data Tip (transparent grey boxes that appear while hovering some items)
  dataTip: {
    delayDataTiming: 600,
  },
};

export let animationTimings = merge({}, defaultAnimationTimings);

/**
 * Applies the given scalar to animation timings
 *
 * Useful to speed up animations during QA or developer workflow
 */
export let scaleAnimationTimings = (scalar) => {
  animationTimings = mergeWith({}, defaultAnimationTimings, (objValue, srcValue) => {
    if (typeof srcValue === "number") {
      return srcValue * scalar;
    }
  });
};

if (process.env.NODE_ENV === "development") {
  scaleAnimationTimings(1.0);
}

export default animationTimings;
