import { UPDATE_ANNOUNCEMENTS } from "../../actions/announcement.types";
import { merge } from "lodash";

export let reducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ANNOUNCEMENTS: {
      let newState = merge({}, state);
      action.announcements.forEach(a => {
          newState[a.id].event = a.event;
          newState[a.id].popupText = a.popupText;
          newState[a.id].recordOfEventsText = a.recordOfEventsText;
          newState[a.id].resultsTextFull = a.resultsTextFull;
          newState[a.id].resultsTextPartial = a.resultsTextPartial;
        });
      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
