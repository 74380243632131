import * as Handlebars from "handlebars";
import { getTimeSinceEvent, findIfSuperceded, getDecisionRequired, getEventWithCondition} from "../helpers/actionHelpers";
import decision from "../../dialog/decision";

export let generateDecisionStormResultsFromState = (state, test = null) => {

  let { entities: {storms}, currentStorm } = state;
  let decisionStorm = Object.keys(state.entities.decisionStorm).map((k) => { return state.entities.decisionStorm[k]; });
  let events = Object.keys(state.entities.events).map((k) => { return state.entities.events[k]; });

  let results = [];

  decisionStorm.forEach(result => {
    
    let hasAlreadyBeenAdded = results.indexOf(result.id) > -1;
    let isNotSuperceded = result.supercededBy ? findIfSuperceded(result.supercededBy, results) : true;

    //Find out if this result has already been superceded or added. If not, continue
    if (!hasAlreadyBeenAdded && isNotSuperceded) {

      // Get the event that the required decision was made on
      let decisionRequired = result.decisionId ? getDecisionRequired(result.decisionId, state) : false;
     
      // Find the event that exceeds the threshold
      let eventWithThreshold = result.threshold ? getEventWithCondition(result.threshold, events, false) : undefined;

      
      if (decisionRequired === "not made" && eventWithThreshold) {
        results.push(result.id);
      }

      else if (decisionRequired !== "not made" && decisionRequired) {
        let eventWithDecision = decisionRequired ? state.entities.events[decisionRequired.event] : false;

        // Find the event that exceeds the threshold
        let eventWithThreshold = result.threshold ? getEventWithCondition(result.threshold, events, false) : undefined;

        let hasLag = result.lagHours || result.lagHours === 0;

        // Handle lag hours
        if (hasLag && eventWithThreshold && eventWithDecision) {
          // Get time between decision event, and event with req. threshold
          let timeSinceEvent = getTimeSinceEvent(eventWithDecision.order, eventWithThreshold.order, storms[currentStorm].events);
          let resultApplies = false;
          
          result.direction === "L" ? resultApplies = timeSinceEvent <= result.lagHours : null;
          result.direction === "G" ? resultApplies = timeSinceEvent >= result.lagHours : null;

          if (resultApplies) {
            results.push(result.id);
            // If time is negative, change to positive
            Math.sign(timeSinceEvent) === -1 ? timeSinceEvent = timeSinceEvent * -1 : null;
            result.reportText ? result.reportText = Handlebars.compile(result.reportText)({event_lag: timeSinceEvent}) : null;
          }
        }

        // If there's no lag, and we have both required events, push the result:
        !hasLag && eventWithThreshold && eventWithDecision ? results.push(result.id) : null;
      }
    }

  });

  let newDecisionStorm = {};
  decisionStorm.forEach((d) => newDecisionStorm[d.id] = d);
  let generatedDecisionStormResults = results;

  // Return array of results and the decisionStorm
  return { generatedDecisionStormResults, newDecisionStorm };
};
