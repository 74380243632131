import * as React from "react";
import * as ReactTooltip from "react-tooltip";
import { openDialog } from "../../../actions/dialog";
import { connect } from "react-redux";
import animationTimings from "../../../misc/animationTiming";

let delayDataTiming = animationTimings.dataTip.delayDataTiming;

export class Header extends React.Component<any, any> {

  render() {
    let { openDialog, isHelpDialogOpened } = this.props;
    return (
      <div className={isHelpDialogOpened ? "topBar helpCentered" : "topBar"}>
        <div className="topBarTitleBar">
          <h1>HURRICANE DECISION SIMULATOR</h1>
        </div>

        <div className="leftSideBox">
          <a
             className="resources"
             data-for="about"
             data-tip="View Credits"
             data-delay-show={delayDataTiming}
             onClick={(e) => openDialog({name: "about"})}>
            About<span className="resourcesIcon">&nbsp;</span>
          </a>
          <ReactTooltip class="custom-tool-tip" id="about" place="bottom" type="dark" effect="solid" />
          <a
             className="help"
             data-for="help"
             data-tip="Return to Home Screen Instructions"
             data-delay-show={delayDataTiming}
             onClick={(e) => { openDialog({name: "help"}); this.setState({helpDialogIsOpened: true}); }}>
            Help<span className="helpIcon">&nbsp;</span>
          </a>
          <ReactTooltip class="custom-tool-tip" id="help" place="bottom" type="dark" effect="solid" />
          <a
             className="share-storm"
             data-for="share"
             data-tip="Get URL for this storm simulation"
             data-delay-show={delayDataTiming}
             onClick={(e) => openDialog({name: "share"})}>
            Share<span className="linkIcon">&nbsp;</span>
          </a>
          <ReactTooltip class="custom-tool-tip" id="share" place="bottom" type="dark" effect="solid" />
        </div>

        <div className="leftSideTriangleContainer">
          <div className="leftSideTriangle"></div>
        </div>

        <div className="rightSideBox">
          {process.env.NODE_ENV === "production" ? null :
            <div>
              <a
                 className="debug-storm"
                 data-for="feedback"
                 data-tip="Provide feedback for this storm"
                 data-delay-show={600}
                 onClick={(e) => openDialog({name: "userFeedback"})}>
                Feedback<span className="debugIcon">&nbsp;</span>
              </a>
              <ReactTooltip class="custom-tool-tip" id="feedback" place="bottom" type="dark" effect="solid" />
            </div>}
        </div>

        <div className="rightSideTriangle"></div>

      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  return {
    isHelpDialogOpened: state.dialogs.help ? state.dialogs.help.isOpen : false,
  };
};

let mapDispatchToProps = (dispatch, ownProps) => {
  return {
    openDialog: (options) => dispatch(openDialog(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
