import { UPDATE_ACTIONS } from "../../actions/actions.types";

export let reducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ACTIONS: {
      return action.actions;
    }
    default:
      return state;
  }
};

export default reducer;
