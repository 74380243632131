import * as React from "react";
import { connect } from "react-redux";
import Header from "./header/";
import SideBar from "./sidebar/";
import Content from "./content/";
import Decision from "../../dialog/decision";

export class Home extends React.Component<any, any> {
  render() {
    let { simulationOver } = this.props;
    return (
      <div className="body-container">
        <Header />
        <SideBar />
        <Content />
        {simulationOver ? null : <Decision />}
      </div>
    );
  }
};

let mapStateToProps = (state, ownProps) => {
  let { simulationOver } = state;
  return { simulationOver };
}

export default connect(mapStateToProps)(Home);
