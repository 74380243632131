import "regenerator-runtime/runtime";

import * as WebFont from "webfontloader";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { AppContainer } from "react-hot-loader";
import { BrowserRouter as Router, StaticRouter } from "react-router-dom";

import { initStore } from "./store/";

import "../../css/src/app.scss";
import App from "./app";

declare let window: any;
declare let module: any;

if (
  process.env.JS_ENV === "browser" &&
  process.env.NODE_ENV === "development"
) {
  window.initialState = require("./developerOverride").changeState(window.initialState);
}

let store = initStore(window.initialState);
let context: any = {};

let render = (App) => {
  let app =
    <StaticRouter location={`/?location=${store.locationId}&stormId=${store.stormId}`} context={context}>
      <Provider store={store}>
        <App/>
      </Provider>
    </StaticRouter>;

  if (process.env.NODE_ENV === "development") {
    app =
      <AppContainer key={Math.random()}>
        {app}
      </AppContainer>;
  }

  ReactDOM.render(app, document.getElementById("content"));
};
render(App);

if (process.env.JS_ENV === "browser") {
  WebFont.load({
    google: {
      families: ["Roboto"],
    },
  });
}

if (module.hot) {
  module.hot.accept("./app", () => {
    console.log('changes arrived');
    let App = require("./app").default;
    render(App);
  });
}