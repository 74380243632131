import * as React from "react";
import * as ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { openDialog } from "../../../actions/dialog";
import { setSelectedEvent } from "../../../actions/event";
import { setCurrentEventHeight } from "../../../actions/ui";
import { stopAutoAdvance } from "../../../actions/autoAdvance";
import animationTimings from "../../../misc/animationTiming";

let {
  sideBar: {
    sideBarTime,
  },
} = animationTimings;

let delayDataTiming = animationTimings.dataTip.delayDataTiming;

import { hoursToLandfall, formatProbability } from "../../../misc/formatter";

export class RecordEvent extends React.Component<any, any> {

  _element;

  state = {
    styleHeight: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    let { userDecisions, currentEvent, shouldAnimate } = this.props;
    let newUserDecisions = prevProps.userDecisions.length !== userDecisions.length;

    if (prevProps.currentEvent !== currentEvent || newUserDecisions) {
      this.setState({styleHeight: {height: "auto"}});
    }

    if (newUserDecisions) {
      if (shouldAnimate) {
        setTimeout(() => {
          this.props.dispatch(setCurrentEventHeight({currentEventHeight: this._element.clientHeight}));
        }, 0);
      }
    }
  }

  componentDidMount() {
    this.setState({mounted: true});
    let height = this._element.clientHeight;
    let { shouldAnimate, numberOfEventsSoFar } = this.props;
    if (shouldAnimate) {
      this.props.dispatch(setCurrentEventHeight({currentEventHeight: height}));
    }
    if (numberOfEventsSoFar > 1) {
      this.setState({styleHeight: {maxHeight: "0px", overflow: "hidden", padding: "0px", border: "none" }});
      setTimeout(() => {
        this.setState({
          styleHeight: {
            maxHeight: height + "px",
            transition: `max-height ${sideBarTime}ms`,
            overflow: "hidden",
          }
        });
      }, 0);
    }
  }

  getCurrentEventDecisions(array) {
    let decisions = [];
    let {event, userDecisions} = this.props;

    for (let i = 0; i < array.length; i++) {
      if (array[i] === event) {
        decisions.push(userDecisions[i]);
      }
    }
    return decisions;
  }

  handleRecordEventOnClick() {
    this.props.dispatch(stopAutoAdvance());
    this.props.dispatch(setSelectedEvent({event: this.props.event}));
  }

  render() {
    let {
      event,
      events,
      actions,
      announcements,
      threshold,
      selectedEvent,
      userDecisions,
      dispatch,
      decisions,
      eventIndex,
      hours,
      shouldAnimate,
      probability,
      decisionTiming,
      cityName,
    } = this.props;

    let eventDetails = events[event];
    let userDecisionEventIds = userDecisions.map((decision) => decision.event);
    let currentEventDecisions = this.getCurrentEventDecisions(userDecisionEventIds);
    let probabilityAffectingCityText = `120 hr probability of winds exceeding threshold affecting ${cityName}`;

    return (
      <div
        onClick={(e) => this.handleRecordEventOnClick()}
        ref={(node) => { if (node) { this._element = node; }}}>
        <li
          className={event === selectedEvent ? "event selectedEvent" : "event"}
          style={eventIndex === 0 && shouldAnimate ? this.state.styleHeight : null}>
          <span className="windInfo">

            <span
              className="details-icon"
              data-for="details-sidebar"
              data-tip="View Additional Event Details and Summaries"
              data-delay-show={delayDataTiming}
              onClick={(e) => dispatch(openDialog({name: "event-details", props: {direction: "from-left"}}))}>
            </span>
            <ReactTooltip class="custom-tool-tip" id="details-sidebar" place="top" type="dark" effect="solid" />

            { events[event].actions.length > 0 ||
              currentEventDecisions.length > 0  ||
              events[event].announcements.length > 0 ||
              events[event].decisionTimingAnnouncements.length > 0 ?

              <div>
                <span
                  className="actions-icon"
                  data-for="actions-sidebar"
                  data-tip="View Actions taken by MFR and other entities"
                  data-delay-show={delayDataTiming}
                  onClick={(e) => dispatch(openDialog({name: "event-actions", props: {direction: "from-left"}}))}>
                </span>
                <ReactTooltip class="custom-tool-tip" id="actions-sidebar" place="top" type="dark" effect="solid" />
              </div> : null }

            <span
              className="hoursToLandfall"
              data-for="hours-to-landfall"
              data-tip="Worst case time to landfall"
              data-delay-show={delayDataTiming}>
              {hoursToLandfall(eventDetails.estimatedHoursToLandfallHurrevac)}
            </span>
            <ReactTooltip class="custom-tool-tip" id="hours-to-landfall" place="top" type="dark" effect="solid" />

            {threshold !== "CONE" ?
              <span>
                <span
                  className="probability"
                  data-for="probability-cone"
                  data-tip={probabilityAffectingCityText}
                  data-delay-show={delayDataTiming}>
                  {formatProbability(probability)}
                </span>
                <ReactTooltip class="custom-tool-tip" id="probability-cone" place="top" type="dark" effect="solid" />
              </span>
              : null
            }

          </span>
          <h3>{hours}</h3>

          {event !== 0 ? <p></p> : null}

          {event === 0 ?
            <p>
              <a
                className="record-initial-storm-update"
                data-for="initial-storm-update"
                data-tip="Displays initial storm information"
                data-delay-show={delayDataTiming}
                onClick={(e) => dispatch(openDialog({name: "initial-storm-update", props: {direction: "from-left"}}))}>
                Initital Storm Update
              </a>
            </p> : null
          }

          {currentEventDecisions.length > 0 ?
            <div className="decisions">
              {currentEventDecisions.map((decision, i) => {
                  let decisionId = decision.decision;
                  return (
                    <span className="decisionText" key={i}>
                      {decisions[decisionId].choices[decision.choice].choiceText}
                    </span>
                  );
                }
              )}
            </div> : null
          }

          { events[event].announcements.length > 0 ||
            events[event].decisionTimingAnnouncements.length > 0 ?
            <div className="announcements">
              {events[event].announcements.length > 0 ?
                events[event].announcements.map((a, i) =>
                  announcements[a].popupText ?
                    <span className="announcementText" key={i}>{announcements[a].popupText}</span> : null
                ) : null }

              {events[event].decisionTimingAnnouncements.length > 0 ?
                events[event].decisionTimingAnnouncements.map((a, i) =>
                  decisionTiming[a].announcementText ?
                    <span className="announcementText" key={i}>
                      {decisionTiming[a].announcementText}
                    </span> : null
                ) : null}

            </div> : null
          }

          {events[event].actions.length > 0 ?
            <div className="actions">
              <span className="actionText">{actions[events[event].actions[0]].textForRecordOfEvents}</span>
            </div> : null
          }

          <ReactTooltip class="custom-tool-tip" id="initial-storm-update" place="bottom" type="dark" effect="solid" />

        </li>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let {
    entities: { events, userDecisions, decisions, actions, announcements, decisionTiming, storms },
    currentEvent, selectedEvent, currentStorm,
    ui: { threshold, currentEventHeight }
  } = state;

  let event = events[ownProps.event];
  let cityName = storms[currentStorm].cityNameShort;

  return {
    events,
    userDecisions,
    selectedEvent,
    decisions,
    currentEvent,
    currentEventHeight,
    actions,
    announcements,
    decisionTiming,
    probability: event[`probabilityWinds${threshold}MPH`],
    threshold,
    cityName,
  };
};

export default connect(mapStateToProps, null, null, {withRef: true})(RecordEvent);
