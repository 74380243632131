import * as UI from "./ui.types";
import { merge } from "lodash";
import { Threshold } from "../reducers/ui.types";

export let toggleViewMode = (options: {}) => {
  return async (dispatch, getState) => {
    let state = getState();
    dispatch(merge({}, {
      type: UI.TOGGLE_VIEW_MODE,
    }, options));
  };
};

export let changeThreshold = (options: {threshold: Threshold}) => {
  return async (dispatch, getState) => {
    let state = getState();
    await dispatch(merge({}, {
      type: UI.CHANGE_THRESHOLD,
    }, options));
  };
};

export let setCurrentEventHeight = (options: {}) => {
  return async (dispatch, getState) => {
    let state = getState();
    await dispatch(merge({}, {
      type: UI.SET_CURRENT_EVENT_HEIGHT,
    }, options));
  };
};
