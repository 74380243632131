import * as React from "react";
import * as ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import RecordEvent from "./recordEvent";
import { openDialog } from "../../../actions/dialog";
import { Scrollbars } from "react-custom-scrollbars";
import { SpringSystem, SpringConfig } from "rebound";
import animationTimings from "../../../misc/animationTiming";

let {
  sideBar: {
    animationTime,
    sideBarTime,
  },
} = animationTimings;

let delayDataTiming = animationTimings.dataTip.delayDataTiming;

export class SideBar extends React.Component<any, any> {

  _scrollingElement;
  componentHeight = 540;
  scrollContainerHeight;
  arrowScroll;
  eventDOMRefs = {};
  firstEventHeight;
  springSystem;
  spring;

  state = {
    mounted: false,
    animating: false,
    hovering: false,
  };

  constructor(props, context) {
    super(props, context);
    this.renderThumb = this.renderThumb.bind(this);
    this.renderTrack = this.renderTrack.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
    this.handleSpringUpdate = this.handleSpringUpdate.bind(this);
  }

  componentDidMount() {
    let springConfig = SpringConfig.fromBouncinessAndSpeed(0, 3);
    this.springSystem = new SpringSystem();
    this.spring = this.springSystem.createSpringWithConfig(springConfig);
    this.spring.addListener({onSpringUpdate: this.handleSpringUpdate});
  }

  scrollTop(top) {
    let scrollbar = this._scrollingElement;
    let scrollTop = scrollbar.getScrollTop();
    this.spring.setCurrentValue(scrollTop).setAtRest();
    this.spring.setEndValue(top);
  }

  handleSpringUpdate(spring) {
    let scrollbar = this._scrollingElement;
    let val = spring.getCurrentValue();
    scrollbar.scrollTop(val);
  }

  //Formats the time in the event
  getHoursAgo(eventIndex, currentEventDisplay = "Current") {
    if (eventIndex === 0) {
      return currentEventDisplay;
    } else {
      return `${(eventIndex) * 6} hrs ago`;
    }
  }

  //Handles animation of events
  componentWillReceiveProps(nextProps) {
    let sideBarTiming = animationTime + sideBarTime;
    let newProps = nextProps.currentEvent !== this.props.currentEvent;

    if (newProps && this.props.eventsArrayUpToCurrentEvent.length >= 1) {
      this._scrollingElement.scrollToTop();
      this.setState({animating: true});
      setTimeout(() => {
        this.setState({animating: false});
      }, sideBarTiming);
    }
    else if (newProps && this.props.eventsArrayUpToCurrentEvent.length < 1) {
      setTimeout(() => {
        this.setState({animating: true});
      }, sideBarTiming);
      setTimeout(() => {
        this.setState({animating: false});
      }, sideBarTiming);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //Gets the height of the scrolling element whenever a new event or new user decision is added
    this.scrollContainerHeight = this._scrollingElement ? this._scrollingElement.getScrollHeight() : 0;

    //Moves scrollbox location to the selected event, if the selected event is not completely in view
    if (prevProps.selectedEvent !== this.props.selectedEvent && this.eventDOMRefs[this.props.selectedEvent]) {
      let scrollbarLocation = this._scrollingElement.getScrollTop();
      let heightOfSelectedEvent = this.eventDOMRefs[this.props.selectedEvent].getWrappedInstance()._element.clientHeight;
      let topOfSelectedDomElement = this.eventDOMRefs[this.props.selectedEvent].getWrappedInstance()._element.offsetTop;
      let isEventAboveScrollContainerBounds = topOfSelectedDomElement < scrollbarLocation;
      let isEventBelowScrollContainerBounds = topOfSelectedDomElement + heightOfSelectedEvent > this.componentHeight + scrollbarLocation;

      //If the selected event is above the bounds of the scrolling box, then move up to that event
      if (isEventAboveScrollContainerBounds) {
        this.scrollTop(topOfSelectedDomElement);
      }
      //If the selected event is below the bounds of the scrolling box, then move down to that event
      if (isEventBelowScrollContainerBounds) {
        this.scrollTop(topOfSelectedDomElement - this.componentHeight + heightOfSelectedEvent);
      }
    }
  }

  //Renders the track of the scrollbar
  renderTrack(props) {
    let shouldShowScrollBar = this.scrollContainerHeight && this.scrollContainerHeight > this.componentHeight;
    return (
      <div
        className={shouldShowScrollBar ? "scrollbar-track" : "hidden-scrollbar"}
        {...props}/>
    );
  }

  //Renders the thumb of the scrollbar
  renderThumb(props) {
    return (
      <div className={this.state.hovering ? "scrollbar-thumb-hover" : "scrollbar-thumb"} {...props}
      />
    );
  }

  //Changes color of the scrollbar thumb when any event is hovered over
  handleHoverColor() {
    if (!this.state.hovering) {
      this.setState({hovering: true});
    }
    else {
      this.setState({hovering: false});
    }
  }

  //Runs when the mouse is held down on either of the scrollbar arrows
  handleArrowScrolling(direction) {
    this.arrowScroll = setInterval(() => {
      let scrollbarLocation = this._scrollingElement.view.scrollTop;
      if (direction === "up") {
        scrollbarLocation -= 1;
      }
      if (direction === "down") {
        scrollbarLocation += 1;
      }
      this._scrollingElement.scrollTop(scrollbarLocation);
    }, 0);
  }

  //Stops handleArrowScrolling() when the mouse is not held down anymore
  handleStopArrowScrolling() {
    clearInterval(this.arrowScroll);
  }

  render() {
    let { eventsArrayUpToCurrentEvent, numberOfEventsSoFar, dispatch } = this.props;

    return (
      <div className="sideBar">
        <div className="eventContainer">
          <div className="Records">
            <h2>Records of Events&nbsp;</h2>
            <span
              className="help-icon"
              data-for="record-of-events-help"
              data-tip="Click to better understand Record of Events"
              data-delay-show={delayDataTiming}
              onClick={(e) => dispatch(openDialog({name: "record-of-events-help"}))}>
            </span>
            <ReactTooltip class="custom-tool-tip" id="record-of-events-help" place="right" type="dark" effect="solid" />

            {this.scrollContainerHeight && this.scrollContainerHeight > this.componentHeight ?
              <div>
                <div
                  className="scrollbar-arrows icon-arrowUp"
                  onMouseDown={() => this.handleArrowScrolling("up")}
                  onMouseUp={() => this.handleStopArrowScrolling()}
                />
                <div
                  className="scrollbar-arrows icon-arrowDown"
                  onMouseDown={() => this.handleArrowScrolling("down")}
                  onMouseUp={() => this.handleStopArrowScrolling()}
                />
              </div>
            : null}

            <Scrollbars
              style={{width: "277px", height: `${this.componentHeight}px`, marginTop: "4px", position: "relative"}}
              renderTrackVertical={this.renderTrack}
              renderThumbVertical={this.renderThumb}
              ref={(node) => this._scrollingElement = node }
              onMouseEnter={() => this.handleHoverColor()}
              onMouseLeave={() => this.handleHoverColor()}
            >
              <ul className="eventRecord">
                {numberOfEventsSoFar <= 1 || !this.state.animating ? (
                  eventsArrayUpToCurrentEvent.slice(0, 1).map((event, eventIndex) => {
                  return (
                    <RecordEvent
                      ref={(node) => this.eventDOMRefs[event] = node}
                      event={event}
                      key={event}
                      shouldAnimate={true}
                      numberOfEventsSoFar={numberOfEventsSoFar}
                      eventIndex={eventIndex}
                      hours={this.state.animating ? this.getHoursAgo(eventIndex + 1) : this.getHoursAgo(eventIndex)}
                    />
                  );
                  })) : null
                }
                {eventsArrayUpToCurrentEvent.slice(1).map((event, eventIndex) => {
                  return (
                    <RecordEvent
                      ref={(node) => this.eventDOMRefs[event] = node}
                      event={event}
                      key={event}
                      shouldAnimate={false}
                      numberOfEventsSoFar={numberOfEventsSoFar}
                      eventIndex={eventIndex}
                      hours={!this.state.animating ? this.getHoursAgo(eventIndex + 1) : this.getHoursAgo(eventIndex)}
                    />
                  );
                })}
              </ul>
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }
};

let mapStateToProps = (state, ownProps) => {
  let { 
    currentEvent, 
    currentStorm, 
    entities: { storms }, 
    ui: { currentEventHeight }, selectedEvent 
  } = state;
  let eventsArray = storms[currentStorm].events;
  let eventsArrayUpToCurrentEvent = eventsArray.slice(0, (currentEvent + 1)).reverse();
  let numberOfEventsSoFar = eventsArrayUpToCurrentEvent.length;
  return {
    currentEvent,
    currentEventHeight,
    eventsArrayUpToCurrentEvent,
    numberOfEventsSoFar,
    selectedEvent
  };
};

export default connect(mapStateToProps)(SideBar);
