import * as React from "react";
import * as ReactTooltip from "react-tooltip";
import ProgressBar from "./progressBar";
import { connect } from "react-redux";
import { roundDownToWhole, getHoursAgo } from "../../../../misc/formatter";
import { openDialog } from "../../../../actions/dialog";
import AutoAdvance from "./autoAdvance";
import animationTimings from "../../../../misc/animationTiming";
import { find } from "lodash"; 

let animationTime = animationTimings.sideBar.animationTime;

export class ProgressTooltip extends React.Component<any, any> {
  progressBarWidth = 500;
  eventWidth = 16;
  padding = 8;
  minSpaceBetweenEvents = 8;

  state = {
    isTransitioning: false,
    dialogIsOpen: false,
  };

  constructor(props) {
    super(props);
    this.getEventLeftPosition = this.getEventLeftPosition.bind(this);
  }

  getEventLeftPosition(eventIndex) {
    let {numEventsSoFar} = this.props;
    let {progressBarWidth, eventWidth, padding} = this;
    let progressBarWidthMinusEventWidth = progressBarWidth - eventWidth - 2 * padding;

    if (numEventsSoFar === 1 || eventIndex + 1 === numEventsSoFar) {
      return progressBarWidth - eventWidth - padding;
    } else if (eventIndex === 0) {
      return padding;
    } else {
      return (progressBarWidthMinusEventWidth / (numEventsSoFar - 1)) * eventIndex - eventWidth / 2 + (padding + eventWidth / 2);
    }
  }

  componentWillReceiveProps(nextProps) {
    let {numEventsSoFar} = this.props;
    let {minSpaceBetweenEvents, eventWidth} = this;
    let width = this.progressBarWidth - (this.padding * 2);
    if (numEventsSoFar !== nextProps.numEventsSoFar) {
      if (width <= ((eventWidth + minSpaceBetweenEvents) * numEventsSoFar)) {
        this.eventWidth = roundDownToWhole((width - (numEventsSoFar * minSpaceBetweenEvents)) / numEventsSoFar);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Detect if any dialog is open
    if (prevProps.dialogs !== this.props.dialogs) {
      let dialogIsOpen = find(this.props.dialogs, d => d.isOpen);
      this.setState({dialogIsOpen: dialogIsOpen ? true : false});      
    }
    if (prevProps.currentEvent !== this.props.currentEvent) {
      this.setState({isTransitioning: true});
      setTimeout(() => this.setState({isTransitioning: false}), animationTime);
    }
  }

  render() {

    let {
      dispatch,
      eventsArrayUpToCurrentEvent,
      selectedEventIndex,
      numEventsSoFar,
      selectedEvent,
      simulationOver,
      dialogIsOpen
    } = this.props;

    return (
      <div className="eventContainer">
        <div className="progress">

          <p className="timeline-label">Interactive Timeline</p>
          <div
            className="hidden"
            style={{left: this.getEventLeftPosition(selectedEventIndex) + "px", width: this.eventWidth}}>
          </div>

          <div className="progressBar" style={{width: "500px", height: "18px"}}>
            <ul className="events">
              {eventsArrayUpToCurrentEvent.map((event, eventIndex) => {
                  return <ProgressBar
                    width={this.eventWidth}
                    event={event}
                    key={eventIndex}
                    position={this.getEventLeftPosition(eventIndex)}
                    eventIndex={eventIndex}
                    isSelected={event === selectedEvent}
                    hoursAgo={getHoursAgo(eventIndex, numEventsSoFar)}
                  />;
                }
              )}
            </ul>
          </div>

          <div className="labels">
            <div className="row">
              <div className="left">{getHoursAgo(selectedEventIndex, numEventsSoFar)}</div>
              <div className="middle"></div>
              <div className="right">Current</div>
            </div>
          </div>

        </div>

        <div className="simulationEndBox"></div>

        <div className={simulationOver && !this.state.dialogIsOpen ? "reminder-blink" : null}>
          {simulationOver ?
            <a
              className={`btn btn-big viewResultsButton btn-primary ${dialogIsOpen ? "btn-disabled" : ""}`}
              onClick={(e) => dispatch(openDialog({name: "simulation-over"}))}>
              BACK TO RESULTS
            </a> : null}

          <ReactTooltip class="custom-tool-tip" id="toggle-map-view" place="top" type="dark" effect="solid"/>

          {simulationOver ?
            <a href={`${window.location}`} className="btn btn-big newSimulationButton">
              NEW SIMULATION
            </a> :
            <AutoAdvance/>}
        </div>

      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let {ui: {viewMode}, entities: {storms}, currentEvent, selectedEvent, currentStorm, simulationOver, dialogs} = state;
  // An array of all the event ID's
  let eventsArray = storms[currentStorm].events;
  // A list of all the events up until now/currentEvent
  let eventsArrayUpToCurrentEvent = eventsArray.slice(0, (currentEvent + 1));
  let numEventsSoFar = eventsArrayUpToCurrentEvent.length;
  let selectedEventIndex = eventsArrayUpToCurrentEvent.indexOf(selectedEvent);
  let dialogIsOpen = Object.keys(dialogs).find(d => dialogs[d].isOpen);

  return {
    viewMode,
    currentEvent,
    selectedEvent,
    numEventsSoFar,
    eventsArray,
    eventsArrayUpToCurrentEvent,
    selectedEventIndex,
    simulationOver,
    dialogs,
    storms,
    currentStorm,
    dialogIsOpen
  };
};

export default connect(mapStateToProps)(ProgressTooltip);
