import { SET_ANNOUNCEMENTS_BEING_SHOWN } from "../actions/announcementsBeingShown.types";

export let reducer = (state = false, action) => {
  switch (action.type) {
    case SET_ANNOUNCEMENTS_BEING_SHOWN:
      return action.announcementsBeingShown;
    default:
      return state;
  }
};

export default reducer;
