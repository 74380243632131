import * as React from "react";

export class Acronyms extends React.Component<any, any> {
  render() {
    return (
      <div className="content">
        <h2>ACRONYMS</h2>
				<p>This section includes a list of Command Documentation acronyms. For additional (NHC) terms, 
					visit the <a href="http://www.nhc.noaa.gov/aboutgloss.shtml" target="_blank">NHC glossary</a> page. </p>
        <ul className="splash-list">
        	<li><span>ADVON</span> - Emergency Relocation Staff (ERS) Advance Party</li>
	        <li><span>AH</span> - Alternate Headquarters</li>
	        <li><span>APC</span> – Agency Program Coordinators</li>
	        <li><span>BPT</span> – Be prepared to</li>
	        <li><span>C2</span> - Command and Control</li>
	        <li><span>CAT</span> - Crisis Action Team</li>
	        <li><span>CMCC</span> – Classified Material Control Center</li>
					<li><span>COA</span> - Course of Action</li>
	        <li><span>COC</span> - Crisis Operations Center</li>
	        <li><span>COOP</span> – Continuity of Operations</li>
	        <li><span>DLA</span> - Defense Logistics Agency</li>
	        <li><span>DTS</span> - Defense Travel System</li>
					<li><span>EM</span> - Emergency Manager</li>
	        <li><span>EOC</span> - Emergency Operations Center</li>
	        <li><span>ePas</span> – Evacuation Personnel Accountability System</li>
	        <li><span>ERS</span> - Emergency Relocation Staff</li>
					<li><span>FP</span> - Force Protection</li>
	        <li><span>GTCC</span> – Government Travel Charge Card</li>
	        <li><span>HQBN</span> – Headquarters Battalion</li>
	        <li><span>HQMC</span> – Headquarters Marine Corps</li>
					<li><span>HDS</span> – Hurricane Decision Simulator</li>
	        <li><span>HSS</span> – Health Services Support</li>
					<li><span>HTC</span> – Headquarters Training Center</li>
	        <li><span>HURREVAC</span> - Hurricane Evacuation plan</li>
	        <li><span>IAW</span> – In Accordance With</li>
	        <li><span>IOT</span> – Initial Operational Test</li>
	        <li><span>IRT</span> – In Respect To</li>
					<li><span>I&I</span> – Inspector and Instructor</li>
	        <li><span>JRIC</span> – Joint Reserve Intelligence Center</li>
	        <li><span>LA</span> – Louisiana</li>
	        <li><span>LWIN</span> – Louisiana Wireless Information Network</li>
	        <li><span>MARCORSPTFAC</span> – Marine Corps Support Facility</li>
					<li><span>MARFORRES</span> – Marine Corps Forces Reserve Headquarters in New Orleans</li>
	        <li><span>MCPD</span> – Marine Corps Police Department</li>
					<li><span>MDOEM</span> – Miami-Dade Office of Emergency Management</li>
	        <li><span>MFR</span> - Marine Forces Reserve</li>
					<li><span>MFRHTC</span> - Marine Forces Reserve Headquarters Training Center</li>
	        <li><span>MSC</span> – Major Subordinate Commands</li>
	        <li><span>mph</span> - miles per hour</li>
	        <li><span>NAS</span> – Naval Air Station</li>
	        <li><span>NHC</span> - National Hurricane Center</li>
	        <li><span>NOAA</span> - National Oceanic and Atmospheric Agency (federal agency that includes NWS and NHC)</li>
	        <li><span>NOLA</span> – New Orleans Louisiana</li>
	        <li><span>NWS</span> - National Weather Service (forecasting office that is part of NOAA)</li>
	        <li><span>OPORD</span> – Operations Order</li>
	        <li><span>O/O</span> – On Order</li>
	        <li><span>POC</span> – Point of Contact</li>
	        <li><span>RBE</span> - Remain Behind Element</li>
	        <li><span>SCIF</span> – Sensitive Compartmented Information Facility</li>
	        <li><span>SECNAV</span> – Secretary of the Navy</li>
	        <li><span>SMCR</span> – Selected Marine Corps Reserve</li>
	        <li><span>TAD</span> – Temporary Additional Duty</li>
	        <li><span>TC</span> - Tropical Cyclone</li>
        </ul>
      </div>
    );
  }
}

export default Acronyms;
