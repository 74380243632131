import { START_AUTO_ADVANCE, STOP_AUTO_ADVANCE } from "./autoAdvance.types";
import { merge } from "lodash";

export let startAutoAdvance = () => {
  return async (dispatch, getState) => {
    let state = getState();
    await dispatch(merge({}, {
      type: START_AUTO_ADVANCE,
    }));
  };
};

export let stopAutoAdvance = () => {
  return async (dispatch, getState) => {
    let state = getState();
    await dispatch(merge({}, {
      type: STOP_AUTO_ADVANCE,
    }));
  };
};
