import * as React from "react";
import { connect } from "react-redux";

export class Instructions extends React.Component<any, any> {

  render() {
    let { name, cityName, cityNameShort } = this.props;
    const city = `${cityName}${cityNameShort !== cityName ? ` (${cityNameShort})` : ""}`;
    
    return (
      <div className="content">
        <a name="top" id="top" />
        <h2>INSTRUCTIONS</h2>
        <h2>Quicklinks</h2>
        <ul className="quick-links">
          <li><a className="splash-nav" href="#objective">Objective</a></li>
          <li><a className="splash-nav" href="#overview">Overview</a></li>
          <li><a className="splash-nav" href="#about">About the Storms</a></li>
          <li><a className="splash-nav" href="#running-through">Running Through the Simulation</a>:
            <ul className="content-list">
              <li><a className="splash-nav" href="#progression">Progression (Step by Step)</a></li>
              <li><a className="splash-nav" href="#simulation-over">Simulation Over (End Screen)</a></li>
            </ul>
          </li>
          <li><a className="splash-nav" href="#user-interface">The User Interface: Decision Making Tools and Views</a>:
            <ul className="content-list">
              <li><a className="splash-nav" href="#probabilities">The Wind Speed Probabilities Map</a></li>
              <li><a className="splash-nav" href="#record-of-events">Record of Events (sidebar)</a></li>
              <li><a className="splash-nav" href="#map-views">Map Views (Threshold and Cone buttons)</a></li>
              <li><a className="splash-nav" href="#cone">Track Forecast Cone</a></li>
              <li><a className="splash-nav" href="#interactive-timeline">Interactive Timeline</a></li>
              <li><a className="splash-nav" href="#auto-advance">Auto Advance</a></li>
              <li><a className="splash-nav" href="#help">Contextual and Global Help</a></li>
            </ul>
          </li>
        </ul>

        <a name="objective" id="objective" />
        <h2>Objective</h2>

        <p>The objective of this simulation is to provide Marine Forces Reserve personnel with 
          experience making key hurricane-preparation decisions in the context of realistic storms 
          and forecasts, implementation schedules, and decisions by other local authorities.</p>
        
        <a className="splash-nav back-to-top" href="#top">Back to Top</a>

        <a name="overview" id="overview" />
        
        <h2>Overview</h2>
        <p>A tropical storm is approaching the coast of southeastern United States. You will be 
          presented with a series of storm forecasts updated at 6 hour intervals. Your task is to run 
          through this simulation, and make hurricane preparation decisions with the goal of reducing 
          the storm’s impact, saving lives, and minimizing the costs of preparation. </p>

        <p><strong>One run through is not sufficient for gaining a full range of experience. </strong> 
          For the best learning experience, it is recommended to run through this simulation many 
          times, to experience the many ways a storm can evolve and a realistic range of forecast accuracy. </p>
        
        <a className="splash-nav back-to-top" href="#top">Back to Top</a>
        
        <a name="about" id="about" />
        
        <h2>About the Storms</h2>
        <p>The Hurricane Decision Simulator (HDS) is designed so that various storm scenarios may be run. 
          Each storm scenario has an identified geographical target location (i.e., a specific city or 
          populated area of concern). The target location will be depicted by a star icon on 
          screen. For most storms in the HDS, the target location will not experience hurricane-force winds. 
          This mirrors the reality that many storms are potential threats to the region and must be monitored, 
          but only a few will directly hit any particular location.</p>

        <p>However, storms in the HDS are much more likely to be threatening to the target location 
          than the typical tropical storm in the region. This provides HDS users a chance to gain rapid 
          experience in dealing with storms that may require preparation.</p>
        
        <a className="splash-nav back-to-top" href="#top">Back to Top</a>

        <a name="running-through" id="running-through" />

        <h2>Running Through the Simulation</h2>
        <p>
          <strong>Read through the instructions below. When ready to begin, use the “Start Simulation” 
            button (upper right) to begin/continue.</strong>
        </p>

        <a name="objective" id="progression" />
        <h3>Progression (Step by Step)</h3>
        <p>1.	The simulation begins with a map of a tropical storm located somewhere in 
          the Atlantic, Caribbean or Gulf of Mexico, and a <i>Continue</i> button. 
          Review this screen and use the green Continue button to proceed.</p>
        <p>
          <img
            className="init-map-image"
            src={`help_screens/opening-screen.png`}
            style={{width: "600px"}}
            alt="hurricane simulator opening screen" 
          />
        </p>
        <p>2.	Next, you will see a map showing a contour plot of the storm’s tropical-storm-force 
          wind speed probabilities and a <i>Record of Events</i> that will document your decisions, storm forecasts, 
          and other events. Additional forecast products may be accessed via the <a className="splash-nav" href="#user-interface"> user interface </a> 
          that will be discussed in more detail later. </p>
        <p>
          <img
            className="init-map-image"
            src={`help_screens/main-screen.png`}
            style={{width: "600px"}}
            alt="hurricane simulator main screen" 
          />
        </p>

        <p>3.	Look for the <i>Decision</i> popup window in the lower left of your screen and follow 
          the prompts to either take action, or continue. Use on-screen forecast products and other 
          available forecast products to help you make your decision(s).
          <ul style={{listStyleType: "circle", marginLeft: "30px"}}>
            <li>If you decide not to take action, you will have the opportunity to take 
              the same action(s) during the subsequent <i>Storm Updates</i>.</li>
          </ul>
        </p>
        <p>
          <img
            className="init-map-image"
            src={`help_screens/decision.png`}
            style={{width: "250px"}}
            alt="decision box" 
          />
        </p>

        <p>4.	After each decision, you will be presented with another <i>Storm Update</i> and a new decision 
        opportunity. Sometimes popup announcements will appear over the screen with a new Storm Update. 
        These may describe local officials’ actions or important storm events such as the onset 
        of tropical-storm-force winds at the target location. Continue through the storm’s life cycle 
        until you reach the end of the simulation. 
          <ul style={{listStyleType: "circle", marginLeft: "30px"}}>
            <li><i>Storm Updates</i> will provide a new set of forecast products. Updates occur every six 
            hours of storm time – at 0500, 1100, 1700 and 2300 Atlantic Standard Time (AST), 
            which matches the NHC’s forecast update schedule. AST is the same as Eastern daylight 
            savings time.</li>
          </ul>
        </p>

        <p>5.	Utilize the various <a className="splash-nav" href="#user-interface">Decision Making Tools and Views</a> as 
        you go to help assess the situation and make decisions. </p>

        <p><strong>You are encouraged to review your actions and run through additional storm simulations 
          many times to gain the broadest experience.</strong></p>
        
        <a className="splash-nav back-to-top" href="#top">Back to Top</a>
        
        <a name="objective" id="simulation-over" />
        <h3>Simulation Over (End Screen)</h3>
        <p>The simulation ends when the storm makes landfall near the storm's target location, 
          reaches a point where it no longer threatens the target location, or dissipates without 
          making landfall. At the end of each simulation, the results of the simulation and your 
          decisions will be described. At this time you may:
          <ul style={{listStyleType: "circle", marginLeft: "30px"}}>
            <li>Begin <i>New Simulation</i> - Returns you to the simulation's home page and loads a new storm.</li>
            <li><i>Review</i> simulation - returns you to the current storm’s simulation environment 
            where you may use the interactive timeline or <i>Record of Events</i> to review the forecast 
            products, the record of events, and the various actions and decisions that were made.</li>
            <li>Or, close the browser window to exit.</li>
          </ul>
        </p>
        <p>
          <img
            className="init-map-image"
            src={`help_screens/simulation-over.png`}
            style={{width: "600px"}}
            alt="simulation over/results screen" 
          />
        </p>

        <a className="splash-nav back-to-top" href="#top">Back to Top</a>

        <a name="user-interface" id="user-interface" />
        <h3>The User Interface: Decision Making Tools and Views</h3>

        <p><strong>To aid in decision making, use any of these tools and interface features, at anytime. </strong></p>
        
        <a className="splash-nav back-to-top" href="#top">Back to Top</a>

        <a name="objective" id="probabilities" />

        <h3>The Wind Speed Probabilities Map</h3>
        <p>This map shows the probability of each location on the map experiencing winds exceeding 
          the selected wind speed threshold (selected in tabs above the map) at any time over 
          the next 120 hours (5 days) from the time indicated in the text at the bottom of the map. 
          The 39-mph threshold is selected in this example, which is the default view.</p>
        <p>
          <img
            className="probabilites-map-image"
            src={`help_screens/probabilities-map.png`}
            style={{width: "661px"}} alt="probabilities map example" />
        </p>

        <a className="splash-nav back-to-top" href="#top">Back to Top</a>
        
        <a name="objective" id="record-of-events" />
        
        <h3>Record of Events (sidebar)</h3>
        <p>The Record of Events allows you to view past storm updates, details, and decisions. 
          Clicking on any event will reset the user interface to previous states and views. 
          You can use the Actions and Details links to view detailed information. Some actions 
          presented within the simulation will be dependent on previous decisions that you will 
          have made.</p>
        
        <p>
          <img
            className="record-events-image"
            style={{width: "661px"}}
            src={`help_screens/record-of-events.png`} />
        </p>

        <p><i>*Expected Landfall. This is a worst-case scenario of a direct hit. It is calculated 
          using the speed of forward motion of the official forecast, but the track is straightened 
          and redirected to the location of interest. This is similar to FEMA’s HURREVAC decision 
          support tool (<a href="http://www.hurrevac.com/about.htm" target="_blank">
          http://www.hurrevac.com/about.htm</a>). Landfall timing estimates in the forecast 
          details are based on the time the official forecast track crosses the coast.</i></p>

        <a className="splash-nav back-to-top" href="#top">Back to Top</a>

        <a name="objective" id="map-views" />

        <h3>Map Views (Threshold and Cone Buttons)</h3>
        <p>For each <i>Storm Update</i> four different map views are available. These are accessed 
        via these four buttons located above the map. At any time during the simulation you can 
        change the map view by selecting these buttons:</p>
        
        <p>
          <img
            src={`help_screens/Threshold-Buttons.png`}
            className="threshold-buttons-image"
            alt="Threshold Buttons: 39mph, 58mph, 74mph, Cone." />
        </p>
        
        <p>Note that the probability will change if you change the wind-speed threshold. For example, 
          there could be a 90% chance of NOLA experiencing 39-mph winds but only a 20% chance 
          that it will experience 58-mph winds. Selecting the “Cone” button will display a map 
          showing the storm’s forecast path and the Track Forecast Cone. </p>
        
        <a className="splash-nav back-to-top" href="#top">Back to Top</a>

        <a name="objective" id="cone" />

        <h3>Track Forecast Cone</h3>
        <p>The shaded areas represent the uncertainty around the forecast position. 
          This shows the forecast center positions 9, 21, 33, 45, 69, 93 and 117 hours from the time the 
          update is issued, together with a cone enclosing positions within the range of likely track errors. 
          The center position of the storm will stay within the cone about 70% of the time, but the impacts 
          may cover a much larger area, depending on the size and intensity of the storm. 
          The wind speed probability plots (above) showing the probability of winds exceeding a 
          given threshold combine the effects of the uncertainty in the storm track, intensity and size. 
          Training yourself to use this information should improve your decision-making under uncertainty.</p>
        <p>
          <img
            className="probabilites-cone-image"
            style={{width: "661px"}}
            src={`help_screens/probabilities-cone.png`}
            alt="Cone view detail." />
        </p>

        <a className="splash-nav back-to-top" href="#top">Back to Top</a>

        <a name="objective" id="interactive-timeline" />

        <h3>Interactive Timeline</h3>
        <p>You can navigate to past updates using the individual timeline markers on the 
          "Interactive Timeline" along the bottom (one update circled in yellow).</p>

        <p> 
          <img
            className="splash-details-box-image"
            src={`help_screens/interactive-timeline.png`}
            style={{width: "653px"}}
            width={830} height={184} alt="Interactive Timeline" />
        </p>

        <a className="splash-nav back-to-top" href="#top">Back to Top</a>

        <a name="objective" id="auto-advance" />
        
        <h3>Auto Advance</h3>

        <p>This feature allows you to run through various simulations more rapidly either to 
          get a feel for how things operate, simulate a time-lapse view of the storm's path, 
          or if you simply want to get to the end in order to start over, more quickly. </p>
        
        <p> 
          <img
            className="splash-details-box-image"
            src={`help_screens/auto-advance.png`}
            style={{width: "170px"}}
            width={830} height={184} alt="Auto Advance Button" />
        </p>

        <a className="splash-nav back-to-top" href="#top">Back to Top</a>

        <a name="objective" id="help" />

        <h3>Contextual and Global Help</h3>
        <p>
          <ul style={{listStyleType: "circle", marginLeft: "30px"}}>
            <li>Use the Global Help link (upper left), to return to these instructions at any time 
              during the simulation.
              <br/>
              <img
                className="splash-details-box-image"
                src={`help_screens/help-button.png`}
                style={{width: "75px", marginTop: "10px"}}
                width={830} height={184} alt="Help Button" />
            </li>
            <li>Use Contextual Help icons (located by various screen elements) to review 
              interface elements from within the simulation.</li>
          </ul>
        </p>
        <a className="splash-nav back-to-top" href="#top">Back to Top</a>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let { entities: {storms}, currentStorm} = state;
  let cityName = storms[currentStorm].cityName;  
  let cityNameShort = storms[currentStorm].cityNameShort;

  return {
    cityName,
    cityNameShort,
  };
};


export default connect(mapStateToProps)(Instructions);
