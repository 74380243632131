import * as React from "react";
import { closeDialog } from "../actions/dialog";
import Dialog from "./BetterDialog";
import { connect } from "react-redux";
import { padStart } from "lodash";
import { BASE_URL } from "../../../../config";

export let name = "initial-storm-update";

export class InitialStormUpdate extends React.Component<any, any> {

  constructor(params) {
    super(params);
  }

  render() {
    let { event, mapUrl, cityName, dispatch } = this.props;

    return (
      <div>
        <div className="dialog-header">
          <h2>Storm Update</h2>
          <span className="grab-handle-icon" />
          <span
            className="close-icon"
            onClick={(e) => dispatch(closeDialog({name: name}))} />
        </div>
        <div className="dialog-body">
          <div className="left-column">
            <div className="simulation_history">
              <p>A tropical depression has formed at {event.stormCenterLatitude.toFixed(1)}°N
                 and {event.stormCenterLongitude.toFixed(1)}°W and may affect {cityName}.</p>
              <p>Storms forming with these characteristics are very likely to threaten {cityName}.</p>
              <a className="btn btn-primary" onClick={(e) => dispatch(closeDialog({name: name}))}>Continue</a>
            </div>
          </div>
          <div className="right-column">
            <img className="simulation_begin_image" src={mapUrl} />
            <div className="latitude-longitude-guide"><div className="latitude-guide">
                <span className="north-text map-north-text-icon" />
                <div className="latitude container-35">
                  <span className="latitude-label">35°</span>
                </div>
                <div className="latitude container-30">
                  <span className="latitude-label">30°</span>
                  <span className="latitude-tick" />
                </div>
                <div className="latitude container-25">
                  <span className="latitude-label">25°</span>
                  <span className="latitude-tick" />
                </div>
                <div className="latitude container-20">
                  <span className="latitude-label">20°</span>
                  <span className="latitude-tick" />
                </div>
                <div className="latitude container-15">
                  <span className="latitude-label">15°</span>
                  <span className="latitude-tick" />
                </div>
                <div className="latitude container-10">
                  <span className="latitude-label">10°</span>
                </div>
              </div>
              <div className="longitude-guide">
                <span className="west-text map-west-text-icon" />
                <div className="longitude container-95">
                  <span className="longitude-tick" />
                  <span className="longitude-label">95°</span>
                </div>
                <div className="longitude container-90">
                  <span className="longitude-tick" />
                  <span className="longitude-label">90°</span>
                </div>
                <div className="longitude container-85">
                  <span className="longitude-tick" />
                  <span className="longitude-label">85°</span>
                </div>
                <div className="longitude container-80">
                  <span className="longitude-tick" />
                  <span className="longitude-label">80°</span>
                </div>
                <div className="longitude container-75">
                  <span className="longitude-tick" />
                  <span className="longitude-label">75°</span>
                </div>
                <div className="longitude container-70">
                  <span className="longitude-tick" />
                  <span className="longitude-label">70°</span>
                </div>
                <div className="longitude container-65">
                  <span className="longitude-tick" />
                  <span className="longitude-label">65°</span>
                </div>
              </div>
            </div>
          </div>
          <div className="simulation_begin_button_container">
            <a href="javascript:;" className="btn btn-primary btn-big continueButton">CONTINUE</a>
          </div>
        </div>
      </div>

    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let { entities: { events, storms }, currentStorm } = state;
  let event = events[0];
  let storm = storms[currentStorm];
  let cityName = storm.cityNameShort;
  let cityname = cityName.replace(/\s/g, '').toLowerCase();
  let mapUrl = `${BASE_URL}/${cityname}/${storm.id}/Images/${padStart(`${events[0].fileNumber}`, 3, "0")}/current_wind.png`;

  return {
    event,
    cityName,
    mapUrl,
    direction: state.dialogs[name] ? state.dialogs[name].direction : "from-bottom",
  };
};

export let ReduxEventActions = connect(mapStateToProps, null)(InitialStormUpdate);

export let ReduxInitialStormUpdateDialog = (props) =>
  <Dialog
    name={name}
    dialogClassName="dialog dialog-simulation-begin re-opened"
    styleOpened={({ dialogElement, dialogProps }) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `${document.body.offsetHeight / 2 - dialogElement.offsetHeight / 2 + 6}px`,
      };
    }}
    styleClosed={({ dialogElement, dialogProps }) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `${document.body.offsetHeight + 10}px`,
        transitionTimingFunction: `ease`,
      };
    }}
    transitionDuration={600}
    hasOverlay={true}
  >
    <ReduxEventActions/>
  </Dialog>;

export default ReduxInitialStormUpdateDialog;
