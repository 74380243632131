import * as React from "react";
import * as ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { startAutoAdvance, stopAutoAdvance } from "../../../../actions/autoAdvance";
import { goToNextEvent } from "../../../../actions/event";
import animationTimings from "../../../../misc/animationTiming";
let {
  autoAdvance: {
    autoAdvanceTiming,
  }
} = animationTimings;

let delayDataTiming = animationTimings.dataTip.delayDataTiming;

export class AutoAdvance extends React.Component<any, any> {

  state = {
    hovering: false,
  };

  animationTimer = null;

  handleStartAutoAdvance() {
    this.props.dispatch(startAutoAdvance());
    this.props.dispatch(goToNextEvent());
    this.animationTimer = setInterval(() => {
      if (this.props.autoAdvance && this.props.eventsRemaining > 0) {
        this.props.dispatch(goToNextEvent());
      }
    }, autoAdvanceTiming);
  }

  handleStopAutoAdvance() {
    this.props.dispatch(stopAutoAdvance());
    clearTimeout(this.animationTimer);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentEvent !== this.props.currentEvent) {
      if (this.props.autoAdvance && this.props.eventsRemaining < 1) {
        clearTimeout(this.animationTimer);
      }
    }
  }

  render() {
    let { autoAdvance } = this.props;
    let visible = {display: "inline"};
    let hidden = {display: "none"};
    return (
      <div>
        <a
          className="btn btn-big playButton"
          data-for="auto-advance"
          data-tip="Fast forward this simulation to end"
          data-delay-show={delayDataTiming}
          style={autoAdvance ? hidden : visible}
          onMouseOver={() => this.setState({hovering: true})}
          onMouseOut={() => this.setState({hovering: false})}
          onClick={() => this.handleStartAutoAdvance()}>
          AUTO ADVANCE
          <span className={this.state.hovering ? "play-icon play-icon-hover" : "play-icon"}></span>
        </a>
        <ReactTooltip class="custom-tool-tip" id="auto-advance" place="top" type="dark" effect="solid" />
        <a
          className="btn btn-big btn-danger stopButton"
          style={autoAdvance ? visible : hidden}
          onClick={() => this.handleStopAutoAdvance()}>
          STOP
          <span className="stop-icon"></span>
        </a>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let { autoAdvance, currentEvent, currentStorm, entities: { storms } } = state;
  let eventKeys = storms[currentStorm].events;
  let eventsRemaining = eventKeys.slice(currentEvent + 1, eventKeys.length).length;
  return {
    autoAdvance,
    currentEvent,
    eventsRemaining,
  };
};

export default connect(mapStateToProps)(AutoAdvance);
