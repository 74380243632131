import * as React from "react";

export class Overview extends React.Component<any, any> {
  render() {
    return (
      <div className="content">

        <p className="tagline">This simulator provides users with practice making critical hurricane-preparation 
          decisions and interpreting tropical-storm forecasts with many simulated storms 
          representing a range of possible storm events and forecasts.</p>

        <h2>OVERVIEW</h2>

        <p>Preparing for a hurricane requires high-stakes decisions, especially for a Commander 
          responsible for people's lives and for mission readiness. While the consequences of 
          failing to evacuate—or of evacuating too late—can be deadly, false alarms are also costly. 
          The high stakes are also coupled with uncertainty over the exact path, size, and intensity 
          of the storm.</p>

        <p>Forecasts are not perfect, just like real forecasts. Only by running through the 
          simulation many times can users improve their ability to incorporate uncertain 
          hurricane forecasts into their preparation decisions.</p>
          
        <p>While it is designed for Marine Forces Reserve personnel, the simulator can help anyone gain 
          a greater familiarity with the evolution of tropical storms and hurricanes in the region 
          and gain experience with the challenging problem of making decisions with uncertain and 
          evolving forecasts.</p>
          
      </div>
    );
  }
}

export default Overview;
