import { START_AUTO_ADVANCE, STOP_AUTO_ADVANCE } from "../actions/autoAdvance.types";

export let reducer = (state = false, action) => {
  switch (action.type) {
    case START_AUTO_ADVANCE: {
      return true;
    }
    case STOP_AUTO_ADVANCE: {
      return false;
    }
    default:
      return state;
  }
};

export default reducer;
