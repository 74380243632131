import { START_EXPERIMENT, ADD_EVENT_TO_EXPERIMENT, ADD_DECISION_TO_EXPERIMENT } from "./experimentalData.types";
import { merge, isEmpty } from "lodash";
import { setSimulationOver } from "./simulationOver";
import { formatTime, filterEventData } from "./experimentalData/helpers";

import * as request from "superagent";

export const startExperiment = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const startTime = new Date();
    const location = state.entities.storms[state.currentStorm].location;
    const stormGuid = state.currentStorm;
    const stormId = state.entities.storms[state.currentStorm].id
    const stormName = state.entities.storms[stormGuid].stormName;
    const numberOfEvents = Object.keys(state.entities.events).length;
    const numberOfDecisions = Object.keys(state.entities.decisions).length;

    const firstEvent = {
      ...filterEventData(state.entities.events[state.currentEvent]),
      timeEventOccuredFromStart: "00:00",
      timeEventOccured: startTime,
    }

    // Data to send to server only, not reducer
    const dataToSend = {
      location,
      stormId,
      stormName,
      timeStarted: startTime.toJSON(),
      completedSimulation: false,
      numberOfEvents,
      numberOfCompletedEvents: 1,
      numberOfDecisions,
      numberOfDecisionsMade: 0,
      firstEvent,
    }

    // Send data to server
    const response = await request.post(`${process.env.BASE_URL}/api/experimentalData`).send(dataToSend);

    await dispatch(merge({}, {
      // Gets the session id back from the response
      sessionId: JSON.parse(response.text).output,
      type: START_EXPERIMENT,
      startTime,
      stormId,
      stormName,
      numberOfEvents,
      numberOfDecisions,
      firstEvent,
    }));

  };
};

export const addEventToExperiment = (options: {newEvent: {object}}) => {
  return async (dispatch, getState) => {

    const state = getState();
    const timeEventOccured = new Date();
    const timeEventOccuredFromStart = (Number(timeEventOccured) - state.experimentalData.startTime)/1000;
    const formattedTime = formatTime(timeEventOccuredFromStart);
    const numberOfCompletedEvents = state.experimentalData.numberOfCompletedEvents + 1;
    const completedSimulation = numberOfCompletedEvents === state.experimentalData.numberOfEvents;
  
    const newEvent = {
      ...filterEventData(options.newEvent),
      timeEventOccuredFromStart: formattedTime,
      timeEventOccured: timeEventOccured
    }

    // Data to send to server only, not reducer
    const dataToSend = {
      sessionId: state.experimentalData.sessionId,
      totalTime: formattedTime,
      numberOfCompletedEvents,
      completedSimulation,
      newEvent,
    }

    await dispatch(merge({}, {
      type: ADD_EVENT_TO_EXPERIMENT,
      newEvent,
      numberOfCompletedEvents
    }, options));

    // Send to server
    request.put(`${process.env.BASE_URL}/api/experimentalData/addEvent`).send(dataToSend).end((err, res) => {
      if (err || !res.ok) {
        console.error(`ERROR SENDING EXPERIMENTAL DATA TO /api/experimentalData/addEvent`, err);
      }
    });

  };
};

export const addDecisionToExperiment = (options: {event: number, decision: object, choiceMade: string}) => {
  return async (dispatch, getState) => {
  
    const state = getState();
    const { entities, experimentalData, currentStorm } = state;
    const { events } = entities;

    const timeDecisionOccured = new Date();
    const timeDecisionOccuredFromStart = (Number(timeDecisionOccured) - experimentalData.startTime)/1000;
    const eventWithDecision = experimentalData.events[options.event];
    const timeDecisionOccuredFromEvent = (Number(timeDecisionOccured) - eventWithDecision.timeEventOccured)/1000;
    const numberOfDecisionsMade = experimentalData.numberOfDecisionsMade + 1;
    const formattedTimeFromStart = formatTime(timeDecisionOccuredFromStart);
    const formattedTimeFromEvent = formatTime(timeDecisionOccuredFromEvent);
    const hasNewDecTimingAnnouncements = events[options.event].decisionTimingAnnouncements !== eventWithDecision.decisionTimingAnnouncements;
    const newDecTimingAnnouncements = hasNewDecTimingAnnouncements ? events[options.event].decisionTimingAnnouncements.join() : null;
    const newActions = events[options.event].actions !== eventWithDecision.actions ? events[options.event].actions.join() : null;

    const decision = {
      ...options.decision,
      timeMadeFromStart: formattedTimeFromStart,
      timeMadeFromEvent: formattedTimeFromEvent,
      choiceMade: options.choiceMade,
    }

    // Data to send to server only, not reducer
    const dataToSend = {
      sessionId: state.experimentalData.sessionId,
      eventOrder: options.event,
      decision,
      numberOfDecisionsMade,
      totalTime: formattedTimeFromStart,
      newDecTimingAnnouncements,
      newActions,
    }

    await dispatch(merge({}, {
      type: ADD_DECISION_TO_EXPERIMENT,
      decision,
      numberOfDecisionsMade,
      totalTime: formattedTimeFromStart,
      newDecTimingAnnouncements,
      newActions,
    }, options));

    // If there are no more decisions, end early
    // if (numberOfDecisionsMade === experimentalData.numberOfDecisions) {
    //   const eventIds = entities.storms[currentStorm].events;
    //   let remainingEvents = {};
      
    //   eventIds.slice(eventIds.indexOf(options.event) + 1).map(id => {
    //     remainingEvents[id] = events[id];
    //   });
    //   dispatch(endExperiment({remainingEvents}));
    // }

    // Send to server
    request.put(`${process.env.BASE_URL}/api/experimentalData/addDecision`).send(dataToSend).end((err, res) => {
      if (err || !res.ok) {
        console.error(`ERROR SENDING EXPERIMENTAL DATA TO /api/experimentalData/addDecision`, err);
      }
    });

  };
};

// Sends final data to the server, such as total time, end date, etc.
export const endExperiment = (options: {remainingEvents: object}) => {

  return async(dispatch, getState) => {
    const state = getState();
    const currentTime = new Date();
    const totalTime = (Number(currentTime) - state.experimentalData.startTime)/1000;
    const formattedTotalTime = formatTime(totalTime);

    // Data to send to server
    const dataToSend = {
      sessionId: state.experimentalData.sessionId,
      totalTime: formattedTotalTime,
      timeCompleted: currentTime.toJSON(), 
      remainingEvents: options.remainingEvents,
    }

    // If there are remaining events, end the simulation in the UI.
    if (!isEmpty(options.remainingEvents)) {
      await dispatch(setSimulationOver({endingEarly: true}));
    }

    // Send to server
    request.put(`${process.env.BASE_URL}/api/experimentalData/finalize`).send(dataToSend).end((err, res) => {
      if (err || !res.ok) {
        console.error(`ERROR SENDING EXPERIMENTAL DATA TO /api/experimentalData/finalize`, err);
      }
    });

  };
};