import * as React from "react";
import * as ReactTooltip from "react-tooltip";
import ProgressTooltip from "./progressTooltip/";
import { openDialog } from "../../../actions/dialog";
import { changeThreshold } from "../../../actions/ui";
import { connect } from "react-redux";
import { formatDate, mphToKnots } from "../../../misc/formatter";
import { padStart } from "lodash";
import animationTimings from "../../../misc/animationTiming";
import * as config from "../../../../../../config";

let delayDataTiming = animationTimings.dataTip.delayDataTiming;

export class Content extends React.Component<any, any> {

  previousMarkerPosition;
  nextMarkerPosition =  {left: `calc(${this.props.percentageLeft}%)`, top: `calc(${this.props.percentageTop}% + 6px)`};

  state = {
    mapHasLoaded: false
  };

  componentWillReceiveProps(nextProps) {
    // Keeps the marker on the previous map position until the next map loads, then moves it to the new map position.
    if (nextProps.mapUrl !== this.props.mapUrl) {
      this.setState({mapHasLoaded: false});
      this.previousMarkerPosition = {left: `calc(${this.props.percentageLeft}%)`, top: `calc(${this.props.percentageTop}% + 6px)`};
      this.nextMarkerPosition = {left: `calc(${nextProps.percentageLeft}%)`, top: `calc(${nextProps.percentageTop}% + 6px)`};
    }
  }


  render() {
    let {
      threshold,
      dispatch,
      date,
      cityNameShort,
      mapUrl,
      cityMarkerVisible,
    } = this.props;

    return (
      <div className="contentViewPanel">

        <div className="probabilitySpeeds">
          <h2 className="title wide">PROBABILITIES</h2>
          <span className="title description">(of Winds Exceeding Threshold)</span>
          <span
            className="help-icon"
            data-for="probabilities-help"
            data-tip="Click to better understand the Probabilities Map"
            data-delay-show={delayDataTiming}
            onClick={(e) => dispatch(openDialog({name: "probabilities-help"}))}>
            &nbsp;
          </span>
          <ReactTooltip class="custom-tool-tip" id="probabilities-help" place="right" type="dark" effect="solid" />
          <div className="row wind-speed">
            <a
              className={"wind-speed-button-39mph " + (threshold === "39" ? "current" : null)}
              data-tip="Set Threshold to 39 mph"
              data-delay-show={delayDataTiming}
              onClick={(e) => dispatch(changeThreshold({threshold: "39"}))}>
              39 mph
            </a>
            <a
              className={"wind-speed-button-58mph " + (threshold === "58" ? "current" : null)}
              data-tip="Set Threshold to 58 mph"
              data-delay-show={delayDataTiming}
              onClick={(e) => dispatch(changeThreshold({threshold: "58"}))}>
              58 mph
            </a>
            <a
              className={"wind-speed-button-74mph " + (threshold === "74" ? "current" : null)}
              data-tip="Set Threshold to 74 mph"
              data-delay-show={delayDataTiming}
              onClick={(e) => dispatch(changeThreshold({threshold: "74"}))}>
              74 mph
             </a>
            <a
              className={"wind-speed-button-cone " + (threshold === "CONE" ? "current" : null)}
              data-tip="Display Cone"
              data-delay-show={delayDataTiming}
              onClick={(e) => dispatch(changeThreshold({threshold: "CONE"}))}>
              Cone
            </a>
            <span className="time">{date}</span>
          </div>
        </div>

        <div className="mapContainer">
          <img className="map" src={`${mapUrl}`} onLoad={(e) => this.setState({mapHasLoaded: true})}/>
          <div className="markerContainer" style={!cityMarkerVisible ? {display: "none"} : null}>
            <span
              className="city"
              style={!cityMarkerVisible || !this.state.mapHasLoaded ? this.previousMarkerPosition : this.nextMarkerPosition}
              data-tip={cityNameShort}
              data-delay-show={delayDataTiming}
            ></span>
          </div>
        </div>

        <div className="probabilityKeyContainer" style={{height: "327px"}}>

        </div>

         <ProgressTooltip/>

      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let { ui: { threshold }, selectedEvent, entities: { events, storms }, currentStorm, currentEvent } = state;
  let date = formatDate(events[selectedEvent].date);
  let storm = storms[currentStorm];
  let cityNameShort = storm.cityNameShort;
  let stormId = storm.id;
  let cityname = cityNameShort.replace(/\s/g, '').toLowerCase();
  let fileNumber = events[selectedEvent].fileNumber;
  let mapUrl = `${config.BASE_URL}/${cityname}/${stormId}/Images/${padStart(fileNumber, 3, "0")}/`;
  let cityLatitude = storms[currentStorm].cityLatitude;
  let cityLongitude = storms[currentStorm].cityLongitude;
  let extentsType = events[selectedEvent].mapExtentsWinds34;

  switch (threshold) {
    case "39": {
      mapUrl += `wind_probs_34_F120.png`;
      extentsType = events[selectedEvent].mapExtentsWinds34;
      break;
    }
    case "58": {
      mapUrl += `wind_probs_50_F120.png`;
      extentsType = events[selectedEvent].mapExtentsWinds50;
      break;
    }
    case "74": {
      mapUrl += `wind_probs_64_F120.png`;
      extentsType = events[selectedEvent].mapExtentsWinds64;
      break;
    }
    case "CONE": {
      mapUrl += `5day_cone_no_line.png`;
      let coneExtents = events[selectedEvent].mapExtentsCone5Day;
      extentsType = coneExtents ? coneExtents : false;
      break;
    }
  }

  let percentageLeft = 0;
  let percentageTop = 0;
  let cityMarkerVisible = false;

  if (extentsType) {
    // Latitude is subtracted by 360 to cut it down to the visible area
    let extentsLatitude = threshold === "CONE" ? extentsType.latitude : extentsType.latitude.map(num => 360 - num);
    let extentsLongitude = extentsType.longitude;

    // Percentages location marker (star) will be from left and top of its container div
    percentageLeft = 100 * (1 - ((cityLatitude - extentsLatitude[1]) / (extentsLatitude[0] - extentsLatitude[1])));
    // CONE threshold's longitude is off a bit. To fix this, I added 4 to extentsLongitude[1] if the threshold is CONE
    let extentLongitudeCalculated = threshold === "CONE" ? extentsLongitude[1] - 4 : extentsLongitude[1];
    percentageTop = 100 * ((cityLongitude - extentLongitudeCalculated) / (extentsLongitude[0] - extentsLongitude[1]));

    let cityLocationInLatitudeRange = (cityLatitude < extentsLatitude[0]) && (cityLatitude > extentsLatitude[1]);
    let cityLocationInLongitudeRange = (cityLongitude > extentsLongitude[0]) && (cityLongitude < extentsLongitude[1]);
    cityMarkerVisible = cityLocationInLatitudeRange && cityLocationInLongitudeRange && extentsType;

  }

  return {
    threshold,
    date,
    cityNameShort,
    mapUrl,
    extentsType,
    percentageLeft,
    percentageTop,
    cityMarkerVisible
  };
};

export default connect(mapStateToProps)(Content);
