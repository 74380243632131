import { combineReducers } from "redux";
import actions from "./actions";
import windResults from "./windResults";
import surgeResults from "./surgeResults";
import announcements from "./announcements";
import decisions from "./decisions";
import decisionCosts from "./decisionCosts";
import events from "./events";
import userDecisions from "./userDecisions";
import currentDecision from "./currentDecision";
import results from "./results";
import storms from "./storms";
import decisionTiming from "./decisionTiming";
import decisionStorm from "./decisionStorm";
import decisionAnnouncement from "./decisionAnnouncement";

export let reducer = combineReducers({
  actions,
  windResults,
  surgeResults,
  announcements,
  decisions,
  decisionCosts,
  events,
  userDecisions,
  currentDecision,
  results,
  storms,
  decisionTiming,
  decisionStorm,
  decisionAnnouncement,
});

export default reducer;
