import { SET_CURRENT_EVENT, SET_SELECTED_EVENT} from "./event.types";
import { setSimulationOver } from "./simulationOver";
import { addEventToExperiment } from "./experimentalData";
import { merge } from "lodash";

export let goToNextEvent = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const { entities: {storms, events}, currentEvent, currentStorm } = state;
    const newCurrentEvent = events[currentEvent + 1] ? events[currentEvent + 1].order : false;
    const nextEvent =  events[currentEvent + 2] ? true : false;

    // If experimental, add the event data to experimentalData
    if (storms[currentStorm].experimental && newCurrentEvent) {
      await dispatch(addEventToExperiment({newEvent: events[newCurrentEvent]}));
    }

    // If at end of the simulation
    if (!newCurrentEvent) {
      await dispatch(setSimulationOver({endingEarly: false}));
      return;
    }

    await dispatch(merge({}, {
      type: SET_CURRENT_EVENT,
      event: newCurrentEvent
    }));

    await dispatch(merge({}, {
      type: SET_SELECTED_EVENT,
      event: newCurrentEvent
    }));

    // If auto advancing and at the end of the simulation
    if (state.autoAdvance && !nextEvent) {
      await dispatch(setSimulationOver({endingEarly: false}));
      return;
    }

  };
};

export let setSelectedEvent = (options: {
  event: number,
  props?: {},
}) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, {
      type: SET_SELECTED_EVENT,
    }, options));
  };
};