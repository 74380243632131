import * as React from "react";

export class History extends React.Component<any, any> {
  render() {
    let { name } = this.props;
    return (
      <div className="content">
        <h2>HISTORY</h2>

        <p>It is difficult for an individual to become experienced dealing with the full range 
          of possible storm events. From the perspective of a single career—even for an emergency 
          manager—a hurricane is a relatively rare event. While hurricanes occur almost every year 
          in the North Atlantic and Gulf of Mexico, on average there are fewer than 10 tropical 
          cyclones per year, and many will never make landfall. Most tropical storms never become 
          hurricanes and only about one or two per year will become major hurricanes.</p>

        <p>Every storm is different. Hurricane Katrina, in 2005, was a large and very intense hurricane 
          that made landfall about 30 miles from New Orleans (NOLA). Much of the city flooded when 
          the levee system failed in several places. The National Hurricane Center (NHC)’s forecasts 
          for conditions in NOLA were very accurate more than 48 hours in advance of landfall. However, 
          the mandatory evacuation of the city was not ordered until about 30 hours before tropical-storm-force 
          winds reached Plaquemines Parish, about 12 hours before the official landfall. Almost exactly a 
          year later, Hurricane Ernesto appeared to be heading toward NOLA, and city officials began 
          preparations to evacuate citizens, but the storm veered across Florida to the Atlantic coast.</p>

        <p>In 2012, Hurricane Isaac was forecast to make landfall in the Florida panhandle, 
          and 72 hours later made landfall in Louisiana as a Category 1 hurricane, and Marine 
          Forces Reserve (MFR) in NOLA prepared to evacuate and sent an advance team to alternate 
          headquarters. Because the forecast track moved over Louisiana less than 48 hours before 
          landfall, the advance team deployed amid a wider evacuation and was on the road for 5 
          hours before reaching Baton Rouge. Although the storm was less intense than Katrina, it 
          caused widespread flooding that disrupted essential services throughout the region.</p>

        <p>While 2004’s Hurricanes Ivan and Charley had remarkably similar forecast tracks as 
          they passed through the Caribbean, their eventual tracks were very different. Charley 
          speeded up and intensified, and eventually hit Punta Gorda in Florida as a Category 4 
          hurricane. At one point, the forecast probability of a hurricane strike in Miami was 
          over 15% for Ivan. However, Ivan made landfall in Alabama, circled overland through 
          North Carolina, back to the Atlantic, and passed over south Florida as a much weaker 
          storm, only to intensify again and make landfall in Texas.</p>
        
        <p>Tropical cyclones can also form close to shore, providing very little lead time 
          before landfall. In 2007, a tropical depression (weaker than a tropical storm) 
          called Humberto formed up just south of Louisiana, intensified to a hurricane 
          within 12 hours of the first NHC advisory, and made landfall as a Category 1 
          hurricane with wind speeds of 85 mph at the Texas-Louisiana border about 16 hours 
          after the first public advisory from NHC.</p>

        <p>NHC forecasts have improved dramatically in recent decades, but they are not be 
          perfect. In September 2017, Category 5 Hurricane Irma threatened the entire state 
          of Florida. Irma was forecast to pass directly over Miami as a Category 4 hurricane 
          less than 72 hours before Miami was spared sustained hurricane-force winds as the 
          storm’s center passed about 150 miles to the West. The forecast errors were typical 
          of recent NHC errors.</p>
        
        <h2>REFERENCES</h2>

        <ul>
          <li>
            Blake, et al (2011). <a href="http://www.aoml.noaa.gov/hrd/Landsea/nws-nhc-6.pdf" target="_blank">The deadliest, costliest, and most intense United States tropical cyclones from 1851 to 2010</a>. 
            NOAA Technical Memorandum NWS NHC-6.
          </li>
          <li>
            Landsea, C. W. (1993).  
            <a href="http://www.aoml.noaa.gov/hrd/Landsea/Landsea_MWRJune1993.pdf" target="_blank"> A climatology of intense (or major) Atlantic hurricanes</a>. 
            Monthly Weather Review 121(6), 1703–1713. 
          </li>
          <li>
            Landsea, C. W. (2007). <a href="http://www.aoml.noaa.gov/hrd/Landsea/landsea-eos-may012007.pdf" target="_blank">Counting Atlantic tropical cyclones back to 1900</a>. Eos 88(18), 197–202.
          </li>
        </ul>
      </div>
    );
  }
}

export default History;
