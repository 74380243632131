import { UPDATE_DECISION_TIMING } from "../../actions/decisionTiming.types";
import { merge } from "lodash";

export let reducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_DECISION_TIMING: {
      let newState = merge({}, state);
      // TODO: Make this and everything else (events, actions, etc.) only update what has changed
      newState = action.newDecisionTiming;
      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
