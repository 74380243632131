import * as React from "react";
import {closeDialog} from "../../actions/dialog";
import {formatDate} from "../../actions/helpers/actionHelpers";
import Dialog from "../BetterDialog";
import {connect} from "react-redux";
import {padStart} from "lodash";
import * as request from "superagent";

import EventLog from "./component/eventLog";

export let name = "userFeedback";

const dialogOpenCloseDuration = 600;
/** ms */
const closeAfterSubmissionDelay = 1000;

/** ms */

export class UserFeedback extends React.Component<any, any> {

  state = {
    feedback: null,
    errors: null,
    submitted: false,
  };

  log = "";

  constructor(params) {
    super(params);
    this.submit = this.submit.bind(this);
  }

  async submit() {
    let {reduxState, dispatch} = this.props;
    let {feedback} = this.state;
    let log = this.log;

    try {
      let response = await request.post(`api/feedback`).send({
        feedback,
        reduxState,
        log,
      });
      this.setState({submitted: true});
      setTimeout(() => {
        dispatch(closeDialog({name}));
        setTimeout(() => {
          this.setState({
            feedback: null,
            errors: null,
            submitted: false,
          });
        }, dialogOpenCloseDuration);
      }, closeAfterSubmissionDelay);
    } catch (e) {
      console.error(e.stack || e);
      this.setState({errors: e.message});
    }
  }

  render() {
    let {closeDialog, events, storm, entities: {actions, userDecisions, decisions, choices, announcements, decisionTiming}} = this.props;
    let {submitted} = this.state;
    return (
      <div>

        <div className="dialog-header">
          <h2>Feedback</h2>
          <span className="grab-handle-icon"/>
          <span
            className="close-icon"
            onClick={(e) => closeDialog({name})}/>
        </div>

        <div className="dialog-body">
          <a className="submit"
             onClick={e => {
               e.preventDefault();
               this.submit()
             }}
          >Submit</a>
          <div>
            <form onSubmit={e => {
              e.preventDefault();
              this.submit()
            }}>
              <textarea className="feedback" type="textbox" onChange={e => this.setState({feedback: e.target.value})}
                        defaultValue={""} placeholder={"Enter your feedback here..."}/>
            </form>
          </div>
          {!submitted ? null :
            <div className="submitted">
              Thank you for your feedback!
            </div>
          }
          <div style={{whiteSpace: "pre-wrap"}}>
            <EventLog onChangeLog={log => this.log = log}/>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let {entities: {events, storms}, currentStorm, entities, selectedEvent} = state;
  let storm = storms[currentStorm];
  let eventsArray = storm.events.map(eId => events[eId]);

  return {
    events: eventsArray,
    entities,
    storm,
    reduxState: state,
  };
};

let mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeDialog: (options) => dispatch(closeDialog(options))
  };
};

export let ReduxEventActions = connect(mapStateToProps, mapDispatchToProps)(UserFeedback);

export let ReduxUserFeedbackDialog = (props) =>
  <Dialog
    name={name}
    dialogClassName="dialog dialog-userFeedback"
    styleOpened={({dialogElement, dialogProps}) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `${document.body.offsetHeight / 2 - dialogElement.offsetHeight / 2 + 6}px`,
      };
    }}
    styleClosed={({dialogElement, dialogProps}) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `-${dialogElement.offsetHeight}px`,
        transitionTimingFunction: `ease`,
      };
    }}
    transitionDuration={dialogOpenCloseDuration}
    hasOverlay={true}
  >
    <ReduxEventActions/>
  </Dialog>;

export default ReduxUserFeedbackDialog;
