export const SET_EVENT_ANNOUNCEMENTS = "SET_EVENT_ANNOUNCEMENTS";
export const SET_ANNOUNCEMENT_RESULTS = "SET_ANNOUNCEMENT_RESULTS";
export const UPDATE_ANNOUNCEMENTS = "UPDATE_ANNOUNCEMENTS";

interface AnnouncementNoRelations {
  guid: string;
  conditionTriggers: string;
  id: string;
  maxLeadTime: number;
  minLeadTime: number;
  popupText: string;
  recordOfEventsText: string;
  resultsTextFull: string;
  resultsTextPartial: string;
}

export interface NormalizedAnnouncement extends AnnouncementNoRelations {};

export interface Announcement extends AnnouncementNoRelations {};

export interface AnnouncementsEntity { [guid: string]: NormalizedAnnouncement; };
