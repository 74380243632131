import * as React from "react";
import { connect } from "react-redux";

export class Feedback extends React.Component<any, any> {

  render() {
    let {
      announcements,
      results,
      decisionTiming,
      decisionStorm,
      decisionAnnouncement,
    } = this.props;

    let announcementResultGuids = results.announcements;
    let decisionTimingResults = results.decisionTiming;
    let decisionStormResults = results.decisionStorm;
    let decisionAnnouncementResults = results.decisionAnnouncement;

    return (
      <div className="simulation_end_feedback">
      
        <ul className="feedback-list">

          {decisionStormResults.length > 0 ?
            decisionStormResults.map((a, i) => {
              return <li className="feedbackText" key={i}>{decisionStorm[a].reportText}</li>;
            }) : null}

          {decisionTimingResults.violated.length > 0 ?
            decisionTimingResults.violated.map((a, i) => {
              return <li className="feedbackText" key={i}>{decisionTiming[a].resultsFeedbackTextIfViolated}</li>;
            }) : null}

          {decisionTimingResults.satisfied.length > 0 ?
            decisionTimingResults.satisfied.map((a, i) => {
              return <li className="feedbackText" key={i}>{decisionTiming[a].resultsFeedbackTextIfSatisfied}</li>;
            }) : null}

          {decisionAnnouncementResults.length > 0 ?
            decisionAnnouncementResults.map((a, i) => {
              return <li className="feedbackText" key={i}>{decisionAnnouncement[a].reportText}</li>;
            }) : null}

          {announcementResultGuids.length > 0 ?
            announcementResultGuids.map((a, i) => {
              return <li className="feedbackText" key={i}>{announcements[a].resultsTextFull}</li>;
            }) : null}

          {results.totalCost > 0 ? <li className="feedbackText">The cost of everything was ${results.totalCost.toLocaleString()}</li> : null}

        </ul>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let {
    entities: {
      announcements,
      decisionTiming,
      windResults,
      surgeResults,
      decisionStorm,
      decisionAnnouncement,
      results,
    }
  } = state;

  return {
    announcements,
    results,
    decisionTiming,
    windResults,
    surgeResults,
    decisionStorm,
    decisionAnnouncement,
  };
};

export default connect(mapStateToProps)(Feedback);
