import * as React from "react";
import * as ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { setSelectedEvent } from "../../../../actions/event";
import * as classNames  from "classnames";
import animationTimings from "../../../../misc/animationTiming";

let {
  sideBar: {
    animationTime,
  },
} = animationTimings;

let delayDataTiming = animationTimings.dataTip.delayDataTiming;

export class ProgressBar extends React.Component<any, any> {

  render() {
    let { event, position, dispatch, width, isSelected, hoursAgo } = this.props;
    return (
      <div>
        <li
          className={"progressEvent event " + classNames({"selectedEvent": isSelected })}
          data-tip={hoursAgo}
          data-for="event"
          data-delay-show={delayDataTiming}
          style={{
            width: width + "px",
            height: "14px",
            left: position + "px",
            transition: `${animationTime}ms`,
          }}
          onClick={(e) => dispatch(setSelectedEvent({event: event}))}>
        </li>
        <ReactTooltip class="custom-tool-tip" id="event" place="top" type="dark" effect="solid" />
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  return {
  };
};

export default connect(mapStateToProps)(ProgressBar);
