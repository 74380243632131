import { getDecisionRequired, getTimeSinceEvent, evaluateThresholdCondition, convertCondition } from "../helpers/actionHelpers";

import { find } from "lodash";

export let generateActionsFromState = (state, test = null) => {
  let { entities: { storms }, currentStorm } = state;
  
  let actions = Object.assign({}, state.entities.actions);
  let events = Object.keys(state.entities.events).map((k) => { 
    return state.entities.events[k]; 
  });


  events.forEach((event, eventIndex) => {
    if (event.order >= state.currentEvent) {
      return Object.assign(event, {
        actions: Object.keys(actions).filter(function(id) {
          const action = actions[id]

          // If action has not been assigned to an event yet, continue. Otherwise, skip action.
          if (action.event === undefined) {

            let triggerDecisionWasMade;
            let requirements = {
              maxLeadTime: true,
              minLeadTime: true,
              threshold: true,
              priorAction: true,
              priorDecision: true,
              prerequisiteDecisions: true,
            };
            
            // Runs through required decision condition and returns a useable condition with booleans
            // that determine whether the right decisions were made or not
            let findRequiredDecisions = (decisions, timeDelay) => {
              // Get the modified condition and list of decisions to look through from "convertCondition()"
              let { condition, listOfItems } = convertCondition(decisions);
              let triggerDecisions = listOfItems;
              // Loop dthrough each decision to see if it was made..
              triggerDecisions.forEach((decision) => {
                // Find the decision required
                let newDecision = getDecisionRequired(decision, state);
                // If there is a time delay, find out if the right amount of time passed
                let timeSinceEvent = 0;
                if (newDecision) {
                  // Get the time since that event
                  timeSinceEvent = getTimeSinceEvent(newDecision.event, event.order, storms[currentStorm].events);
                  // If time since event is greater than time delay, it's true. Add boolean to the condition
                  condition = condition.replace(decision, timeSinceEvent >= timeDelay);
                } else {
                  condition = condition.replace(decision, false);
                }
              });
              // Return the final condition
              return condition;
            };

            // handle Trigger Decision
            if (action.triggerDecisions) {
              // Get the condition to check whether the prior decision will be true or not
              let requiredDecision = findRequiredDecisions(action.triggerDecisions, action.timeDelay)
              // requiredDecision will be a string so it needs eval() called on it
              triggerDecisionWasMade = eval(requiredDecision)
            }

            // handle Prerequisite Decision
            if (action.prerequisiteDecisions) {
              // Get the condition to check whether the prerequisite decision will be true or not
              let requiredDecision = findRequiredDecisions(action.prerequisiteDecisions, action.timeDelay)
              // requiredDecision will be a string so it needs eval() called on it
              requirements.prerequisiteDecisions = eval(requiredDecision);
            }

            // handle prior action
            if (action.priorAction) {
              // Get list of events up to this event
              let eventsUpToCurrentEvent = events.slice(0, events.indexOf(event) + 1);
              // Get the condition and list of actions to check
              let { condition, listOfItems } = convertCondition(action.priorAction);
              let priorActionItems = listOfItems;

              // Run through each item in the list to see if the action has occured before.
              priorActionItems.forEach((priorAction) => {
                // Find the action needed given the id
                let actionNeeded = actions[find(Object.keys(actions), (actionId) => {
                  return actions[actionId].id === priorAction;
                })];

                // If it's not found, replace false in the condition
                if (!actionNeeded) {
                  condition = condition.replace(priorAction, false);
                }
                else {
                  // Go through all previous events and see if the action was ever triggered on one
                  let eventWithPriorAction = find(eventsUpToCurrentEvent, (e) => {
                    return e.actions.indexOf(actionNeeded.id) !== -1;
                  });
                  // If it replace true in the condition, if it wasn't replace false.
                  condition = condition.replace(priorAction, eventWithPriorAction ? true : false);
                }
              });
              requirements.priorAction = eval(condition);
            }

            let { conditionTriggers, maxLead, minLead } = action;

            // Find out if any of the conditionTriggers will trigger the action
            requirements.threshold = conditionTriggers ? evaluateThresholdCondition(conditionTriggers, event, null, true) : true;

            // check max lead & min lead
            event.estimatedHoursToLandfallHurrevac > maxLead ? requirements.maxLeadTime = false : null;
            event.estimatedHoursToLandfallHurrevac < minLead ? requirements.minLeadTime = false : null;

            let actionAppliesToEvent = false;

            if (action.triggerDecisions) {
              actionAppliesToEvent = triggerDecisionWasMade;
            }
            
            else if (
              !action.triggerDecisions &&
              requirements.priorAction &&
              requirements.maxLeadTime &&
              requirements.minLeadTime &&
              requirements.threshold &&
              requirements.prerequisiteDecisions
            ) {
              actionAppliesToEvent = true;
            }

            // Put the event ID on the action, so we can check for it later and make sure it doesn't repeat
            if (actionAppliesToEvent) {
              if (action.id === "act_core_team_updates") {
              }
              actions[id].event = event.order;
              // store a copy of action requirements in development mode, to assist debugging
              // action trigger cause
              if (process.env.NODE_ENV === "development") {
                event.actionRequirements = event.actionRequirements || {};
                event.actionRequirements[action.id] = requirements;
              }
            }

            return actionAppliesToEvent;
          }
        })//end filter
      });
    }
  });

  return {events, actions};
};
