import * as React from "react";
import { closeDialog } from "../actions/dialog";
import Dialog from "./BetterDialog";
import { connect } from "react-redux";
import * as classNames from "classnames";

export let name = "share";

export class Share extends React.Component<any, any> {

  state = {
    copied: false,
    copiedBlinking: false,
    clicks: 0,
    isMounted: false,
  };

  inputCopyToClipboard = null;
  btnCopyToClipboard = null;
  clipboard = null;
  copyToClipboardTimeout = null;

  constructor(params) {
    super(params);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  copyToClipboard() {
    let count = this.state.clicks;
    count ++;
    if (this.inputCopyToClipboard) {
      setTimeout(() => { this.inputCopyToClipboard.focus(); this.inputCopyToClipboard.select(); }, 0);
    }
    this.setState({ copied: true, copiedBlinking: true, clicks: count });
    clearTimeout(this.copyToClipboardTimeout);
    this.copyToClipboardTimeout = setTimeout(() => this.setState({ copiedBlinking: false }), 1000);
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isOpen && this.props.isOpen) {
      setTimeout(() => { this.inputCopyToClipboard.focus(); this.inputCopyToClipboard.select(); }, 0);
    } else if (prevProps.isOpen && !this.props.isOpen) {
      clearTimeout(this.copyToClipboardTimeout);
      this.setState({
        copied: false,
        copiedBlinking: false,
        clicks: 0
      });
    }
  }

  handleFocus(e) {
    e.target.focus();
    e.target.select();
  }

  render() {
    let { closeDialog, storms, currentStorm } = this.props;
    let { copied, copiedBlinking, clicks } = this.state;

    let shareUrl = "";
    if (process.env.JS_ENV === "browser") {
      let fullUrl = `${window.location.origin}${window.location.pathname}`;
      // TODO: make a "cityUrlName" so that it will use the folder name instead of actual city name 
      // which is sometimes different than the folder name
      let city = storms[currentStorm].location;
      let id = storms[currentStorm].id;
      shareUrl = `${fullUrl}?location=${city}&storm=${id}`;
    }

    return (
      <div>
        <div className="dialog-header">
          <h2>Share</h2>
          <span className="grab-handle-icon" />
          <span
            className="close-icon"
            onClick={() => { closeDialog({ name });
            this.setState({copied: false, clicks: 0}); }}/>
        </div>
        <div className="dialog-body">
          <p><strong>Share a Link to the Current Storm Simulation</strong></p>
          <div>
            <input
              className="share-storm-input"
              type="text"
              readOnly={true}
              value={this.state.isMounted ? shareUrl : ""}
              ref={(input) => {
                this.inputCopyToClipboard = input;
              }}
              onClick={(e) => this.handleFocus(e)}
              autoFocus={true}
              selected={true}
              spellCheck={false}></input>
          </div>

          { !copied ? null :
            <span
              className={"copied-to-clipboard " + (copiedBlinking && (clicks > 1) ? "copied-to-clipboard-blink" : null) }
              style={{display: "inline"}}>
              URL COPIED TO CLIPBOARD
            </span>
          }

          <div className="btn btn-primary btn-big copy-to-clipboard clipboard"
              ref={(btn) => {
                if (btn !== null) {
                  if (process.env.JS_ENV === "browser") {
                    this.btnCopyToClipboard = btn;
                    if (!this.clipboard) {
                      let Clipboard = require("clipboard");
                      this.clipboard = new Clipboard(btn);
                      this.clipboard.on("success", this.copyToClipboard);
                    }
                  }
                }
              }}
              data-clipboard-text={this.state.isMounted ? shareUrl : ""}>
              Copy to Clipboard
          </div>

        </div>
      </div>
    );
  }
}


let mapStateToProps = (state, ownProps) => {
  let { entities: { storms }, currentStorm } = state;
  return {
    storms, currentStorm
  };
};

let mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeDialog: (options) => dispatch(closeDialog(options)),
  };
};

export let ReduxShare = connect(mapStateToProps, mapDispatchToProps)(Share);

let ReduxShareDialog = (props) =>
  <Dialog
    name={name}
    dialogClassName="dialog dialog-share-storm"
    styleOpened={({ dialogElement, dialogProps }) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `${document.body.offsetHeight / 2 - dialogElement.offsetHeight / 2 + 6}px`,
      };
    }}
    styleClosed={({ dialogElement, dialogProps }) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `-${dialogElement.offsetHeight}px`,
        transitionTimingFunction: `ease`,
      };
    }}
    transitionDuration={600}
    hasOverlay={true}
  >
    <ReduxShare/>
  </Dialog>;

export default ReduxShareDialog;
