import * as React from "react";
import { connect } from "react-redux";
import * as classNames from "classnames";
import * as ReactTooltip from "react-tooltip";
import { goToNextEvent } from "../actions/event";
import { addUserDecision } from "../actions/decision";
import animationTimings from "../misc/animationTiming";
import { find } from "lodash";
let Draggable = require("react-draggable");

let {
  decisionDialog: {
    showAgainAfterSavedTiming,
    slideUpAndDownTransitionTiming,
    hideTransitionTiming,
    waitToShowOnFirstLoadTiming,
    waitToShowTiming,
    waitToHideTiming,
    waitToShowPausedBefore,
    waitToShowNotPausedBefore,
  },
  dataTip: {
    delayDataTiming,
  }
} = animationTimings;


export class Decision extends React.Component<any, any> {

  transition = {transition: `${slideUpAndDownTransitionTiming}ms`};

  state = {
    firstLoad: true,
    height: 0,
    pause: false,
    decisionSaved: false,
    isDragging: false,
    isHovering: false,
    isDocked: true,
    isMinimized: false,
    isExpanded: false,
    dialogIsOpen: false,
    position: {
      x: 0,
      y: 0
    }
  };

  hiddenPosition = {x: 0, y: this.state.height + 10};
  elDecisionHeight: HTMLDivElement = null;

  constructor(props) {
    super(props);
    this.handleOnDrag = this.handleOnDrag.bind(this);
    this.handleOnMinimize = this.handleOnMinimize.bind(this);
    this.handleOnCollapse = this.handleOnCollapse.bind(this);
    this.updateHeight = this.updateHeight.bind(this);
  }

  componentDidMount() {
    this.updateHeight();
    let newState = {
      position: {x: 0, y: 170}
    };
    if (this.state.decisionSaved) {
      setTimeout(function() {
        this.setState(newState);
      }.bind(this), showAgainAfterSavedTiming);
    }
    else {
      this.setState(newState);
    }
  }

  setBackToOriginalState() {
    if (!this.state.pause) {
      this.setState({
        decisionSaved: false,
        isDragging: false,
        isDocked: true,
        isMinimized: false,
        isExpanded: false,
        position: {
          x: 0,
          y: 0
        }
      });
    }
  }

  updateHeight() {
    if (this.elDecisionHeight) {
      this.setState({height: this.elDecisionHeight.clientHeight});
      this.hiddenPosition = { x: 0, y: this.elDecisionHeight.clientHeight + 26 };
    }
  }

  handleOnDrag(event, data) {
    let docked = this.state.isDocked;
    this.setState({
      isDragging: true,
      position: {x: data.x, y: data.y}
    });
    docked && data.x > 150 ? this.setState({isDocked: false, isExpanded: true}) : null;
    !docked && data.x <= 150 ? this.setState({isDocked: true, isExpanded: false}) : null;
    this.state.isMinimized ? this.setState({isDocked: true, isMinimized: false, isExpanded: false}) : null;    
  }

  handleOnHover() {
    this.state.isHovering ? this.setState({isHovering: false}) : this.setState({isHovering: true});
  }

  handleOnMinimize() {
    if (!this.state.isMinimized) {
      this.setState({
        isDragging: false,
        isMinimized: true,
        isDocked: true,
        isExpanded: false,
        position: {x: 0, y: this.state.height - 26}
      });
    }
    else if (this.state.isMinimized) {
      this.setState({
        isMinimized: false,
        position: {x: 0, y: 0}
      });
    }
  }

  handleOnHide(saved) {
    let newState = {
      isDragging: true,
      position: this.hiddenPosition
    };
    if (saved) {
      this.setState({decisionSaved: true});
    }
    else {
      this.setState({decisionSaved: false});
      this.setState(newState);
    }
  }

  handleOnCollapse() {
    if (!this.state.isExpanded) {
      this.setState({
        isDragging: false,
        isExpanded: true,
        isMinimized: false,
        isDocked: false,
        position: {x: 292, y: -225}
      });
    }
    else if (this.state.isExpanded) {
      this.setBackToOriginalState();
    }
  }

  handleOnAutoAdvance() {
    this.setState({
      decisionSaved: false,
      isExpanded: false,
      isDragging: false,
      isDocked: true,
      position: this.hiddenPosition
    });
  }

  componentDidUpdate(prevProps, prevState) {
    let { autoAdvance, currentEvent, isExperimental } = this.props;
    let { height, decisionSaved } = this.state;
    let prevInitialDialog = prevProps.dialogs["initial-storm-update"];
    let currentInitialDialog = this.props.dialogs["initial-storm-update"];
    let firstInitialUpdateClose = prevInitialDialog ? prevInitialDialog.isOpen && !currentInitialDialog.isOpen : false;
    let choiceMade = prevState.decisionSaved !== decisionSaved;
    const unpaused = prevState.pause && !this.state.pause;
    const eventChanged = prevProps.currentEvent !== currentEvent;
    //const onLastExperimentalDec = decisionIds.indexOf(currentDecision) + 1 === decisionIds.length && isExperimental && choiceMade;

    // Delay decision on first load.
    if (firstInitialUpdateClose && this.state.firstLoad) {
      this.updateHeight();
      setTimeout(() => {
        this.setState({firstLoad: false, position: {x: 0, y: height + 26}, isMinimized: true});
        this.handleOnMinimize();
      }, waitToShowOnFirstLoadTiming);
    }

    // If either a choice was made, or we are on a new event, OR it was just unpaused
    if (/*!onLastExperimentalDec && */((choiceMade || eventChanged) || (unpaused)) && !autoAdvance && !this.state.firstLoad) {
      this.transition = {transition: `${slideUpAndDownTransitionTiming}ms`};
    
      // Pause "decision saved!", then continue after
      if (this.state.pause) {
        this.setState({position: this.hiddenPosition});
      }

      else {
        // When user makes a decision
        if (decisionSaved && !this.state.pause && !autoAdvance) {
          setTimeout(() => {
            this.setState({position: this.hiddenPosition});
            setTimeout(() => {
              this.setBackToOriginalState();
              this.updateHeight();
            }, prevState.pause ? waitToShowPausedBefore : waitToShowNotPausedBefore);
          }, prevState.pause ? 0 : waitToHideTiming);
        }
        // When user selects "No, continue"
        if (!decisionSaved && /*!this.state.pause &&*/ !autoAdvance) {
            setTimeout(() => {
              this.setBackToOriginalState();
              this.updateHeight();
            }, waitToShowTiming);
        }
      }
    }

    // Handles decision hide on auto advance
    !prevProps.autoAdvance && autoAdvance ? this.handleOnAutoAdvance() : null;
    prevProps.currentEvent !== currentEvent && autoAdvance ? this.handleOnAutoAdvance() : null;

    // Prevent blinking if any dialog is open
    if (prevProps.dialogs !== this.props.dialogs) {
      let dialogIsOpen = find(this.props.dialogs, d => d.isOpen);
      this.setState({dialogIsOpen: dialogIsOpen ? true : false});      
    }
  }

  componentWillReceiveProps(nextProps) {
    // Set pause state if any announcements are showing
    if (this.props.announcementsBeingShown !== nextProps.announcementsBeingShown) {
      nextProps.announcementsBeingShown ? this.setState({pause: true}) : this.setState({pause: false});
    }
    // unhides decision box when auto advance is stopped.
    this.props.autoAdvance && !nextProps.autoAdvance && !nextProps.announcementsBeingShown ? this.setBackToOriginalState() : null;
  }

  render() {
    let { dispatch, decisions, currentDecision } = this.props;
    let minimized = this.state.isMinimized;
    let saved = this.state.decisionSaved;
    let newPosition = this.state.height - 87 + "px";
    let shouldNotBlink = this.state.decisionSaved || this.state.isDragging || this.state.isHovering || this.state.isExpanded;
    let containerClasses = "dialog-container dialog-non-modal";
    
    return (
      <Draggable
        position={this.state.position}
        bounds={{left: 0,  bottom: 0, right: 450, top: -420}}
        onStart={() => this.setState({isDragging: true})}
        onDrag={this.handleOnDrag}
        onStop={() => this.setState({isDragging: false})}
        handle=".grab-handle-icon">

        <div
          onMouseEnter={() => this.handleOnHover()}
          onMouseLeave={() => this.handleOnHover()}
          className={shouldNotBlink || this.state.dialogIsOpen ? containerClasses : containerClasses + " reminder-blink"}
          style={this.state.isDragging ? null : this.transition}>

          <div
            ref={(e) => this.elDecisionHeight = e }
            style={saved ? {bottom: newPosition} : {bottom: "3px"}}
            className={`dialog dialog-decision
            ${classNames({"docked-decision-event": this.state.isDocked})}`}>

            <div className="dialog-header">
              <h2>Decision</h2>
              <span className="grab-handle-icon"></span>

                <span
                  className={ minimized ? "maximize-icon" : "minimize-icon" }
                  data-for="minimize-maximize"
                  data-tip={ minimized ? "Maximize Window" : "Minimize Window" }
                  data-delay-show={delayDataTiming}
                  onClick={this.handleOnMinimize}>
                </span>

              <span
                className="collapse-icon-left"
                data-for="collapse"
                data-tip="Shrink and hide recommendations"
                data-delay-show={delayDataTiming}
                onClick={this.handleOnCollapse}>
              </span>

              <span
                className="collapse-icon-right"
                data-for="collapse"
                data-tip="Expand and view recommendations"
                data-delay-show={delayDataTiming}
                onClick={this.handleOnCollapse}>
              </span>

            </div>

            <div className="dialog-body">
              <div className="row top decision">

                { saved ?
                  <div className="decision-description-container">
                    <p className="decision-description">
                      Decision Saved!
                    </p>
                  </div> : null }

                { !saved ?
                  <div className="decision-description-container">
                    <p className="decision-description">
                    { currentDecision ? decisions[currentDecision].dialogShortText
                      : "There are no decisions at this time."}
                    </p>
                  </div> : null }

                { currentDecision && !saved ?
                  <div className="decision-recommendation-container">
                    <p className="decision-recommendation">
                    {decisions[currentDecision].dialogExtendedText}
                    </p>
                  </div> : null }

                { !saved ?
                  <div className="decision-button-container">
                    { currentDecision ?
                      (decisions[currentDecision].choices.map((choice) => {
                        let choiceNumber = decisions[currentDecision].choices.indexOf(choice);
                        let lastChoiceNumber = decisions[currentDecision].choices.length - 1;
                        return (
                            <div
                              key={choiceNumber}
                              className="btn btn-primary decision-button"
                              style={decisions[currentDecision].choices.indexOf(choice) === 2 ?
                                {marginRight: "0px"} : {marginRight: "4px"}
                              }
                              onClick={choiceNumber === lastChoiceNumber ?
                                ((e) => {
                                  this.transition = {transition: "0s"};
                                  dispatch(goToNextEvent());
                                  this.handleOnHide(false);
                                  this.setState({isDocked: true});
                                }) :
                                ((e) => {
                                  this.handleOnHide(true);
                                  this.transition = {transition: `${hideTransitionTiming}ms`};
                                  dispatch(addUserDecision({
                                    choice: choiceNumber,
                                    decision: currentDecision,
                                  }));
                                })
                              }>
                              {choice.buttonText}
                            </div>
                          );
                        })
                      ) :
                      <div
                        className="btn btn-primary decision-button"
                        style={{marginRight: "4px"}}
                        onClick={(e) => {
                          this.handleOnHide(false);
                          dispatch(goToNextEvent());
                        }}>
                        Ok, Continue
                      </div>
                    }
                  </div> : null }
              </div>
            </div>
          </div>
          <ReactTooltip class="custom-tool-tip" id="collapse" place="top" effect="solid"/>
          <ReactTooltip class="custom-tool-tip" id="minimize-maximize" place="top" effect="solid"/>
        </div>

      </Draggable>

    );
  }
}

let mapStateToProps = (state, ownProps) => {
  const { entities: { storms, events, decisions, currentDecision, decisionTiming },
    currentEvent, currentStorm, autoAdvance, dialogs, announcementsBeingShown, simulationOver } = state;
  const onFirstEvent = events[0].order === currentEvent;
  const decisionIds = storms[currentStorm].decisions;
  const isExperimental = storms[currentStorm].experimental;
  return {
    currentEvent,
    events,
    decisions,
    currentDecision,
    autoAdvance,
    dialogs,
    onFirstEvent,
    decisionTiming,
    announcementsBeingShown,
    simulationOver,
    decisionIds,
    isExperimental
  };
};

export default connect(mapStateToProps)(Decision);
