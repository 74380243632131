import { SET_ANNOUNCEMENT_RESULTS } from "../../actions/announcement.types";
import { SET_DECISION_TIMING_RESULTS } from "../../actions/decisionTiming.types";
import { SET_END_RESULTS } from "../../actions/endResults.types";

import { merge } from "lodash";

export let reducer = (state:any = {}, action) => {
  switch (action.type) {

    case SET_ANNOUNCEMENT_RESULTS: {
      let newState = merge({}, state);
      newState.announcements = action.announcementResults;
      return newState;
    }

    case SET_DECISION_TIMING_RESULTS: {
      let newState = merge({}, state);
      newState.decisionTiming = action.decisionTimingResults;
      return newState;
    }

    case SET_END_RESULTS: {
      let newState = merge({}, state);
      newState.windResults = action.generatedWindResults;
      newState.surgeResults = action.generatedSurgeResults;
      newState.decisionAnnouncement = action.generatedDecisionAnnouncementResults;
      newState.decisionStorm = action.generatedDecisionStormResults;
      newState.totalCost = action.totalCost;
      return newState;
    }

    default:
      return state;
  }
};

export default reducer;
