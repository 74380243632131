import { find } from "lodash";
import * as Handlebars from "handlebars";
import { getDecisionRequired, getTimeSinceEvent } from "../helpers/actionHelpers";

export let generateDecisionFeedbackFromState = (state, test = null) => {

  let { entities: {storms}, currentStorm} = state;
  let decisionTiming = Object.keys(state.entities.decisionTiming).map((k) => { return state.entities.decisionTiming[k]; });
  let events = Object.keys(state.entities.events).map((k) => { return state.entities.events[k]; });
  let decisionTimingResults = state.entities.results.decisionTiming;

  events = events.map((event) => {
    return Object.assign({}, event);
  });

  decisionTiming = decisionTiming.map((item) => {
    return Object.assign({}, item);
  });

  events.forEach((event, eventIndex) => {
    return Object.assign(event, {
      decisionTimingAnnouncements: decisionTiming.filter(function(decisionTimingItem) {

        if (!decisionTimingItem.event) {

          let violated = false;
          let satisfied = false;

          let addResultToViolated = () => {
            decisionTimingItem = Object.assign(decisionTimingItem, {event: event.id});
            if (decisionTimingItem.resultsFeedbackTextIfViolated) {
              decisionTimingResults.violated = Object.assign(decisionTimingResults.violated, decisionTimingResults.violated.push(decisionTimingItem.guid));
            }
          };

          let addResultToSatisfied = () => {
            decisionTimingItem = Object.assign(decisionTimingItem, {event: event.id});
            if (decisionTimingItem.resultsFeedbackTextIfSatisfied) {
              decisionTimingResults.satisfied = Object.assign(decisionTimingResults.satisfied, decisionTimingResults.satisfied.push(decisionTimingItem.guid));
            }
          };

          let earlierDecisionId = decisionTimingItem.earlierDecisionId ? decisionTimingItem.earlierDecisionId : null;
          let laterDecisionId = decisionTimingItem.laterDecisionId ? decisionTimingItem.laterDecisionId : null;

          // the two required decisions (if they were made)
          let earlierDecision = earlierDecisionId ? getDecisionRequired(earlierDecisionId, state) : false;
          let laterDecision = laterDecisionId ? getDecisionRequired(laterDecisionId, state) : false;

          if (earlierDecision === false || laterDecision === false) {
            return false;
          }

          // Remove result if it was not added before and triggered, but now it has been added.
          if (decisionTimingItem.laterDecisionNotMade) {
            if (decisionTimingItem.laterDecisionNotMade === laterDecision.decision) {
              let violatedGuidToRemove = decisionTimingResults.violated.indexOf(decisionTimingItem.guid);
              decisionTimingResults = Object.assign(decisionTimingResults, {violated: violated.splice(violatedGuidToRemove, 1)});
            }
          }

          // If the earlier decision was made, and later decision was not made (but wasn't supposed to be)
          if (earlierDecision && laterDecision === "not made") {
            decisionTimingItem = Object.assign(decisionTimingItem, {laterDecisionNotMade: decisionTimingItem.laterDecisionId});
            decisionTimingItem.resultsFeedbackTextIfViolated ? addResultToViolated() : null;
            return true;
          }

          // If the 2 required decisions were made:
          if (earlierDecision && laterDecision && laterDecision !== "not made") {

            let minTime = decisionTimingItem.minimumLagHours;
            violated = !minTime && minTime !== 0;

            if (minTime || minTime === 0) {

              // Get time between earlierDecision event and laterDecision event
              let eventIds = events.map((e) => { return e.order });
              let earlierIdIndex = eventIds.indexOf(find(events, e => e.order === earlierDecision.event).order)
              let laterIdIndex = eventIds.indexOf(find(events, e => e.order === laterDecision.event).order)
              let timeBetweenDecisions = getTimeSinceEvent(events[earlierIdIndex].order, events[laterIdIndex].order, storms[currentStorm].events)
              
              // Add data to handlebar variables
              let data = {decision_lag: timeBetweenDecisions};
              decisionTimingItem.announcementText ? decisionTimingItem.announcementText = Handlebars.compile(decisionTimingItem.announcementText)(data) : null;
              decisionTimingItem.resultsFeedbackTextIfViolated ? decisionTimingItem.resultsFeedbackTextIfViolated = Handlebars.compile(decisionTimingItem.resultsFeedbackTextIfViolated)(data) : null;
              decisionTimingItem.resultsFeedbackTextIfSatisfied ? decisionTimingItem.resultsFeedbackTextIfSatisfied = Handlebars.compile(decisionTimingItem.resultsFeedbackTextIfSatisfied)(data) : null;

              // If time between decisions was not enough time, it's violate. If it is enough time, it's satisfied.
              let onRequiredEvent = event.order === laterDecision.event;
              violated = timeBetweenDecisions < minTime && onRequiredEvent;
              if (timeBetweenDecisions >= minTime && onRequiredEvent) {
                satisfied = true;
              }

            }

          }

          if (violated) {
            addResultToViolated();
            decisionTimingItem.event = event.order;
            return true;
          }

          // If this decisionTimingItem has satisfied text and has not been used yet, push it.
          if (satisfied && decisionTimingItem.resultsFeedbackTextIfSatisfied) {
            addResultToSatisfied();
            decisionTimingItem.event = event.order;
            return true;
          }

        }

      }).map((decisionTimingItem) => decisionTimingItem.guid)
    });
  });

  let newDecisionTiming = {};
  decisionTiming.forEach(d => newDecisionTiming[d.guid] = d);

  return { events, decisionTimingResults, newDecisionTiming };
};
