import { merge, pick } from "lodash";
import { ViewMode, Threshold } from "./ui.types";
import * as UI from "../actions/ui.types";

export let reducer = (state: {viewMode: ViewMode, threshold: Threshold, currentEventHeight: ""} = {"viewMode": "ON_HUD", "threshold": "39", currentEventHeight: ""}, action) => {
  switch (action.type) {
    case UI.TOGGLE_VIEW_MODE: {
      let nextState = merge({}, state);
      nextState.viewMode = nextState.viewMode === "ON_HUD" ? "ON_MAP" : "ON_HUD";
      return nextState;
    }
    case UI.CHANGE_THRESHOLD: {
      let nextState = merge({}, state);
      nextState.threshold = action.threshold;
      return nextState;
    }
    case UI.SET_CURRENT_EVENT_HEIGHT: {
      let nextState = merge({}, state);
      nextState.currentEventHeight = action.currentEventHeight;
      return nextState;
    }
    default:
      return state;
  }
};

export default reducer;
