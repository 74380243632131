import * as React from "react";
import Navigation from "./component/navigation";
import * as ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import Dialogs from "./Dialogs";

export class Base extends React.Component<any, any> {
  render() {
    const { isExperimental, sessionId } = this.props;
    return (
      <div className="page">
        <Dialogs/>
        <Navigation/>
        <div className="content">
          { this.props.children }
        </div>
        <div className="footer"/>
        <ReactTooltip place="bottom" effect="solid" multiline={true} class="custom-tool-tip" delayHide={0} delayShow={100} />
        {isExperimental && sessionId ? <div className="session-id">{`Your session ID is: ${sessionId}`}</div> : null}
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  const { experimentalData: {sessionId}, entities: {storms}, currentStorm} = state;
  const isExperimental = storms[currentStorm].experimental;
  return { isExperimental, sessionId };
};

export default connect(mapStateToProps)(Base);
