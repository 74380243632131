import { SET_ANNOUNCEMENTS_BEING_SHOWN } from "./announcementsBeingShown.types";
import { merge } from "lodash";

export let setAnnouncementsBeingShown = ({announcementsBeingShown}) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, {
      type: SET_ANNOUNCEMENTS_BEING_SHOWN,
      announcementsBeingShown
    }));
  };
};
