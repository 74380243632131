import {
  SET_END_RESULTS,
  UPDATE_WIND_RESULTS,
  UPDATE_SURGE_RESULTS,
  UPDATE_DECISION_ANNOUNCEMENT_RESULTS,
  UPDATE_DECISION_STORM_RESULTS
 } from "./endResults.types";

import { merge } from "lodash";
import { generateWindResultsFromState } from "./windResults/";
import { generateSurgeResultsFromState } from "./surgeResults/";
import { generateDecisionAnnouncementResultsFromState } from "./decisionAnnouncements/";
import { generateDecisionStormResultsFromState } from "./decisionStorm/";
import { calculateTotalCost } from "./decisionCosts";

export let getEndResults = () => {
  return async (dispatch, getState) => {
    let state = getState();
    let { generatedWindResults, windResults } = generateWindResultsFromState(state);
    let { generatedSurgeResults, surgeResults } = generateSurgeResultsFromState(state);
    let { generatedDecisionAnnouncementResults, newDecisionAnnouncements } = generateDecisionAnnouncementResultsFromState(state);
    let { generatedDecisionStormResults, newDecisionStorm } = generateDecisionStormResultsFromState(state);
    let totalCost = calculateTotalCost(state);

    await dispatch(merge({}, {
      type: SET_END_RESULTS,
      generatedWindResults,
      generatedSurgeResults,
      generatedDecisionAnnouncementResults,
      generatedDecisionStormResults,
      totalCost,
    }));

    await dispatch(merge({}, {
      type: UPDATE_WIND_RESULTS,
      windResults
    }));

    await dispatch(merge({}, {
      type: UPDATE_SURGE_RESULTS,
      surgeResults
    }));

    await dispatch(merge({}, {
      type: UPDATE_DECISION_ANNOUNCEMENT_RESULTS,
      newDecisionAnnouncements
    }));

    await dispatch(merge({}, {
      type: UPDATE_DECISION_STORM_RESULTS,
      newDecisionStorm
    }));
  };
};
