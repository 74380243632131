import * as React from "react";
import { closeDialog } from "../actions/dialog";
import Dialog from "./BetterDialog";
import { connect } from "react-redux";
import { getHoursAgo } from "../misc/formatter";
import * as moment from "moment";
import narrative from "./narrative";

export let name = "event-details";

export class EventDetails extends React.Component<any, any> {

  state = {
    hasChangedDirection: false,
  };

  constructor(params) {
    super(params);
  }

  render() {

    let { 
      closeDialog, 
      event, 
      events, 
      cityName, 
      numberOfEvents, 
      selectedEventIndex, 
      estimatedHoursToLandfall,
      expectedLandfallLongitude,
      expectedLandfallLatitude,
     } = this.props;

    let paragraph = narrative(event, events, cityName);
    let hasLandfall = estimatedHoursToLandfall !== 9999 || estimatedHoursToLandfall !== -9999;
    let shouldDisplayTidesAndSurge = hasLandfall && estimatedHoursToLandfall <= 48 && event.surge;
    let tideData = shouldDisplayTidesAndSurge ? event.tideData : null;

    return (
      <div>
        <div className="dialog-header">
          <h2>Event Details 
            <span className="subtitle">({getHoursAgo(selectedEventIndex, numberOfEvents)})</span>
          </h2> 
         
          <span className="grab-handle-icon" />
          <span
            className="close-icon"
            onClick={(e) => closeDialog({ name })}
          />
        </div>
        <div className="dialog-body"><pre className="actionText">

          {paragraph.stormDescription}

          {paragraph.cityWindSpeeds ? <div><br/>{paragraph.cityWindSpeeds}</div> : null}

          {paragraph.forecast ? <div><br/>{paragraph.forecast}</div> : null}

          {paragraph.stormLocation ? <div><br/>{paragraph.stormLocation}<br/></div> : null}

          {paragraph.probability39 ? <div><br/>{paragraph.probability39}</div> : null}

          {paragraph.probability74 ? <div><br/>{paragraph.probability74}</div> : null}

          {paragraph.landfallForecast ? <div><br/>{paragraph.landfallForecast}</div> : null}

          {paragraph.expectedTides && shouldDisplayTidesAndSurge ? <div><br/>{paragraph.expectedTides}</div> : null}

          </pre>
          <div>
              { tideData ?
                <div>
                  <h2>Tide Details</h2>
                  <table>
                    <tbody>
                      <tr className="tide-table-top-row">
                        <td>Date</td>
                        <td>Height (ft)</td>
                      </tr>
                      { tideData.map((tide, index) => {
                        let getTideHour = () => {
                          let array = tide.hour.toString().split("");
                          if (array.length === 1) {
                            return "0" + tide.hour + "00";
                          }
                          else {
                            return tide.hour + "00";
                          }
                        };
                        return (
                          <tr key={index}>
                            <td>{moment(`${tide.month}-${tide.day}`, "M-D").format("D-MMM") + " " + getTideHour()}</td>
                            <td>{(tide.tide).toFixed(1) === "-0.0" ? "0.0" : (tide.tide).toFixed(1)}</td>
                          </tr>
                        );
                      })
                      }
                    </tbody>
                  </table>
                </div>
                : null
              }
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let { entities: {events, storms}, selectedEvent, currentEvent, currentStorm} = state;
  let event = events[selectedEvent];
  let cityName = storms[currentStorm].cityNameShort;
  // An array of all the event ID's
  let eventsArray = Object.keys(events).map((k) => events[k]);
  // A list of all the events up until now/currentEvent
  let eventsArrayUpToCurrentEvent = eventsArray.slice(0, (eventsArray.indexOf(events[currentEvent]) + 1));
  let numberOfEvents = eventsArrayUpToCurrentEvent.length;
  let selectedEventIndex = eventsArrayUpToCurrentEvent.indexOf(events[selectedEvent]);
 
  let estimatedHoursToLandfall = event.estimatedHoursToLandfall;
  let estimatedHoursToLandfallHurrevac = event.estimatedHoursToLandfallHurrevac;

  let expectedLandfallLongitude = storms[currentStorm].expectedLandfallLongitude;
  let expectedLandfallLatitude = storms[currentStorm].expectedLandfallLatitude;

  return {
    event,
    events,
    cityName,
    numberOfEvents,
    selectedEventIndex,
    estimatedHoursToLandfall,
    estimatedHoursToLandfallHurrevac,
    expectedLandfallLongitude,
    expectedLandfallLatitude,
    direction: state.dialogs[name] ? state.dialogs[name].direction : "from-bottom",
  };
};

let mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeDialog: (options) => dispatch(closeDialog(options)),
  };
};

export let ReduxEventDetails = connect(mapStateToProps, mapDispatchToProps)(EventDetails);

export let ReduxEventDetailsDialog = (props) =>
  <Dialog
    name={name}
    dialogClassName="dialog dialog-view-details"
    styleOpened={({ dialogElement, dialogProps }) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `${document.body.offsetHeight / 2 - dialogElement.offsetHeight / 2 + 6}px`,
      };
    }}
    styleClosed={({ dialogElement, dialogProps }) => {
      if (dialogProps.direction === "from-left") {
        return {
          left: `${0 - dialogElement.offsetWidth - 6}px`,
          top: `${document.body.offsetHeight / 2 - dialogElement.offsetHeight / 2 + 6}px`,
        };
      }
      else {
        return {
          left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
          top: `${document.body.offsetHeight + 10}px`,
          transitionTimingFunction: `ease`,
        };
      }
    }}
    transitionDuration={600}
    hasOverlay={true}
  >
    <ReduxEventDetails/>
  </Dialog>;

export default ReduxEventDetailsDialog;
