import * as Handlebars from "handlebars";
import { isEmpty } from "lodash";
import { roundToFive } from "../../misc/formatter";
import { evaluateThresholdCondition, formatDate, findIfSuperceded } from "../helpers/actionHelpers";
import { knotsToMph } from "../../misc/formatter";

export let generateAnnouncementsFromState = (state, test = null) => {
  let announcements = Object.keys(state.entities.announcements).map((k) => { return state.entities.announcements[k] });
  let events = Object.keys(state.entities.events).map((k) => { return state.entities.events[k]; });
  let announcementResults = [];
  let announcementsTriggeredSoFar = [];
  
  events = events.map((event) => {
    return Object.assign({}, event);
  });

  announcements = announcements.map((announcement) => {
    return Object.assign({}, announcement);
  });

  events.forEach((event, eventIndex) => {
    let announcementsTriggeredThisEvent = [];
    let filteredAnnouncements = [];

    // Filter announcements by threshold and conditions that apply
    announcements.forEach(announcement => {
      const announcementPreviouslyTriggered = announcementsTriggeredSoFar.indexOf(announcement.id) !== -1;

      if (!announcementPreviouslyTriggered) {
        // Has no superceding announcement in results, or during this announcement.
        let isNotSuperceded = announcement.supercededBy ? findIfSuperceded(announcement.supercededBy, announcementResults) : true;
        let isNotSupercededThisEvent = announcement.supercededBy ? findIfSuperceded(announcement.supercededBy, announcementsTriggeredThisEvent) : true;

        // If announcement has not been assigned to an event yet, continue. Otherwise, skip announcement.
        if (!announcement.event && isNotSuperceded && isNotSupercededThisEvent) {

          let requirements = {
            threshold: true,
            maxLeadTime: true,
          };

          // Last 2 digits of "conditionTriggers" - value to be compared to event probability values
          let { conditionTriggers, maxLeadTime, minLeadTime } = announcement;

          requirements.threshold = conditionTriggers ? evaluateThresholdCondition(conditionTriggers, event, null, true) : true;
          
          // check maxLead
          if (maxLeadTime) {
            requirements.maxLeadTime =  announcement.maxLeadTime >= event.estimatedHoursToLandfallHurrevac;
          }

          let announcementAppliesToEvent = false;

          // the announcement triggers on the event if the following are true
          if (requirements.maxLeadTime && requirements.threshold) {
            announcementAppliesToEvent = true;
          }
          
          // If estimatedHoursToLandfallHurrevac is less than minLeadTime, the announcement does not happen
          // because it's too late
          if (minLeadTime && (minLeadTime >= event.estimatedHoursToLandfallHurrevac)) {
            announcementAppliesToEvent = false;
          }
          
          // Put the announcement ID on the action, so we can check for it later and make sure it doesn't repeat
          if (announcementAppliesToEvent) {
            
            announcement = Object.assign(announcement, {event: event.order});
            announcementsTriggeredThisEvent.push(announcement.id);

            let data = {
              // Forecasted city wind speed (used in popup)
              curr_winds: roundToFive(knotsToMph(event.cityWindSpeedForecasted)),
              // Actual highest city wind speed for this storm (used in results)
              maxwindsexperienced: state.entities.storms[state.currentStorm].maxCityWindSpeed,
              announcement_time: formatDate(event.date),
            };

            // Add variables to the text
            announcement.popupText ? announcement.popupText = Handlebars.compile(announcement.popupText)(data) : null;
            announcement.recordOfEventsText ? announcement.recordOfEventsText = Handlebars.compile(announcement.recordOfEventsText)(data) : null;
            announcement.resultsTextFull ? announcement.resultsTextFull = Handlebars.compile(announcement.resultsTextFull)(data) : null;
            announcement.resultsTextPartial ? announcement.resultsTextPartial = Handlebars.compile(announcement.resultsTextPartial)(data) : null;

            // Push the announcement guid to results.announcements, so we can show it at the end.
            if (announcement.resultsTextFull || announcement.resultsTextPartial) {   
              announcementResults.push(announcement.id);
            }
            
          }
          
          if (announcementAppliesToEvent) {
            announcementsTriggeredSoFar.push(announcement.id)
          }
          
          if (announcementAppliesToEvent) {
            filteredAnnouncements.push(announcement.id);
          }
        }

      }

      else {
        return false;
      }

    });

    if (!isEmpty(filteredAnnouncements)) {

      // Remove announcement objects that were superceded
      filteredAnnouncements = filteredAnnouncements.filter(id => {
        let announcement = state.entities.announcements[id];
        if (announcement.supercededBy) {          
          return findIfSuperceded(announcement.supercededBy, filteredAnnouncements);
        }
        else {
          return true;
        }
      });

    }
    
    return Object.assign(event, {announcements: filteredAnnouncements})

  });

  // Get list of announcement ID's only
  const announcementIds = announcements.map(ann => ann.id);

  // Remove superceded announcement results from results
  announcementResults = announcementResults.filter(id => {
    const announcement = announcements[announcementIds.indexOf(id)];
    if (announcement.supercededBy) {
      // Only return results that were not superceded
      return findIfSuperceded(announcement.supercededBy, announcementResults);
    }
    else {
      return true;
    }

  });

  return { events, announcementResults, announcements } ;
};
