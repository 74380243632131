import { SET_DECISION_TIMING_ANNOUNCEMENTS, SET_DECISION_TIMING_RESULTS, UPDATE_DECISION_TIMING } from "./decisionTiming.types";
import { merge } from "lodash";
import { generateDecisionFeedbackFromState } from "./decisionTiming/";

export let generateDecisionTiming = () => {
  return async (dispatch, getState) => {
    let state = getState();
    let  { events, decisionTimingResults, newDecisionTiming } = generateDecisionFeedbackFromState(state);
    // Add anouncements
    await dispatch(merge({}, {
      type: SET_DECISION_TIMING_ANNOUNCEMENTS,
      events
    }));
    // Add results
    await dispatch(merge({}, {
      type: SET_DECISION_TIMING_RESULTS,
      decisionTimingResults
    }));
    // Update decisionTiming data
    await dispatch(merge({}, {
      type: UPDATE_DECISION_TIMING,
      newDecisionTiming
    }));
  };
};
