import * as React from "react";
import { closeDialog } from "../actions/dialog";
import Dialog from "./BetterDialog";
import { connect } from "react-redux";

export let name = "probabilities-help";


export class ProbabilitiesHelp extends React.Component<any, any> {

  constructor(params) {
    super(params);
  }

  render() {
    let { dispatch, cityName, threshold } = this.props;

    return (
      <div>
        <div className="dialog-header">
          <h2>Understanding the Probabilities Map</h2>
          <span className="close-icon" onClick={(e) => dispatch(closeDialog({ name }))}/>
        </div>
        <div className="dialog-body"><p className="probabilites-help">Ths map shows the probability of {cityName} experiencing winds exceeding the
            selected wind speed “threshold” at any time <strong>over the next 120 hours
              (5 days).</strong> Adjustable threshold is currently set to {threshold} mph in this example.
          </p>

          <img
            className="probabilites-image"
            src={`help_screens/probabilities-map.png`}
            style={{width: "661px", marginBottom: "15px"}}/>

          <h2>Cone of Uncertainty</h2>
          <p>
            When the "Cone" button is selected, the map will display the "cone of uncertainty” view.
            Although it is tempting to rely on the forecast track and the cone of uncertainty when making
            decisions about preparing for a hurricane, there is a lot of uncertainty in the forecast track,
            and the intensity and size of the storm will also affect whether {cityName} will experience
            hurricane force winds. The contour plots (above) showing the probability of winds exceeding
            a given speed combine the effects of the uncertainty in the storm track, intensity and size.
            Training yourself to use this information should improve your decision making under uncertainty.
          </p>

          <img
            className="probabilites-image"
            src={`help_screens/probabilities-cone.png`}
            style={{width: "661px"}}/>

        </div>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let { currentStorm, entities: {storms}, ui: {threshold} } = state;
  let cityName = storms[currentStorm].cityNameShort;
  return {
    cityName,
    threshold
  };
};

export let ReduxProbabilitiesHelp = connect(mapStateToProps)(ProbabilitiesHelp);

let ReduxProbabilitiesHelpDialog = (props) =>
  <Dialog
    name={name}
    dialogClassName="dialog dialog-help-details"
    styleOpened={({ dialogElement, dialogProps }) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `${document.body.offsetHeight / 2 - dialogElement.offsetHeight / 2 + 6}px`,
      };
    }}
    styleClosed={({ dialogElement, dialogProps }) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `${document.body.offsetHeight + 10}px`,
        transitionTimingFunction: `ease`,
      };
    }}
    transitionDuration={600}
    hasOverlay={true}
  >
    <ReduxProbabilitiesHelp/>
  </Dialog>;

export default ReduxProbabilitiesHelpDialog;
