import * as Handlebars from "handlebars";
import { getTimeSinceEvent, findIfSuperceded, getDecisionRequired } from "../helpers/actionHelpers";

export let generateDecisionAnnouncementResultsFromState = (state, test = null) => {

  let { entities: {storms}, currentStorm } = state;

  let decisionAnnouncements = Object.assign({}, state.entities.decisionAnnouncement);
  let events = Object.keys(state.entities.events).map((k) => { return state.entities.events[k]; });

  let results = [];

  Object.keys(decisionAnnouncements).forEach((id, index) => {

    let result = decisionAnnouncements[id];
    let hasAlreadyBeenAdded = results.indexOf(result.id) !== -1;
    let isNotSuperceded = result.supercededBy ? findIfSuperceded(result.supercededBy, results) : true;

    if (!hasAlreadyBeenAdded && isNotSuperceded) {

      // Get the event that the required decision was made on
      let decisionRequired = result.decisionId ? getDecisionRequired(result.decisionId, state) : false;
      let eventWithDecision = decisionRequired ? state.entities.events[decisionRequired.event] : false;
      let hasLag = (result.lagHours || result.lagHours === 0);
      let eventWithAnnouncement = events.find((e) => {
        return e.announcements ? e.announcements.indexOf(result.announcementId) !== -1 : null;
      });

      if (hasLag && eventWithDecision && eventWithAnnouncement) {
        // Get time between decision event, and event with announcement
        let timeSinceEvent = getTimeSinceEvent(eventWithDecision.order, eventWithAnnouncement.order, storms[currentStorm].events);
        let resultApplies = false;

        result.direction === "L" ? resultApplies = timeSinceEvent <= result.lagHours : null;
        result.direction === "G" ? resultApplies = timeSinceEvent >= result.lagHours : null;

        // If time is negative, change to positive
        Math.sign(timeSinceEvent) === -1 ? timeSinceEvent = timeSinceEvent * -1 : null;

        result.reportText ? result.reportText = Handlebars.compile(result.reportText)({event_lag: timeSinceEvent}) : null;
        resultApplies ? results.push(result.id) : null;
      }
        !hasLag && eventWithDecision && eventWithAnnouncement ? results.push(result.id) : null;
      }

  });

  // Filter out results that have been superceded
  results = results.filter((resultId) => {
    const result = decisionAnnouncements[resultId];
    if (result.supercededBy) {
      return findIfSuperceded(result.supercededBy, results);
    }
    else {
      return true;
    }
  })

  let newDecisionAnnouncements = decisionAnnouncements;
  let generatedDecisionAnnouncementResults = results;

  return { generatedDecisionAnnouncementResults, newDecisionAnnouncements };
};
