
export let reducer = (state = {
  southLatitude: 10,
  westLongitude: 100,
  northLatitude: 35,
  eastLongitude: 60
}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;
