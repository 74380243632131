import { merge } from "lodash";
import { SET_EVENT_ACTIONS } from "../../actions/actions.types";
import { SET_EVENT_ANNOUNCEMENTS } from "../../actions/announcement.types";
import { SET_DECISION_TIMING_ANNOUNCEMENTS } from "../../actions/decisionTiming.types";

export let reducer = (state = {}, action) => {

  switch (action.type) {
    case SET_EVENT_ACTIONS: {
      let newState = merge({}, state);
      action.events.forEach(event => {
        if (event.actions.length > 0) {
          newState[event.order].actions = event.actions;
        }
      });
      return newState;
    }
    case SET_EVENT_ANNOUNCEMENTS: {
      let newState = merge({}, state);
      action.events.forEach(event => {
        if (event.announcements.length > 0) {
          newState[event.order].announcements = event.announcements;
        }
      });
      return newState;
    }
    case SET_DECISION_TIMING_ANNOUNCEMENTS: {
      let newState = merge({}, state);
      action.events.forEach(event => {
        if (event.decisionTimingAnnouncements.length > 0) {
          newState[event.order].decisionTimingAnnouncements = event.decisionTimingAnnouncements;
        }
      });
      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
