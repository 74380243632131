import * as React from "react";
import { closeDialog } from "../actions/dialog";
import { connect } from "react-redux";
import * as Redux from "redux";
import Dialog, { DialogProps } from "./BetterDialog";

export interface AnnouncementProps {
  guid: string;
  name?: string;
  content: string;
  title?: string;
}

interface AnnouncementReduxProps extends AnnouncementProps {
  dispatch: Redux.Dispatch<any>;
}

export class Announcement extends React.Component<AnnouncementProps, any> {

  public static defaultProps: Partial<AnnouncementProps> = {
    title: "Announcement",
  };

  constructor(params) {
    super(params);
  }

  render() {
    let { content, title, dispatch, name } = this.props as AnnouncementReduxProps;

    return (
      <div>
        <div className="dialog-header">
          <h2>{ title }</h2>
        </div>
        <div className="dialog-body">
          <p>
            {content}
          </p>
          <div className="decision-buttons-container">
            <div
              className="btn btn-primary ok-button"
              onClick={(e) => dispatch(closeDialog({name}))}>
              OK
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let { entities: { announcements, decisionTiming } } = state;
  let { name } = ownProps;
  return {
    announcements,
    decisionTiming,
    direction: state.dialogs[name] ? state.dialogs[name].direction : "from-bottom",
  };
};

let ConnectedAnnouncement = connect(mapStateToProps)(Announcement);

export let generateAnnouncementDialog = (props: AnnouncementProps & Pick<DialogProps, "onClosed">) => {
  let name = props.name ? props.name : `announcement-${props.guid}`;
  return (
    <Dialog
      name={name}
      dialogClassName={`dialog dialog-view-announcement`}
      transitionDuration={700}
      onClosed={props.onClosed}
      key={name}
    >
      <ConnectedAnnouncement name={name} {...props} />
    </Dialog>
  );
};

export default generateAnnouncementDialog;
