import { DIALOG_OPEN, DIALOG_CLOSE } from "./dialog.types";
import { merge } from "lodash";

export let openDialog = (options: {
  name: string,
  animate?: boolean,
  props?: {},
}) => {
  options = { ...{ animate: true }, ...options };
  return async (dispatch, getState) => {
    let state = getState();
    await dispatch(merge({}, {
      type: DIALOG_OPEN,
    }, options));
  };
};

export let closeDialog = (options: {
  name: string,
  animate?: boolean,
  props?: {},
}) => {
  options = { ...{ animate: true }, ...options };
  return async (dispatch, getState) => {
    let state = getState();
    await dispatch(merge({}, {
      type: DIALOG_CLOSE,
    }, options));
  };
};
