import { ADD_USER_DECISION, CHANGE_CURRENT_DECISION } from "./decision.types";
import { merge } from "lodash";
import { generateActions } from "./action";
import { generateDecisionTiming } from "./decisionTiming";
import { getNextDecisionId } from "./currentDecision";
import { addDecisionToExperiment } from "./experimentalData";

export let addUserDecision = (options: {
  choice: number,
  decision: string,
  props?: {},
}) => {

  return async (dispatch, getState) => {
    let state = getState();
    let nextDecisionId = getNextDecisionId(state, options);

    await dispatch(merge({}, {
      event: state.currentEvent,
      type: ADD_USER_DECISION,
    }, options));

    await dispatch(merge({}, {
      type: CHANGE_CURRENT_DECISION,
      nextDecisionId: nextDecisionId,
    }, options));

    await dispatch(generateActions());
    await dispatch(generateDecisionTiming());

    // Add decision to experimental data if this is an experimental scenario
    if (state.entities.storms[state.currentStorm].experimental) {
      dispatch(addDecisionToExperiment({
        event: state.currentEvent, 
        decision: state.entities.decisions[options.decision],
        choiceMade: state.entities.decisions[options.decision].choices[options.choice].buttonText
      }));
    }
      
  };
};

