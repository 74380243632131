import * as React from "react";
import { connect } from "react-redux";
import { openDialog, closeDialog } from "../../actions/dialog";
import { startAutoAdvance, stopAutoAdvance } from "../../actions/autoAdvance";
import { goToNextEvent } from "../../actions/event";
import { setAnnouncementsBeingShown } from "../../actions/announcementsBeingShown";
import AboutDialog from "../../dialog/about";
import HelpDialog from "../../dialog/help/";
import ShareDialog from "../../dialog/share";
import ProbabilitiesHelpDialog from "../../dialog/probabilitiesHelp";
import RecordOfEventsHelpDialog from "../../dialog/recordOfEventsHelp";
import EventDetailsDialog from "../../dialog/eventDetails";
import EventActionsDialog from "../../dialog/eventActions";
import InitialStormUpdateDialog from "../../dialog/initialStormUpdate";
import SimulationOver from "../../dialog/simulationOver";
import UserFeedbackDialog from "../../dialog/userFeedback/";
import { AnnouncementProps, generateAnnouncementDialog } from "../../dialog/announcement";

export class Dialogs extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.generateAnnouncementDialogs = this.generateAnnouncementDialogs.bind(this);
  }

  announcementDialogs = [];
  shownAnnouncements = {};
  pausedAutoAdvance = false;

  componentDidUpdate(prevProps, prevState) {
    let {
      event, 
      autoAdvance, 
      openDialog, 
      stopAutoAdvance, 
      announcementProps, 
      currentEvent, 
      setAnnouncementsBeingShown 
    } = this.props;
    
    if (prevProps.currentEvent !== currentEvent && event.announcements.length > 0) {
      
      // new event, new announcements
      if (autoAdvance) {
        stopAutoAdvance();
        this.pausedAutoAdvance = true;
      } else {
        this.pausedAutoAdvance = false;
      }

      // open first dialog
      let firstAnnouncementName = announcementProps[0].name;
      this.shownAnnouncements = {};
      this.shownAnnouncements[firstAnnouncementName] = true;
      openDialog({ name: firstAnnouncementName });

      setAnnouncementsBeingShown({announcementsBeingShown: true});
    }

    if (prevProps.currentEvent === currentEvent && prevProps.announcementProps.length !== announcementProps.length) {
      // new announcements, same event
      // open first new dialog
      if (autoAdvance) {
        stopAutoAdvance();
        this.pausedAutoAdvance = true;
      } else {
        this.pausedAutoAdvance = false;
      }

      let announcementPropsToShow = announcementProps.filter(a => !this.shownAnnouncements[a.name]);
      if (announcementPropsToShow.length > 0) {
        let firstAnnouncementName = announcementPropsToShow[0].name;
        this.shownAnnouncements[firstAnnouncementName] = true;
        openDialog({ name: firstAnnouncementName });
        setAnnouncementsBeingShown({announcementsBeingShown: true});
      }
    }
  }

  generateAnnouncementDialogs(props = this.props) {
    let { announcementProps, setAnnouncementsBeingShown } = props;
    let pausedAutoAdvance = this.pausedAutoAdvance;
    this.announcementDialogs = announcementProps.map((announcementProp, index) => {
      let { guid, content, name } = announcementProp;
      let dialog = generateAnnouncementDialog({ name, guid, content, onClosed: () => {
        let nextAnnouncement = announcementProps.slice(index + 1).find(a => !this.shownAnnouncements[a.name]);
        if (nextAnnouncement) {
          // another announcement exists, open it
          let nextAnnouncementName = nextAnnouncement.name;
          this.shownAnnouncements[nextAnnouncementName] = true;
          props.openDialog({ name: nextAnnouncementName });
        } else {
          // no more announcements - resume sim
          setAnnouncementsBeingShown({announcementsBeingShown: false});
          if (pausedAutoAdvance) {
            props.startAutoAdvance();
          }
        }
      }});
      return dialog;
    });
  }

  render() {
    this.generateAnnouncementDialogs();

    return (
      <div className="dialogs">
        <AboutDialog />
        <HelpDialog />
        <ShareDialog />
        <ProbabilitiesHelpDialog />
        <RecordOfEventsHelpDialog />
        <EventDetailsDialog />
        <EventActionsDialog />
        <InitialStormUpdateDialog />
        <SimulationOver />
        {process.env.NODE_ENV === "production" ? null : <UserFeedbackDialog />}
        {this.announcementDialogs.length > 0 ? this.announcementDialogs : null}
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let { entities: { events, userDecisions, announcements, decisionTiming }, currentEvent, dialogs, autoAdvance } = state;
  let event = events[currentEvent];
  // Checks to see if the currentEvent is the first event
  let isFirstevent = currentEvent === events[0].order;

  let announcementProps: AnnouncementProps[] =
    event.announcements.map(id => announcements[id]).map(a => ({ guid: a.id, content: a.popupText, name: `announcement-${a.id}` })).concat(
    event.decisionTimingAnnouncements.map(guid => decisionTiming[guid]).filter(a => a.announcementText).map(a => ({ guid: a.guid, content: a.announcementText, name: `decisionTimingAnnouncement-${a.guid}`}))
  );

  return {
    event,
    currentEvent,
    userDecisions,
    dialogs,
    isFirstevent,
    autoAdvance,
    announcementProps,
  };
};

let mapDispatchToProps = (dispatch, ownProps) => {
  return {
    openDialog: (options) => dispatch(openDialog(options)),
    closeDialog: (options) => dispatch(closeDialog(options)),
    stopAutoAdvance: () => dispatch(stopAutoAdvance()),
    startAutoAdvance: () => dispatch(startAutoAdvance()),
    goToNextEvent: () => dispatch(goToNextEvent()),
    setAnnouncementsBeingShown: (options) => dispatch(setAnnouncementsBeingShown(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialogs);
