import * as React from "react";

export class HurricaneBasics extends React.Component<any, any> {
  render() {
    return (
      <div className="content">
        <h2>HURRICANE BASICS</h2>
        
        <p>The NHC also has many good resources online on <a href="http://www.nhc.noaa.gov/climo/" target="_blank">
        hurricane climatology</a> and its <a href="http://www.nhc.noaa.gov/aboutnhcprod.shtml" target="_blank">
        forecasts</a> and forecast <a href="http://www.nhc.noaa.gov/verification/" target="_blannk">
        errors</a> and resulting <a href="http://www.nhc.noaa.gov/outreach/presentations/NHC2017_L311_Uncertainty.pdf" target="_blank">
        uncertainty</a>.</p>

        <h2>What is a Hurricane?</h2>
        <p>Tropical Cyclones (TCs) form over the open ocean in the tropical latitudes and have a 
          characteristic circular wind structure. A TC's intensity is typically measured as the 
          one-minute sustained wind speed. TCs with maximum sustained wind speeds between 39 mph 
          (34 knots) and 73 mph (63 knots) are called tropical storms. When their wind speeds exceed 
          74 mph (64 knots), TCs are called <strong>hurricanes</strong> in the North 
          Atlantic or <strong>typhoons</strong> in the North Pacific. For more information, 
          please see the <a href="http://www.nhc.noaa.gov/" target="_blank"> National Hurricane Center</a>'s 
          <a href="http://www.nhc.noaa.gov/aboutsshws.php" target="_blank"> educational resources on the 
          damage that wind can cause</a>.</p>
        
        <h2>Surge</h2>

        <p>Although hurricanes are categorized according to wind speed, hurricane-induced flooding causes 
          far more deaths than wind. Storm surge is even more difficult to forecast than hurricane 
          track and winds. Surge is affected by the size, speed of forward motion, and track of the 
          storm, as well as the local topography and bathymetry (topography underwater). 
          Flooding is also affected by local conditions, including levees. For more information 
          about storm surge, please see the National Hurricane Center's 
          <a href="http://www.nhc.noaa.gov/surge/" target="_blank"> educational resources on surge</a>.</p>

        <h2>Hurricane and Storm Categories</h2>
        <table>
          <thead>
            <tr>
              <th>Max Sustained Winds</th>
              <th>Classification/Category</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{`< 39 mph (< 34 knots)`}</td>
              <td>Tropical Depression</td>
            </tr>
            <tr>
              <td>39-73 mph (34-63 knots)</td>
              <td>Tropical Storm</td>
            </tr>
            <tr>
              <td>74-95 mph (64-82 knots)</td>
              <td>Category 1 hurricane</td>
            </tr>
            <tr>
              <td>96-110 mph (83-95 knots) </td>
              <td>Category 2 hurricane</td>
            </tr>
            <tr>
              <td>111-129 mph (96-112 knots)</td>
              <td>Category 3 hurricane</td>
            </tr>
            <tr>
              <td>130-156 mph (113-136 knots)</td>
              <td>Category 4 hurricane</td>
            </tr>
            <tr>
              <td>Exceeding 157 mph (137 knots)</td>
              <td>Category 5 hurricane</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default HurricaneBasics;
