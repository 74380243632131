import { find } from "lodash";
import { formatDate as _formatDate } from "../../misc/formatter";

export let formatDate = (date) => {
  return _formatDate(date);
};

// Compares conditions (W = wind, S = surge, T = estimatedHoursToLandfallHurrevac)
let compareConditions = (condition, value, event, usingForecast) => {
  let windSpeed = false;
  if (usingForecast) {
    windSpeed = condition === "W" && event.cityWindSpeedForecasted >= value;
  }
  else {
    windSpeed = condition === "W" && event.cityWindSpeed >= value;
  }
  let surge = condition === "S" && event.surge >= value;
  let timeToLandfall = condition === "T" && event.estimatedHoursToLandfallHurrevac <= value;
  return (windSpeed || surge || timeToLandfall);
};

// Compares wind thresholds (PT = 39MPH, PD = 58MPH, PH = 74MPH)
let compareThresholds = (condition, value, event) => {
  let winds39 = condition.match(/PT/) && event.probabilityWinds39MPH >= value /*&& windspeed >= 34*/;
  let winds58 = condition.match(/PD/) && event.probabilityWinds58MPH >= value /*&& windspeed >= 50*/;
  let winds74 = condition.match(/PH/) && event.probabilityWinds74MPH >= value /*&& windspeed >= 64*/;
  return (winds39 || winds58 || winds74);
};

// Takes in a string condition ("W90", "PH34", "S3", etc.) And returns the event that reaches that condition.
// Returns "not made" when the decision was SUPPOSED to have not been made, and wasn't.
export let getEventWithCondition = (condition, events, usingForecast) => {
  let normalConditionType = !condition.match(/(PH|PD|PT)/) ? condition.match(/[A-Z]/)[0] : false;
  let conditionValue = Number(condition.match(/\d+/)[0]);
  return events.find(event => {
    return normalConditionType ? compareConditions(normalConditionType, conditionValue, event, usingForecast) : compareThresholds(condition, conditionValue, event);
  });
};

// Takes in a decision id and the state, and returns the userDecision where that decision was made on.
export let getDecisionRequired = (decisionId, state) => {
  // tilde stands for "not made"
  let not = decisionId.match(/~/) ? true : false;
  not ? decisionId = decisionId.replace("~", "") : null;
  // See if there's a number in an array at the end ([0], [1], etc.), if yes, that means there's a choice
  let requiredChoice = decisionId.match(/\[[\d]\]/) ? decisionId.match(/\[[\d]\]/)[0] : false;
  // If there is a choice, the id needs to be split away and set to a Number
  requiredChoice ? decisionId = decisionId.split("").slice(0, decisionId.length - 3).join("") : null;
  let decisionNeeded = find(state.entities.userDecisions, (ud) => {
    // If there is a required choice we look to see if decision AND choice was made
    if (requiredChoice && ud.decision === decisionId) {
      if (Number(requiredChoice.match(/\d/)[0]) === ud.choice) {
        return ud;
      }
    }
    // If there's no required choice, just return the user decision, IF it was made.
    else {
      if (ud.decision === decisionId) {
        return ud;
      }
    }
  });
  // If there was a "~not" decision that was made:
  if (not && decisionNeeded) {
    return false;
  }
  // If there was NO "~not" decision, but the decicion and/or choice wasn't made:
  if (!not && !decisionNeeded) {
    return false;
  }
  // If there was a "~not" decision, and it wasn't made:
  if (not && !decisionNeeded) {
    return "not made";
  }
  // If there was a decision and it was made:
  else {
    return decisionNeeded;
  }
};

// Takes in a condition (e.g. "S_1 AND S_2") and returns an array (["S_1", "S_2"])
// Also updates the condition by changing words to symbols, e.g. "dec_1 AND dec2" returns "dec_1 && dec2"
export let convertCondition = (condition) => {
  condition = condition.replace(/ AND /g, " && ");
  condition = condition.replace(/ OR /g, " || ");
  // Find decisions in the condition, they can contain characters, numbers, underscores, and brackets
  let charactersToFind = /[a-zA-Z_\[\]\d+]+/g;
  let listOfItems = condition.match(charactersToFind);
  return { condition, listOfItems };
};

// Takes a threshold condition, evaluates it, and returns true or false.
export let evaluateThresholdCondition = (initialCondition, currentEvent, events, usingForecast) => {
  let { condition, listOfItems } = convertCondition(initialCondition);
  // Go through each item and see if any of them match any events

  listOfItems.forEach(item => {

    let newItem = null;
    // "normal" condition type is "W" "S", etc,
    let windOrSurgeOrTimeToLandfall = !item.match(/(PH|PD|PT)/) ? item.match(/[A-Z]/)[0] : false;
    let conditionValue = Number(item.match(/\d+/)[0]);

    // OF CURRENT EVENT: If no events were provided, we will just find threshold on currentEvent
    if (!events) {
      // If it's a regular condition type ("W", "S", etc.)
      if (windOrSurgeOrTimeToLandfall) {
        newItem = compareConditions(windOrSurgeOrTimeToLandfall, conditionValue, currentEvent, usingForecast);
      }
      if (!windOrSurgeOrTimeToLandfall) {
        newItem = compareThresholds(condition, conditionValue, currentEvent);

      }
    }

    // OF ALL TIME: If events WERE provided, we will look through ALL events to find the threshold
    if (events) {
      events.find(event => {
        if (windOrSurgeOrTimeToLandfall) {
          let threshold =  compareConditions(windOrSurgeOrTimeToLandfall, conditionValue, event, usingForecast);
          threshold ? newItem = threshold : null;
        }
        if (!windOrSurgeOrTimeToLandfall) {
          let threshold =  compareConditions(windOrSurgeOrTimeToLandfall, conditionValue, event, usingForecast);
          threshold ? newItem = threshold : null;
        }
      });
    }
    newItem ? condition = condition.replace(item, true) : condition = condition.replace(item, false);
  });

  return eval(condition);
};


// Takes a first event, a second event, and array of events, and returns the time between the first and second events.
export let getTimeSinceEvent = (event1, event2, events) => {
  // Get a list of event guids
  let eventIds = events;
  let timeBetweenEvents = 0;
  // Event happens BEFORE: If event2 comes after event1 Slice the eventIds so it will only consist of event1 through event2
  if (event2 >= event1) {
    let eventList = eventIds.slice(event1, event2);
    // For each event, add 6 hours
    eventList.forEach((e) => {
      timeBetweenEvents += 6;
    });
    return timeBetweenEvents;
  }
  // Event happens AFTER: If event2 comes BEFORE event1, Slice the eventIds so it will only consist of event2 through event1
  else if (event2 < event1) {
    let eventList = eventIds.slice(event2, event1);
    // For each event, add 6 hours
    eventList.forEach((e) => {
      timeBetweenEvents -= 6;
    });
  }
  return timeBetweenEvents;
};

// Takes a string of superceding items (e.g. "action_1 OR action_2") and array to search through,
// Takes one item at a time (e.g. "action_1") and searches for it in array (true = found, false = not found)
// converts the condition to a real condition (e.g. true || false)
// evaluates the condition, and returns true or false. 
// True = item is not superceded (superceding item not found), item will trigger.
// False = item was superceded (superceding item was found), item will not be allowed to trigger.
export let findIfSuperceded = (supercedingItems, arrayToSearch) => {
  let { condition, listOfItems } = convertCondition(supercedingItems);
  listOfItems.forEach(item => {
    condition = condition.replace(item, arrayToSearch.indexOf(item) > -1 ? true : false);
  });
  return !eval(condition);
};
