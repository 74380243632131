import * as React from "react";
import { generateActions } from "./actions/action";
import { generateAnnouncements } from "./actions/announcement";
import { openDialog } from "./actions/dialog";

import { connect } from "react-redux";
import { Route, Switch} from "react-router-dom";

import PageLayout from "./page/base/";
import Home from "./page/home/";

export class App extends React.Component<any, any> {

  state = {
    shouldSendFirstEvent: true,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // Generate all the actions and announcements beforehand since no input affects them
    this.props.dispatch(generateActions());
    this.props.dispatch(generateAnnouncements());
    if (this.props.onFirstEvent) {
      this.props.dispatch(openDialog({name: "help", animate: false}));
    }
  }

  render() {
    return (
      <PageLayout>
        <Switch>
          <Route component={Home}/>
        </Switch>
      </PageLayout>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let { currentEvent, entities: {events} } = state;
  const onFirstEvent = events[0].order === currentEvent;
  return {
    onFirstEvent,
  };
};

export default connect(mapStateToProps)(App);
