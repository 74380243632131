import * as React from "react";
import { closeDialog } from "../actions/dialog";
import Dialog from "./BetterDialog";
import { connect } from "react-redux";

export let name = "record-of-events-help";

export class RecordOfEventsHelp extends React.Component<any, any> {

  constructor(params) {
    super(params);
  }

  render() {
    let { closeDialog } = this.props;

    return (
      <div>
        <div className="dialog-header">
          <h2>Understanding the Record of Events</h2>
          <span className="close-icon" onClick={(e) => closeDialog({ name })}/>
        </div>
        <div className="dialog-body">
          <p>The Record of Events allows you to view past storm updates, details, and decisions. Clicking on any event will reset the user interface to previous states and views. This will allow you to review the storm’s path and previously announced storm updates. Storm updates occur every 6 hours. </p>
          <img
            className="probabilites-image"
            style={{width: "661px"}}
            src={`help_screens/record-of-events.png`} />
          <p><i>*Expected Landfall. This is a worst-case scenario of a direct hit. It is calculated using 
            the speed of forward motion of the official forecast, but the track is straightened 
            and redirected to the location of interest. This is similar to FEMA’s HURREVAC decision 
            support tool (<a href="http://www.hurrevac.com/about.htm" target="_blank">
            http://www.hurrevac.com/about.htm</a>). Landfall timing estimates in the forecast 
            details are based on the time the official forecast track crosses the coast.</i></p>
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  return {

  };
};

let mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeDialog: (options) => dispatch(closeDialog(options)),
  };
};

export let ReduxRecordOfEventsHelp = connect(mapStateToProps, mapDispatchToProps)(RecordOfEventsHelp);

let ReduxRecordOfEventsHelpDialog = (props) =>
  <Dialog
    name={name}
    dialogClassName="dialog dialog-help-details"
    styleOpened={({ dialogElement, dialogProps }) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `${document.body.offsetHeight / 2 - dialogElement.offsetHeight / 2 + 6}px`,
      };
    }}
    styleClosed={({ dialogElement, dialogProps }) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `${document.body.offsetHeight + 10}px`,
        transitionTimingFunction: `ease`,
      };
    }}
    transitionDuration={600}
    hasOverlay={true}
  >
    <ReduxRecordOfEventsHelp/>
  </Dialog>;

export default ReduxRecordOfEventsHelpDialog;
