import * as Handlebars from "handlebars";
import { getDecisionRequired, getTimeSinceEvent, evaluateThresholdCondition } from "../helpers/actionHelpers";
import { formatMoney } from "../../misc/formatter";

export let calculateTotalCost = (state, test = null) => {

  let events = Object.keys(state.entities.events).map((k) => { return state.entities.events[k]; });
  let decisionCosts = Object.keys(state.entities.decisionCosts).map((k) => { return state.entities.decisionCosts[k]; });
  let userDecisions = state.entities.userDecisions;
  let fixedCost = 0;
  let variableCost = 0;

  decisionCosts.forEach((decisionCost) => {

    let decisionRequired = decisionCost.decisionId ? getDecisionRequired(decisionCost.decisionId, state) : false;
    // If the decision required was made
    if (decisionRequired) {
      // If it has a fixed cost:
      if (decisionCost.fixedCost) {
        fixedCost += decisionCost.fixedCost;
      }
      // If it is a variable cost:
      if (decisionCost.variableCost) {
        // Set variableCost back to 0 in case a lower condition amount cost was already added up,
        // and we found a new one with a higher condition
        variableCost = 0;
        let eventWithDecision = events.find((e) => { return decisionRequired.event === e.order });
        let lastEvent = events[events.length - 1];

        // Get time between the event the decision was made on, and the very last event
        let timeBetweenEvents = getTimeSinceEvent(eventWithDecision.order, lastEvent.order, Object.keys(events));
        // Divide by 24 to get number of days
        let daysSinceDecision = Math.ceil(timeBetweenEvents / 24);

        // Add to total cost
        variableCost += (decisionCost.variableCost * daysSinceDecision);

        let eventWithCondition = decisionCost.conditions ? evaluateThresholdCondition(decisionCost.conditions, null, events, false) : true;

        if (eventWithCondition) {
          // If any events reached any of the required conditions, add to total cost
          variableCost += (decisionCost.variableCost * decisionCost.days);
        }

      }
    }
  });

  let totalCost = fixedCost + variableCost;
  return totalCost;
};

export let addCostVariables = (state, test = null) => {
  let decisionCosts = Object.keys(state.entities.decisionCosts).map((k) => { return state.entities.decisionCosts[k]; });
  let decisions = Object.keys(state.entities.decisions).map((k) => { return state.entities.decisions[k]; });

  // search through each decision, find the first id that matches the decision id substring, and inject
  // the corresponding costs via handlebars
  decisions.map((decision) => {
    decisionCosts.find((decisionCost) => {
      // Get the required decision id #
      let isMatch = new RegExp(`^${decision.id}`).test(decisionCost.decisionId);
      if (isMatch) {
        // compile the cost into the decision handlebar variables
        let fixedCost = decisionCost.fixedCost ? decisionCost.fixedCost : null;
        let variableCost = decisionCost.variableCost ? decisionCost.variableCost : null;
        let costs = {fixed_cost: formatMoney(fixedCost), variable_cost: formatMoney(variableCost)};
        decision.dialogShortText ? decision.dialogShortText = Handlebars.compile(decision.dialogShortText)(costs) : null;
        decision.dialogExtendedText ? decision.dialogExtendedText = Handlebars.compile(decision.dialogExtendedText)(costs) : null;
      }
    });
  });

  return decisions;
};
