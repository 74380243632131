import * as React from "react";
import { closeDialog } from "../actions/dialog";
import Dialog from "./BetterDialog";
import { connect } from "react-redux";

export let name = "about";

export class About extends React.Component<any, any> {

  constructor(params) {
    super(params);
  }

  render() {
    let { closeDialog } = this.props;
    return (
      <div>
        <div className="dialog-header">
          <h2>About</h2>
          <span className="grab-handle-icon"></span>
          <span className="close-icon" onClick={() => closeDialog({ name })}></span>
        </div>
        <div className="dialog-body">
          <p>The U.S. Marine Corps Forces Reserve Hurricane Decision Simulator (HDS) provides users with practice making critical hurricane-preparation decisions and interpreting tropical-storm forecasts with many simulated storms representing a range of possible storm events and forecasts.</p>
          <h2>Credits</h2>
          <ul className="credits-list">
            <li><strong>Designed and Developed for the U.S. Marine Forces Reserve by the Naval Postgraduate School.</strong></li>
            <li><a href="http://faculty.nps.edu/eregnier/" target="_blank">Eva D. Regnier, Ph.D.</a></li>
            <li>Cameron A. MacKenzie, Ph.D.</li>
            <li>Andy Hernandez, Ph.D.</li>
            <li>Sean R. Christopherson</li>
          </ul>
          <ul className="credits-list">
            <li><strong>Online implementation designed and developed by the Naval Postgraduate School's <a href="http://faculty.nps.edu/dl/ced3/" target="_blank">Center for Educational Design, Development, and Distribution (CED3)</a></strong></li>
            <li>Dianna Beardslee, Instructional Designer</li>
            <li>Lisa Spence, Instructional Designer</li>
            <li>Mike Northcutt, UX/UI Designer</li>
            <li>Michael Nigh, Developer</li>
            <li>Leah Frye, Developer</li>
            <li>Noah Lloyd-Edelman, Developer</li>
            <li>Phillip McCullick, Developer</li>
            <li>Richard von Buelow, Developer</li>
          </ul>
          <div className="credits-logos">
            <a href="http://www.nps.edu" target="_blank"><img src="nps-logo.png" className="nps-logo" /></a>
            <a target="_blank"><img src="ced3-logo.png" className="ced3-logo" /></a>
            <a href="http://www.marforres.marines.mil/" target="_blank"><img src="marines-logo.png" className="marforres-logo" /></a>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  return {

  };
};

let mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeDialog: (options) => dispatch(closeDialog(options)),
  };
};

export let ReduxAbout = connect(mapStateToProps, mapDispatchToProps)(About);

export let ReduxAboutDialog = (props) =>
  <Dialog
    name={name}
    dialogClassName="dialog dialog-view-about"
    styleOpened={({ dialogElement, dialogProps }) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `${document.body.offsetHeight / 2 - dialogElement.offsetHeight / 2 + 6}px`,
      };
    }}
    styleClosed={({ dialogElement, dialogProps }) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `${document.body.offsetHeight + 10}px`,
        transitionTimingFunction: `ease`,
      };
    }}
    transitionDuration={600}
    hasOverlay={true}
  >
    <ReduxAbout/>
  </Dialog>;

export default ReduxAboutDialog;
