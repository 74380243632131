import { SET_EVENT_ANNOUNCEMENTS, SET_ANNOUNCEMENT_RESULTS, UPDATE_ANNOUNCEMENTS } from "./announcement.types";
import { merge } from "lodash";
import { generateAnnouncementsFromState } from "./announcement/";

export let generateAnnouncements = () => {
  return async (dispatch, getState) => {
    let state = getState();
    let { events, announcementResults, announcements } = generateAnnouncementsFromState(state);
    await dispatch(merge({}, {
      type: SET_EVENT_ANNOUNCEMENTS,
      events,
    }));
    await dispatch(merge({}, {
      type: SET_ANNOUNCEMENT_RESULTS,
      announcementResults,
    }));
    await dispatch(merge({}, {
      type: UPDATE_ANNOUNCEMENTS,
      announcements,
    }));
  };
};
