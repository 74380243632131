import { UPDATE_DECISION_STORM_RESULTS } from "../../actions/endResults.types";
import { merge } from "lodash";

export let reducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_DECISION_STORM_RESULTS: {
      let newState = merge({}, state);
      newState = action.newDecisionStorm;
      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
