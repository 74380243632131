import { SET_EVENT_ACTIONS, UPDATE_ACTIONS } from "./actions.types";
import { merge } from "lodash";
import { generateActionsFromState } from "./action/";

export let generateActions = () => {
  return async (dispatch, getState) => {
    let state = getState();
    let { events, actions } = generateActionsFromState(state);
    await dispatch(merge({}, {
      type: SET_EVENT_ACTIONS,
      events
    }));
    await dispatch(merge({}, {
      type: UPDATE_ACTIONS,
      actions
    }))
  };
};
