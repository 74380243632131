import { convertCondition } from "../helpers/actionHelpers";

// Takes current state, and returns the next decision id
export let getNextDecisionId = (state, decisionsMadeThisEvent, test = null) => {

  let decisions = state.entities.decisions;
  let userDecisions = decisionsMadeThisEvent ? state.entities.userDecisions.concat(decisionsMadeThisEvent) : state.entities.userDecisions;
  let userDecisionIds = userDecisions.map(ud => ud.decision);
  let currentDecisionId = state.entities.currentDecision;
  let decisionIds = Object.keys(decisions);
  let decisionsRemainingIds = decisionIds.slice(decisionIds.indexOf(currentDecisionId) + 1, decisionIds.length + 1);
  let decisionsRemaining = decisionsRemainingIds.map(id => decisions[id]);
  let onLastDecision = decisionIds[decisionIds.length - 1] === currentDecisionId;

  if (onLastDecision) { return false; }

  // Takes preReqCondition(s), and returns true or false based on whether those decisions have been made.
  let findNextDecision = (preReqDecisions) => {

    let { condition, listOfItems } = convertCondition(preReqDecisions);

    listOfItems.forEach((item) => {
      
      let choice = item.match(/\[[\d]\]/) ? item.match(/\[[\d]\]/)[0] : false;

      // If there is no choice, just find out if the decision was made
      if (!choice) {
        condition = condition.replace(item, userDecisions.find(ud => ud.decision === item) ? true : false);
      }

      // If there's a choice, find if correct decision AND choice was made
      if (choice) {
        let hasDecisionAndChoice = userDecisions.find((ud) => {
          let decision = item.split("").slice(0, item.length - 3).join("");
          let hasDecision = ud.decision === decision;
          let hasChoice = ud.choice === Number(choice.match(/\d/)[0]);
          return hasDecision && hasChoice;
        });

        condition = condition.replace(item, hasDecisionAndChoice ? true : false);

      }

    });

    // The condition is still a string, so it needs to be evaluated.
    return eval(condition);

  };

  // Searches through remaining decisions to see which should be the next one. Returns the next decision id.
  let nextDecision = decisionsRemaining.find((drm) => {
    if (userDecisionIds.indexOf(drm.id) === -1) {
      return drm.preReqDecision ? (findNextDecision(drm.preReqDecision) ? true : null) : drm;
    }
  });

  // Return the next decision id, but if none are remaining, return false.
  return nextDecision ? nextDecision.id : false;

};
