import * as Handlebars from "handlebars";
import { findIfSuperceded, formatDate } from "../helpers/actionHelpers";

export let generateWindResultsFromState = (state, test = null) => {
 
  let windResults = Object.assign({}, state.entities.windResults);
  let events = Object.keys(state.entities.events).map((k) => state.entities.events[k]);

  events = events.map((event) => {
    return Object.assign({}, event);
  });

  let results = [];

  // Finds the event with the max cityWindSpeed out of all the events
  let maxWindEvent = events.reduce((a, b) => a.cityWindSpeed > b.cityWindSpeed ? a : b);
  let maxCityWindSpeed = maxWindEvent.cityWindSpeed;
  let maxCityWindSpeedRoundedMPH = state.entities.storms[state.currentStorm].maxCityWindSpeed;
  
  // add results with windspeed thresholds that are breached to the results array
  Object.keys(windResults).forEach(resultId => {
    if (maxCityWindSpeed >= windResults[resultId].threshold.match(/\d+/)[0]) {
      results.push(resultId);
    }
  });

  // Takes a city windspeed and returns the events date that first has that city windspeed
  let getCityWindspeedDate = (windspeed: number) => {
    let eventWithWindspeed = events.find(event => event.cityWindSpeed >= windspeed);
    if (eventWithWindspeed) {
      return formatDate(eventWithWindspeed.date);
    }
    else {
      return null;
    }
  };

  
  let data = {
    time_34: getCityWindspeedDate(34),
    time_50: getCityWindspeedDate(50),
    time_64: getCityWindspeedDate(64),
    maxwindsexperienced: maxCityWindSpeedRoundedMPH,
  };

  // filter out supercededBy results
  results = results.filter(resultId => {
    const result = windResults[resultId];
    let isNotSuperceded = true;

    // If the result has a supercededBy value
    if (result.supercededBy) {
      isNotSuperceded = findIfSuperceded(result.supercededBy, results);
    }

    // If it is not superceded, or has not supercededByvalue, and has text to report
    if (result.textToReport && isNotSuperceded) {
      if (result.textToReport) {
        windResults[resultId].textToReport = Handlebars.compile(result.textToReport)(data);
      }
    }

    // Only return result if it was not superceded or has no supercededBy value
    return isNotSuperceded || !result.supercededBy ? true : false;
  
  });


  let generatedWindResults = results;

  return { generatedWindResults, windResults };

};
