import { ADD_USER_DECISION } from "../../actions/decision.types";

export let reducer = (state = [], action) => {
  switch (action.type) {
    case ADD_USER_DECISION: {
      let newDecision = {
        choice: action.choice,
        decision: action.decision,
        event: action.event,
      };
      return [...state, newDecision];
    }
    default:
      return state;
  }
};

export default reducer;
