import * as React from "react";
import * as Redux from "redux";
import { connect } from "react-redux";
import { closeDialog } from "../actions/dialog";
import { merge, isEqual, isEmpty } from "lodash";

export const VISIBLE_STATES = {
  UNOPENED: "UNOPENED",
  PRERENDER: "PRERENDER",
  CLOSED: "CLOSED",
  OPENING: "OPENING",
  OPENED: "OPENED",
  CLOSING: "CLOSING",
};

export interface DialogProps {
  styleClosed?: (dialogElements: { dialogElement: HTMLElement, dialogProps: any }) => React.CSSProperties;
  styleOpened?: (dialogElements: { dialogElement: HTMLElement, dialogProps: any }) => React.CSSProperties;
  transitionDuration?: Number;
  hasOverlay?: boolean;
  dialogClassName?: string;
  name: string;
  onOpened?: () => void;
  onClosed?: () => void;
}

interface DialogReduxProps extends DialogProps {
  isOpen: boolean;
  animate: boolean;
  dispatch: Redux.Dispatch<any>;
}

/**
 * React Dialog which can be controlled through redux actions.
 *
 * Most parameters are optional except for name, as this is used to map redux
 * state to individual dialogs.
 *
 * Five possible view states:
 *  - unopened: component is not rendered at all until it first request to be opened
 *  - preRender: component is rendered off screen so we can capture its size in later states
 *  - closed: style closed is applied
 *  - opening: from style closed, we apply style opened with a transition duration
 *  - opened: style open is applied
 *  - closing: from style opened, we apply style closed with a transition duration
 *
 * @example:
 *  <Dialog
 *    name={`my-dialog`}
 *    dialogClassName={`dialog dialog-my-dialog`}
 *    transitionDuration={700}
 *    onClosed={() => console.log("closed dialog")}
 *    styleOpened: ({ dialogElement }) => {
 *      return {
 *        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
 *        top: `${document.body.offsetHeight / 2 - dialogElement.offsetHeight / 2 + 6}px`,
 *        transitionTimingFunction: `swing`,
 *      };
 *    },
 *    styleClosed: ({ dialogElement }) => {
 *      return {
 *        left: `${0 - dialogElement.offsetWidth - 6}px`,
 *        top: `${document.body.offsetHeight / 2 - dialogElement.offsetHeight / 2 + 6}px`,
 *        transitionTimingFunction: `ease`,
 *      };
 *    },
 *  >
 *    <div>
 *      <h1>Dialog Contents</h1>
 *    </div>
 *  </Dialog>
 */
export class Dialog extends React.Component<DialogProps, any> {

  public static defaultProps: Partial<DialogProps> = {
    styleOpened: ({ dialogElement }) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `${document.body.offsetHeight / 2 - dialogElement.offsetHeight / 2 + 6}px`,
        transitionTimingFunction: `swing`,
      };
    },
    styleClosed: ({ dialogElement }) => {
      return {
        left: `${0 - dialogElement.offsetWidth - 6}px`,
        top: `${document.body.offsetHeight / 2 - dialogElement.offsetHeight / 2 + 6}px`,
        transitionTimingFunction: `ease`,
      };
    },
    transitionDuration: 400,
    hasOverlay: true,
    onOpened: () => {},
    onClosed: () => {},
    dialogClassName: "dialog"
  };

  state = {
    visible: VISIBLE_STATES.UNOPENED,
  };

  _element = null;
  _dialogContainerEl = null;
  numDialogsOpen = 0;
  resetOpacity = false;

  // store previous style to determine if we should rerender
  offsetWidth: 0;
  offsetHeight: 0;

  constructor(props) {
    super(props);
    this.getVisibleStyle = this.getVisibleStyle.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.toggle = this.toggle.bind(this);
    this.displayOverlay = this.displayOverlay.bind(this);
    this.handleOverlayOpacity = this.handleOverlayOpacity.bind(this);
  }

  public open({ animate } = { animate: true }) {
    if (this.state.visible !== VISIBLE_STATES.OPENED) {
      if (animate) {
        this.setState({ visible: VISIBLE_STATES.OPENING});
        setTimeout(() => {
          if (this.state.visible === VISIBLE_STATES.OPENING) {
            this.setState({ visible: VISIBLE_STATES.OPENED });
            this.props.onOpened();
          }
        }, this.props.transitionDuration);
      } else {
        this.setState({ visible: VISIBLE_STATES.OPENED });
        this.props.onOpened();
      }
    }
  }

  public close({ animate } = { animate: true }) {
    if (this.state.visible !== VISIBLE_STATES.CLOSED) {
      if (animate) {
        this.setState({ visible: VISIBLE_STATES.CLOSING});
        setTimeout(() => {
          if (this.state.visible === VISIBLE_STATES.CLOSING) {
            this.setState({ visible: VISIBLE_STATES.CLOSED });
            this.props.onClosed();
          }
        }, this.props.transitionDuration);
      } else {
        this.setState({ visible: VISIBLE_STATES.CLOSED });
        this.props.onClosed();
      }
    }
  }

  public toggle({ animate } = { animate: true }) {
    if (this.state.visible !== VISIBLE_STATES.CLOSED) {
      this.close({ animate });
    } else {
      this.open({ animate });
    }
  }

  getVisibleStyle() {
    let dialogElement = this._element;
    let style: any = {};
    switch (this.state.visible) {
      case VISIBLE_STATES.PRERENDER: {
        style = { left: `-10000px`, top: `-10000px` };
        break;
      }
      case VISIBLE_STATES.CLOSED: {
        if (dialogElement) {
          style = this.props.styleClosed({ dialogElement, dialogProps: this.props });
        }
        break;
      }
      case VISIBLE_STATES.CLOSING: {
        if (dialogElement) {
          style = this.props.styleClosed({ dialogElement, dialogProps: this.props });
          style = { ...style, ...{
            transitionDuration: `${this.props.transitionDuration}ms`,
          }};
        }
        break;
      }
      case VISIBLE_STATES.OPENED: {
        if (dialogElement) {
          style = this.props.styleOpened({ dialogElement, dialogProps: this.props });
        }
        break;
      }
      case VISIBLE_STATES.OPENING: {
        if (dialogElement) {
          style = this.props.styleOpened({ dialogElement, dialogProps: this.props });
          style = { ...style, ...{
            transitionDuration: `${this.props.transitionDuration}ms`,
          }};
        }
        break;
      }
      default: {
        style = { left: `-10000px`, top: `-10000px` };
        break;
      }
    }

    // fix potential rounding errors to prevent
    // infinite loops caused by decimal pixel values
    style = { ...style, ...{
      left: style.left ? `${parseInt(style.left)}px` : undefined,
      top: style.top ? `${parseInt(style.top)}px` : undefined,
      right: style.right ? `${parseInt(style.right)}px` : undefined,
      bottom: style.bottom ? `${parseInt(style.bottom)}px` : undefined,
    }};

    return style;
  }

  displayOverlay() {
    let { hasOverlay } = this.props;
    let { visible } = this.state;
    return hasOverlay && visible !== VISIBLE_STATES.CLOSED;
  }

  componentWillUpdate(nextProps, nextState) {
    // This is to set the Opacity if there are multiple overlays,
    // otherwise the multiple overlays will be to dark stacked on top of eachother
    if (!isEmpty(this.props.dialogs) && this.props.dialogs !== nextProps.dialogs) {
      let { dialogs } = this.props;
      let dialogIds = Object.keys(nextProps.dialogs);
      let numDialogsOpen = 0;
      let nextNumDialogsOpen = 0;

      dialogIds.forEach((id) => {
        if (dialogs[id]) {
          dialogs[id].isOpen ? numDialogsOpen++ : null;
        }
        if (nextProps.dialogs[id]) {
          nextProps.dialogs[id].isOpen ? nextNumDialogsOpen++ : null;
        }
      });  

      this.numDialogsOpen = numDialogsOpen;

      // Only for if we want to handle to opacity issue too
      // if (numDialogsOpen > nextNumDialogsOpen) {
      //   this.resetOpacity = true;
      // }
  
      // if (numDialogsOpen < nextNumDialogsOpen) {
      //   this.resetOpacity = false;
      // }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let props = this.props as DialogReduxProps;
    let { animate } = this.props as DialogReduxProps;
    let { visible } = this.state;

    /**
     * On first open dialog, run through the visible states before calling open
     * - UNOPENED
     * - PRERENDER
     * - CLOSED
     */
    if (
        (!prevProps.isOpen && props.isOpen) ||
        (props.isOpen && visible === VISIBLE_STATES.PRERENDER) ||
        (props.isOpen && visible === VISIBLE_STATES.CLOSED)
    ) {
      if (visible === VISIBLE_STATES.UNOPENED) {
        this.setState({ visible: VISIBLE_STATES.PRERENDER });
      } else if (visible === VISIBLE_STATES.PRERENDER) {
        this.setState({ visible: VISIBLE_STATES.CLOSED });
      } else {
        this.open({ animate });
      }
    } else if (prevProps.isOpen && !props.isOpen) {
      this.close({ animate });
    } 
  }  

  handleOverlayOpacity(name) {
    if (this.numDialogsOpen > 1 && !this.resetOpacity) {
      if (name === "initial-storm-update") {
        return {zIndex: "10000"}
      }
    }
  }
  
  render() {
    let { dispatch, name } = this.props as DialogReduxProps;

    // const intialUpdateIsOpen = this.props.dialogs.initial-storm-update.isOpen; 
    // const shouldHideAnnouncement = name.includes("announcement") && intialUpdateIsOpen;
    return this.state.visible === VISIBLE_STATES.UNOPENED ? null : (
      <div 
        ref={e => this._dialogContainerEl = e} 
        className="dialog-container-overflow-hidden" 
       // style={shouldHideAnnouncement ? {} : {display: "none"}}
        onScroll={(e) => {
          // disable scrolling in dialogContainer
          if (e.target === this._dialogContainerEl) {
            e.target.scrollTop = 0
          }}}>
            { this.displayOverlay() ? 
          
        <div 
          className="dialog-overlay" 
          style={this.handleOverlayOpacity(name)} 
          onClick={() => dispatch(closeDialog({ name }))}
        
        /> : null }
        <div className={this.props.dialogClassName} style={this.getVisibleStyle()} ref={e => this._element = e }>
          {this.props.children}
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let { isOpen, animate } = state.dialogs[ownProps.name] ? state.dialogs[ownProps.name] : { isOpen: false, animate: true };
  let { dialogs } = state;
  return merge({}, state.dialogs[ownProps.name], {
    isOpen,
    animate,
    dialogs,
  });
};

export default connect(mapStateToProps, null, null, { withRef: true })(Dialog);
