import { START_EXPERIMENT, ADD_EVENT_TO_EXPERIMENT, ADD_DECISION_TO_EXPERIMENT } from "../actions/experimentalData.types";
import { merge } from "lodash";

export let reducer = (state = {}, action) => {
  switch (action.type) {
    case START_EXPERIMENT: {
      let newState = merge({}, state);

      let {
        sessionId,
        startTime,
        stormId,
        stormName,
        numberOfEvents,
        numberOfDecisions,
        firstEvent
      } = action;

      newState = {
        sessionId,
        startTime,
        stormId,
        stormName,
        numberOfEvents,
        numberOfDecisions,
        completedSimulation: false,
        numberOfCompletedEvents: 1,
        numberOfDecisionsMade: 0,
        events: {},
      }

      newState.events[firstEvent.order] = firstEvent;
      return newState;
    }
    
    case ADD_EVENT_TO_EXPERIMENT: {
      let newState = merge({}, state);
      newState.events[action.newEvent.order] = action.newEvent;
      newState.numberOfCompletedEvents = action.numberOfCompletedEvents;
      return newState;
    }

    case ADD_DECISION_TO_EXPERIMENT: {
      let newState = merge({}, state);
      let event = newState.events[action.event];
      event.decisions = {};
      let decision = action.decision;
      event.decisions[action.decision.id] = decision;
      newState.totalTime = action.totalTime;
      newState.numberOfDecisionsMade = action.numberOfDecisionsMade;
      // Update decision timing announcements
      if (action.newDecTimingAnnouncements) {
        event.decisionTimingAnnouncements = action.newDecTimingAnnouncements;
      }

      // Update actions
      if (action.newActions) {
        event.actions = action.newActions;
      }

      return newState;
    }

    default:
      return state;
  }
};

export default reducer;
