// Takes a time object and returns minutes/seconds in a string form "MM:SS"
export const formatTime = (time) => {
    let formattedTime;
    let minutes;
    let seconds;
    if (time > 60) {
        minutes = Math.floor(time/60);
        seconds = (time - (minutes*60)).toFixed();
        formattedTime = `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;      
    }
    else {
        seconds = time.toFixed();
        formattedTime = `00:${seconds < 10 ? "0" + seconds : seconds}`;
    }
    return formattedTime;
};
  
// Only returns data that Eva needs for each time step/evet
export const filterEventData = (event) => {
    return {
      order: event.order,
      date: event.date,
      actions: event.actions.join(),
      announcements: event.announcements.join(),
      decisionTimingAnnouncements: event.decisionTimingAnnouncements.join(),
      bearing: event.bearing,
      cityWindSpeed: event.cityWindSpeed,
      distanceToLandfallMiles: event.distanceToLandfallMiles,
      estimatedHoursToLandfall: event.estimatedHoursToLandfall,
      estimatedHoursToLandfallHurrevac: event.estimatedHoursToLandfallHurrevac,
      hasMadeLandfall: event.hasMadeLandfall,
      hasMadeLandfallForecasted: event.hasMadeLandfallForecasted,
      hasMadeFirstLandfall: event.hasMadeFirstLandfall,
      estimatedLandfallDate: event.estimatedLandfallDate,
      cityWindSpeedForecasted: event.cityWindSpeedForecasted,
      maxSustainedWindsForecasted: event.maxSustainedWindsForecasted,
      maxSustatinedWinds: event.maxSustainedWinds,
      radiusMaxWindsMiles: event.radiusMaxWindsMiles,
      radiusMaxWindsMilesForecasted: event.radiusMaxWindsMilesForecasted,
      probabilityWinds39MPH: event.probabilityWinds39MPH,
      probabilityWinds58MPH: event.probabilityWinds58MPH,
      probabilityWinds74MPH: event.probabilityWinds74MPH,
      speedOfForwardMotion: event.speedOfForwardMotion,
      speedOfForwardMotionForecasted: event.speedOfForwardMotionForecasted,
      stormCenterLatitudeForecasted: event.stormCenterLatitudeForecasted,
      stormCenterLongitudeForecasted: event.stormCenterLongitudeForecasted,
      stormCenterLatitude: event.stormCenterLatitude,
      stormCenterLongitude: event.stormCenterLongitude,
      surge: event.surge,
      tide: event.tide,
      timeEventOccured: event.timeEventOccured,
      timeEventOccuredFromStart: event.timeEventOccuredFromStart,
    }
}
  