import { CHANGE_CURRENT_DECISION } from "../../actions/decision.types";

export let reducer = (state = [], action) => {
  switch (action.type) {
    case CHANGE_CURRENT_DECISION: {
      let newState = action.nextDecisionId;
      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
