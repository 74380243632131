let { routerReducer } = require("react-router-redux");
import { combineReducers } from "redux";
import dialogs from "./dialogs/";
import currentStorm from "./currentStorm";
import currentEvent from "./currentEvent";
import selectedEvent from "./selectedEvent";
import mapExtents from "./mapExtents";
import autoAdvance from "./autoAdvance";
import simulationOver from "./simulationOver";
import announcementsBeingShown from "./announcementsBeingShown";
import experimentalData from "./experimentalData";

let routing = routerReducer;

import entities from "./entities/";
import ui from "./ui";

let reducer = combineReducers({
  entities,
  routing,
  dialogs,
  ui,
  currentStorm,
  currentEvent,
  selectedEvent,
  mapExtents,
  autoAdvance,
  simulationOver,
  announcementsBeingShown,
  experimentalData,
  locationId: String,
  stormId: String,
});

export default reducer;
