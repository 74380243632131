import { SET_SIMULATION_OVER } from "./simulationOver.types";
import { getEndResults } from "./endResults";
import { stopAutoAdvance } from "./autoAdvance";
import { openDialog } from "./dialog";
import { endExperiment } from "./experimentalData";

import { merge } from "lodash";

export let setSimulationOver = (options: {endingEarly: boolean}) => {
  return async (dispatch, getState) => {
    const state = getState();

    // If experimental, send the final experimental data
    if (!options.endingEarly && state.entities.storms[state.currentStorm].experimental) {
      await dispatch(endExperiment({remainingEvents: {}})); 
    }

    await dispatch(stopAutoAdvance());
    await dispatch(getEndResults());
    await dispatch(openDialog({name: "simulation-over"}));
    await dispatch(merge({}, {
      type: SET_SIMULATION_OVER,
    }));

  };
};
