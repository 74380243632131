import * as React from "react";
import { closeDialog, openDialog } from "../../actions/dialog";
import { startExperiment } from "../../actions/experimentalData";
import Dialog from "./../BetterDialog";
import { connect } from "react-redux";
import * as classNames from "classnames";

// Import components
import Overview from "./pages/overview";
import History from "./pages/history";
import HurricaneBasics from "./pages/hurricaneBasics";
import Instructions from "./pages/instructions";
import Acronyms from "./pages/acronyms";

export let name = "help";

export class Help extends React.Component<any, any> {

  _content;
  
  state = {
    currentTab: 1,
    simulationNotStartedYet: true,
  };

  constructor(params) {
    super(params);
  }

  getPage() {
    switch (this.state.currentTab) {
      case 1:
        return <Overview />;
      case 2:
        return <History />;
      case 3:
        return <HurricaneBasics />;
      case 4:
        return <Instructions />;
      case 5:
        return <Acronyms />;
      default:
        return <Overview />;
    }
  }

  handleOnNext() {
    let count = this.state.currentTab;
    if (this.state.currentTab < 5) {
      count ++;
      this.setState({currentTab: count});
    }
  }

  handleOnPrev() {
    let count = this.state.currentTab;
    if (this.state.currentTab > 1) {
      count --;
      this.setState({currentTab: count});
    }
  }

  handleStartOrResumeSimulation() {
    this.props.dispatch(closeDialog({ name }));
    this.setState({currentTab: 1});
    if (this.state.simulationNotStartedYet) {
      // If experimental scenario, start collecting data
      if (this.props.isExperimental) {
        this.props.dispatch(startExperiment());
      }
      this.props.dispatch(openDialog({name: "initial-storm-update", animate: false}));
      setTimeout(() => {
        this.setState({simulationNotStartedYet: false});
      }, 500);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // If the help dialog was triggered from the "help" button (i.e. it existed before, but was closed, and now opened):
    if (!prevProps.dialogs.help.isOpen && this.props.dialogs.help.isOpen) {
      this.setState({currentTab: 4});
    }
    // If a new page was selected, scroll top top
    if (this.state.currentTab !== prevState.currentTab) {
      this._content.scrollTop = 0;
    }
  }


  render() {
    let { onFirstEvent, dispatch } = this.props;
    let menu = ["Overview", "History", "Hurricane Basics", "Instructions", "Acronyms"];
    let tabNumber = this.state.currentTab;

    return (
        <div>
          <div className="header">
            <span className="MFRLogo" />
            <span className="MFRLogoText" />
            <h2>Hurricane Decision Simulator</h2>
            <div className="header-buttons">
              <a href="javascript:;"
                 className={"btn startSimulationButton btn-primary"}
                 onClick={e => this.handleStartOrResumeSimulation()}>
                 {this.state.simulationNotStartedYet ? "Start Simulation" : "Resume Simulation"}</a>
            </div>
          </div>
          <div className="splash-content">
            <div className="content-controlbar">
              <ol>
                { menu.map((menuItem, key) =>
                  <li
                    className={`tab-${menu.indexOf(menuItem) + 1} ` + classNames({
                      current: tabNumber === menu.indexOf(menuItem) + 1,
                    })}
                    onClick={() => this.setState({currentTab: menu.indexOf(menuItem) + 1})}
                    key={key}
                  >
                    <span className="number" key={key}>
                      <span className="value" key={key}>
                        {menu.indexOf(menuItem) + 1}
                      </span>
                    </span>
                    {menuItem}
                  </li>
                )}

              </ol>
              <div className="arrow-controls">
                <span className={tabNumber === 1 ? "leftArrowIcon disabled" : "leftArrowIcon"} onClick={() => this.handleOnPrev()}/>
                <span className={tabNumber === 5 ? "rightArrowIcon disabled" : "rightArrowIcon"} onClick={() => this.handleOnNext()}/>
              </div>
            </div>
            <div className="content-container" ref={(n) => this._content = n}>

              {this.getPage()}

            </div>
          </div>
        </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  const { entities: {events, storms}, currentEvent, currentStorm, dialogs } = state;
  const onFirstEvent = events[0].order === currentEvent;
  const isExperimental = storms[currentStorm].experimental;
  return { onFirstEvent, isExperimental, dialogs };
};

const ReduxHelp = connect(mapStateToProps)(Help);

export let ReduxHelpDialog = (props) =>
  <Dialog
    name={name}
    dialogClassName="splashScreen"
    styleOpened={({ dialogElement }) => {
      return {
        left: `${0}px`,
        top: `${25}px`,
        transitionTimingFunction: `ease`,
      }
    }}
    styleClosed={({ dialogElement }) => {
      return {
        left: `${-document.body.offsetWidth}px`,
        top: `${25}px`,
        transitionTimingFunction: `ease`,
      }
    }}
    transitionDuration={600}
    hasOverlay={false}
  >
    <ReduxHelp/>
  </Dialog>;

export default ReduxHelpDialog;
