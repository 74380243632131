import * as moment from "moment";

// Converts knots to MPH. 1 knot = 1.15078 miles per hour
export let knotsToMph = (knots) => {
  return knots * 1.15078;
};

export let mphToKnots = (mph) => {
  return mph * 0.8689762;
};

export let formatMoney = (cost) => {
  // see https://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-dollars-currency-string-in-javascript#comment28061054_149055
  let dollars = Math.round(cost);
  return (dollars).toLocaleString();
};

export let formatDate = (date) => {
  return `${moment(date).add(0.5, "hours").subtract(4, "hours").format("D MMM HH") + "00"} AST`;
};

export let roundDownToWhole = (num) => {
  return Math.floor(num);
};

export let getHoursAgo = (eventIndex, numberOfEvents) => {
  if (eventIndex === numberOfEvents - 1) {
    return "Current Event";
  } else {
    return `${(numberOfEvents - 1 - eventIndex) * 6} hrs ago`;
  }
}

export let hoursToLandfall = (hours) => {
  if (hours === -9999 || hours === 9999) {
    return "N/A";
  }
  if (hours !== -1) {
    return Math.round(hours) + " hrs";
  }
};

export let formatProbability = (probability) => {
  if (probability < 1) {
    return "< 1%";
  }
  else {
    return  Math.round(probability) + "%";
  }
};

export let typeOfStorm = (winds) => {
  // convert to MPH and round to whole number
  let mph = Math.round(knotsToMph(winds));
  if (mph < 39) {
    return "tropical depression";
  }
  if (mph >= 39 && mph < 74) {
    return "tropical storm";
  }
  if (mph >= 74 && mph < 96) {
    return "Category 1 hurricane";
  }
  if (mph >= 96 && mph < 111) {
    return "Category 2 hurricane";
  }
  if (mph >= 111 && mph < 130) {
    return "Category 3 hurricane";
  }
  if (mph >= 130 && mph < 157) {
    return "Category 4 hurricane";
  }
  if (mph >= 157) {
    return "Category 5 hurricane";
  }
};

// Rounds number up or down to the nearest multiple of 5
export let roundToFive = (num) => {
  let numUp = Math.ceil(num/5) * 5;
  let numDown = Math.floor(num/5) * 5;
  if (numDown - num < 2.5 && numDown - num >= -2.5) {
    return numDown;
  }
  else {
    return numUp;
  }
};

