import * as Handlebars from "handlebars";
import { findIfSuperceded } from "../helpers/actionHelpers";

export let generateSurgeResultsFromState = (state, test = null) => {

  let surgeResults = Object.assign({}, state.entities.surgeResults);
  let events = Object.keys(state.entities.events).map((k) => state.entities.events[k]);

  events = events.map((event) => {
    return Object.assign({}, event);
  });

  let results = [];

  // Finds the event with the max surge out of all the events
  let maxSurgeEvent = events.reduce((a, b) => a.surge > b.surge ? a : b);
  let maxSurge = maxSurgeEvent.surge;
  
  // add results with surge thresholds that are breached to the results array
  Object.keys(surgeResults).forEach(resultId => {
    if (maxSurge >= surgeResults[resultId].threshold.match(/\d+/)[0]) {
      results.push(resultId);
    }
  });

  const surgeplustide = maxSurge + maxSurgeEvent.tide;
  const surgeplustideRounded = surgeplustide.toFixed(1);


  // filter out supercededBy results
  results = results.filter(resultId => {
    const result = surgeResults[resultId];
    let isNotSuperceded = true;

    // If the result has a supercededBy value
    if (result.supercededBy) {
      isNotSuperceded = findIfSuperceded(result.supercededBy, results);
    }

    // If it is not superceded, or has not supercededByvalue, and has text to report
    if (result.textToReport && isNotSuperceded) {
      if (result.textToReport) {
        surgeResults[resultId].textToReport = Handlebars.compile(result.textToReport)({surgeplustide: surgeplustideRounded});
      }
    }

    // Only return result if it was not superceded or has no supercededBy value
    return isNotSuperceded || !result.supercededBy ? true : false;

  });


  let generatedSurgeResults = results;

  return { generatedSurgeResults, surgeResults };
};
