import { SET_SELECTED_EVENT } from "./../actions/event.types";

export let reducer = (state = "", action) => {
  switch (action.type) {
    case SET_SELECTED_EVENT: {
      return action.event;
    }
    default:
      return state;
  }
};

export default reducer;
