import * as React from "react";
import {closeDialog} from "../actions/dialog";
import Dialog from "./BetterDialog";
import {connect} from "react-redux";
import Feedback from "./feedback";
import {padStart, find} from "lodash";
import {formatDate, typeOfStorm} from "../misc/formatter";

export let name = "simulation-over";

export class SimulationOver extends React.Component<any, any> {

  state = {
    isMounted: false,
  };

  constructor(params) {
    super(params);
  }

  componentDidMount() {
    this.setState({isMounted: true});
  }

  render() {
    let {
      closeDialog, 
      mapUrl, 
      results, 
      windResults, 
      surgeResults, 
      userDecisions, 
      eventWithLandfall,
      isExperimental,
      sessionId
    } = this.props;

    const noDecStormResults = results.decisionStorm.length === 0;
    const noDecTimingResults = results.decisionTiming.violated.length === 0 && results.decisionTiming.satisfied.length === 0;
    const noDecAnnResults = results.decisionAnnouncement.length === 0;
    const noAnnResults = results.announcements.length === 0;
    const noCost = results.totalCost === 0;

    const noFeedback = noDecStormResults && noDecTimingResults && noDecAnnResults && noAnnResults && noCost;

    return (
      <div>
        <div className="dialog-header">
          <h2>Simulation Over</h2>
          <span className="grab-handle-icon"/>
          <span className="close-icon" onClick={(e) => closeDialog({name})}/>
        </div>

        <div className="dialog-body">
          <div className="left-column">
            <div className="simulation_end_results">

              {isExperimental ? <div className="session-id">{`Your session ID is: ${sessionId}`}</div> : null}
              
              {eventWithLandfall ? <p>The storm made landfall at {eventWithLandfall.stormCenterLatitude.toFixed(1)}°N
                and {eventWithLandfall.stormCenterLongitude.toFixed(1)}°W as
                a {typeOfStorm(eventWithLandfall.maxSustainedWinds)}, on {formatDate(eventWithLandfall.date)}.</p> : null}

              {results.windResults.length > 0 ?
                results.windResults.map((a, i) => {
                  return <p key={i}>{windResults[a].textToReport}</p>;
                }) : null}

              {results.surgeResults.length > 0 ?
                results.surgeResults.map((a, i) => {
                  return <p key={i}>{surgeResults[a].textToReport}</p>;
                }) : null}

              {userDecisions.length === 0 ? <p>You took no preparatory actions.</p> : null}

              {noFeedback ? null : <h2>Results:</h2>}
              
              {noFeedback ? null : <Feedback/>}
            
            </div>
          </div>

          <div className="right-column">
            <img className="simulation-end-image" src={mapUrl}/>
            <div className="latitude-longitude-guide">
              <div className="latitude-guide">
                <span className="north-text map-north-text-icon"/>
                <div className="latitude container-35">
                  <span className="latitude-label">35°</span>
                </div>
                <div className="latitude container-30">
                  <span className="latitude-label">30°</span>
                  <span className="latitude-tick"/>
                </div>
                <div className="latitude container-25">
                  <span className="latitude-label">25°</span>
                  <span className="latitude-tick"/>
                </div>
                <div className="latitude container-20">
                  <span className="latitude-label">20°</span>
                  <span className="latitude-tick"/>
                </div>
                <div className="latitude container-15">
                  <span className="latitude-label">15°</span>
                  <span className="latitude-tick"/>
                </div>
                <div className="latitude container-10">
                  <span className="latitude-label">10°</span>
                </div>
              </div>
              <div className="longitude-guide">
                <span className="west-text map-west-text-icon"/>
                <div className="longitude container-95">
                  <span className="longitude-tick"/>
                  <span className="longitude-label">95°</span>
                </div>
                <div className="longitude container-90">
                  <span className="longitude-tick"/>
                  <span className="longitude-label">90°</span>
                </div>
                <div className="longitude container-85">
                  <span className="longitude-tick"/>
                  <span className="longitude-label">85°</span>
                </div>
                <div className="longitude container-80">
                  <span className="longitude-tick"/>
                  <span className="longitude-label">80°</span>
                </div>
                <div className="longitude container-75">
                  <span className="longitude-tick"/>
                  <span className="longitude-label">75°</span>
                </div>
                <div className="longitude container-70">
                  <span className="longitude-tick"/>
                  <span className="longitude-label">70°</span>
                </div>
                <div className="longitude container-65">
                  <span className="longitude-tick"/>
                  <span className="longitude-label">65°</span>
                </div>
              </div>
            </div>
          </div>
          <div className="simulation-end-button-container">
            <a
              href="javascript:;"
              className="btn btn-big reviewButton"
              onClick={(e) => closeDialog({name})}
              style={{marginRight: "9.33px"}}>
              REVIEW
            </a>
            <a
              href={this.state.isMounted ? `${window.location}` : ""}
              className="btn btn-primary btn-big newSimulationButton"
              style={{marginRight: "9.33px"}}>
              NEW SIMULATION
            </a>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  const {
    entities: {
      events, 
      storms, 
      results, 
      windResults, 
      surgeResults, 
      userDecisions,
    }, 
    currentStorm,
    experimentalData: {sessionId}
  } = state;
  const eventWithLandfall = find(events, e => e.hasMadeLandfall > 0);
  const storm = storms[currentStorm];
  const cityNameShort = storm.cityNameShort;
  const stormId = storm.id;
  const cityname = cityNameShort.replace(/\s/g, '').toLowerCase();
  const mapUrl = `${cityname}/${stormId}/Images/${padStart(storm.totalSteps, 3, "0")}/wind_history.png`;
  const isExperimental = storms[currentStorm].experimental;
  return {
    mapUrl,
    results,
    windResults,
    surgeResults,
    userDecisions,
    eventWithLandfall,
    isExperimental,
    sessionId
  };
};

let mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeDialog: (options) => dispatch(closeDialog(options)),
  };
};

export let ReduxSimulationOver = connect(mapStateToProps, mapDispatchToProps)(SimulationOver);

let ReduxSimulationOverDialog = (props) =>
  <Dialog
    name={name}
    dialogClassName="dialog dialog-simulation-over"
    styleOpened={({dialogElement, dialogProps}) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `${document.body.offsetHeight / 2 - dialogElement.offsetHeight / 2 + 6}px`,
      };
    }}
    styleClosed={({dialogElement, dialogProps}) => {
      return {
        left: `${document.body.offsetWidth / 2 - dialogElement.offsetWidth / 2 + 6}px`,
        top: `${document.body.offsetHeight + 10}px`,
        transitionTimingFunction: `ease`,
      };
    }}
    transitionDuration={600}
    hasOverlay={true}
  >
    <ReduxSimulationOver/>
  </Dialog>;

export default ReduxSimulationOverDialog;
