import * as path from "path";

export const isDev = process.env.NODE_ENV === "development";
export const BASE_URL = process.env.BASE_URL;
export const tmpPath = `.tmp/${process.env.NODE_ENV}`;
export const distPath = `${tmpPath}/dist`;
export const NODE_PORT = Number(process.env.NODE_PORT);
export const NODE_ENV = process.env.NODE_ENV ? process.env.NODE_ENV : "development";
export const scenarioPath = path.resolve(`${__dirname}/../data/scenario/`);
export const stormsPath = path.resolve(`${__dirname}/../data/storms/`);
export const stormTargetsPath = path.resolve(`${__dirname}/../data/storms-target/`);

export function listeningCallback() {
    console.log(`Server listening on port ${NODE_PORT}`);
  }